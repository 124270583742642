import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EntranceApi } from 'src/apis/entrance-test'
import { PageLink } from 'src/constants'
import { CODE_ADMIN, TITLE_QUIZ_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { Role } from 'src/type'
import { IParticipants } from 'src/type/participants'
import ActionCellEntrance from '../base/actionEntranceTest/ActionCellEntrance'

interface IProps {
  Action: any
  data: IParticipants
  showPublishScore: boolean
}

const ParticipantAction = ({ Action, data, showPublishScore }: IProps) => {
  const navigate = useNavigate()

  const getParticipantsDetail = async (id: string) => {
    try {
      const res = await EntranceApi.detailAttempts(id)
      if (res) {
        Action('content', res?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const allowRenderGetParticipantDetail = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_QUIZ_GR.PUBLIC_SCORE_DETAIL) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowRenderGetParticipant = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_QUIZ_GR.VIEW_PARTICIPANT_LIST) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const { contextHolder } = useConfirm()

  return (
    <>
      {contextHolder}
      {(allowRenderGetParticipantDetail || showPublishScore) && (
        <ActionCellEntrance>
          {(allowRenderGetParticipantDetail || allowRenderGetParticipant) && (
            <div className='menu-item px-3' onClick={() => getParticipantsDetail(data?.id)}>
              <div className='menu-link px-3'>Detail</div>
            </div>
          )}
          {(allowRenderGetParticipantDetail || allowRenderGetParticipant) && (
            <div
              className='menu-item px-3'
              onClick={() => navigate(`${PageLink.ENTRANCE_TEST_RESULT}/${data.id}`)}
            >
              <div className='menu-link px-3'>Test Result</div>
            </div>
          )}
        </ActionCellEntrance>
      )}
    </>
  )
}
export default ParticipantAction
