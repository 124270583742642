import {
  MESSAGE_VALIDATION_USERNAME,
  VALIDATE_MAX_FULLNAME,
  VALIDATE_MIN_FULLNAME,
  VALIDATE_MIN_USERNAME,
  VALIDATE_PHONE,
  VALIDATION_FIELD,
} from 'src/constants'
import { phoneRegExp, usernamePattern } from 'src/utils'
import { z } from 'zod'

export const DEFAULT_SCHEMA = {
  full_name: z
    .string({ required_error: VALIDATION_FIELD })
    .min(3, { message: VALIDATE_MIN_FULLNAME })
    .max(100, { message: VALIDATE_MAX_FULLNAME }),
  email: z.string({ required_error: VALIDATION_FIELD }).email(),
  username: z
    .string({ required_error: VALIDATION_FIELD })
    .regex(new RegExp(usernamePattern), {
      message: MESSAGE_VALIDATION_USERNAME,
    })
    .min(6, { message: VALIDATE_MIN_USERNAME })
    .max(40, { message: 'Username Must Be Shorter Than Or Equal To 40 Characters' }),
  status: z.string().optional(),
  phone: z
    .string({ required_error: VALIDATION_FIELD })
    .regex(new RegExp(phoneRegExp), { message: VALIDATE_PHONE }),
  sex: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
  dob: z.any().optional(),
  attachment_files: z.any(),
  current_company: z.string().optional(),
  facility_ids: z
    .array(z.string({ message: VALIDATION_FIELD }))
    .refine((arr) => arr.some((item) => item != null && item !== ''), {
      message: VALIDATION_FIELD,
    }),
  avatar: z.any(),
  job_title: z.string().trim().optional().nullable(),
  facebook: z.string().trim().optional().nullable(),
  linkedin: z.string().trim().optional().nullable(),
  youtube: z.string().trim().optional().nullable(),
  describe: z.string().trim().optional().nullable(),
  teacher_status: z
    .string({ required_error: 'required' })
    .nullable()
    .transform((val) => (val === null ? '' : val))
    .refine((val) => val.trim() !== '', { message: VALIDATION_FIELD }),
  staff_ids: z
    .string({ required_error: VALIDATION_FIELD })
    .array()
    .min(1, { message: VALIDATION_FIELD }),
  category_primary_id: z.string({ message: VALIDATION_FIELD }).trim(),
  teacher_teachable_instances: z.any(),
  address: z.string().optional(),
}
