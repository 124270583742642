import { UseFormReturn } from 'react-hook-form'
import { ACCEPT_UPLOAD_MIME_TYPE, DEFAULT_MAX_FILE_SIZE, SHOP_EVENT_STATUS } from 'src/constants'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import SappLabel from 'src/components/base/label/SappLabel'
import './CreateAndUpdateContent.scss'
import GeneralContent from 'src/components/shop/events/GeneralContent'
import InformationContent from 'src/components/shop/events/InformationContent'
import image_default from 'src/_metronic/assets/images/shop/image_default.png'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { Select } from 'antd'
import { IEventForm } from 'src/type/shop/event'
import { hanleTransferMedia } from 'src/utils/shop/validation'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import { Dropdown } from 'react-bootstrap'
import { IStepProgress } from 'src/type'

const { Option } = Select

interface IProps extends IStepProgress {
  loading: boolean
  useFormProp: UseFormReturn<IEventForm>
  onSubmit: (x: any) => void
  onCancel: () => void
}

const CreateAndUpdateInfo = ({ useFormProp, onSubmit, onCancel, step, setStep }: IProps) => {
  const {
    control,
    setValue,
    setError,
    getValues,
    trigger,
    formState: { errors },
  } = useFormProp

  const handleToggle = async (): Promise<boolean> => {
    const isValid = await trigger([
      'title',
      'start_time',
      'end_time',
      'construction_mode',
      'address',
      'categories',
      'cta',
      'cta_title',
      'cta_url',
      'is_charge',
      'price_number',
      'total_seat_number',
    ])
    return isValid
  }

  return (
    <div className='form d-flex flex-column flex-lg-row'>
      <Processbar
        step={step}
        setNewStep={setStep}
        showCancel
        onCancel={onCancel}
        onClick={onSubmit}
        showButtonPrimary={false}
        canToggle={handleToggle}
      >
        <Dropdown.Item>Preview</Dropdown.Item>
        <Dropdown.Item onClick={onSubmit}>Save & Next</Dropdown.Item>
      </Processbar>
      <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-8'>
        <div className='card card-flush sapp-pt--image'>
          <div className='card-header pt-8 align-items-start'>
            <SappLabel label='Event Thumbnail' isLabel />
          </div>

          <div className='card-body text-center px-16 pb-10'>
            <SAPPHookUploadFile
              name='thumbnail'
              control={control}
              setValue={setValue}
              setError={setError}
              imagePreview={hanleTransferMedia(getValues('thumbnail')) ?? image_default}
              accept={ACCEPT_UPLOAD_MIME_TYPE}
              maxFileSize={DEFAULT_MAX_FILE_SIZE}
              removeAvatar={image_default}
              guideline={[
                'Set the product thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted',
              ]}
              className='sapp-w-200px'
            />
          </div>
        </div>
        <div className='card card-flush p-8'>
          <SappLabel label='Status' required isLabel />
          <div>
            <HookFormSelectAntd
              name='status'
              control={control}
              placeholder='Status'
              filterOption={true}
              className='sapp-h-45px fs-6 sapp-field_question-select'
              defaultValue={getValues('status')}
              options={SHOP_EVENT_STATUS}
            />
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-5'>
        <div className='tab-content'>
          <div className='tab-pane fade show active'>
            <div className='d-flex flex-column gap-7 gap-lg-10 sapp-mw-950px'>
              {/* General */}
              <GeneralContent useFormProp={useFormProp} />
              {/* General */}
              <InformationContent useFormProp={useFormProp} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateAndUpdateInfo
