import { round } from 'lodash'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState, useRef } from 'react'
import { KTIcon } from 'src/_metronic/helpers'
import ErrorMessage from 'src/common/ErrorMessage'
import NotData from 'src/components/NotData'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { IQuestion } from 'src/components/question-bank/shared/interfaces'
import { htmlToRaw } from 'src/utils'
import { normalizeLikeParam, qType } from 'src/utils/string'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { Control } from 'react-hook-form'
import { TYPE_OF_TEST } from '../shared/consts'
import { QuestionBankAPI } from 'src/apis/question-bank'
import QuestTionPreview from 'preview-question-bank'
import { Spin } from 'antd'
import ModalPreviewFile from 'src/components/question-bank/preview-question/ModalPreviewFile'
import { convertMathToImage } from 'src/utils'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import { useUserContext } from 'src/context/UserProvider'
import { hasPermission } from 'src/utils/permission'
import { TITLE_EVENT_TEST_GR } from 'src/constants/permission'

type Props = {
  questions: IQuestion[]
  setQuestions: (value: React.SetStateAction<IQuestion[]>) => void
  confirm: (option: any) => void
  handleChooseQuestions: () => void
  error?: string | undefined
  maxQuestionsNumber?: number | 'NO_MAX'
  disabled?: boolean
  control: Control<any>
  setValue: any
  getValues: any
  watchType: any
  defaultType: any
  totalQuestionsMultiple: number
  totalQuestionsConstructed: number
  percentQuestionsMulti: number
  setPercentQuestionsMulti: Dispatch<SetStateAction<any>>
  searchName?: string
  errors: boolean
  showAddQuestion?: boolean
  showdeleteQuestion?: boolean
  disbaleMark?: boolean
}

const headers = [
  {
    label: 'List Questions',
    key: 'list_questions',
  },
  {
    label: 'Type',
    key: 'type',
  },
  {
    label: 'Mark(%)',
    key: 'mark_of_each_question',
    className: 'sapp-w-175px text-center fw-bold',
  },
  {
    label: 'Action',
    key: 'actions',
    className: 'fw-bold text-end min-w-125px',
  },
]

const TestQuestionTable = ({
  questions,
  setQuestions,
  confirm,
  handleChooseQuestions,
  error,
  maxQuestionsNumber,
  disabled,
  control,
  setValue,
  getValues,
  watchType,
  defaultType,
  totalQuestionsMultiple,
  totalQuestionsConstructed,
  percentQuestionsMulti,
  searchName,
  errors,
  showAddQuestion = true,
  showdeleteQuestion = true,
  disbaleMark = false,
}: Props) => {
  const [filter, setFilter] = useState({
    search: '',
    currentPage: 1,
    pageSize: 10,
  })
  // const [loading, setLoading] = useState(true)
  const [filteredQuestions, setFilteredQuestions] = useState<IQuestion[]>([])
  const [checkTrue, setCheckTrue] = useState<boolean>(false)
  const [openPreview, setOpenPreview] = useState<{ value: boolean; data: any }>({
    value: false,
    data: undefined,
  })
  const [loadPreview, setLoadPreview] = useState(false)
  const [viewFile, setViewFile] = useState({ status: false, url: undefined })
  const editorRef = useRef<HTMLDivElement>(null)

  const handleClosePreview = () => {
    setOpenPreview({ value: false, data: undefined })
  }

  const calculateScoreChildNoValue = (total: number) => {
    questions?.map((e) => {
      setValue(`question_percent_${e.id}`, round(total / questions?.length, 4))
    })
  }

  const calculateScoreChildValue = (total: number) => {
    questions?.map((e: any) => {
      setValue(`question_percent_${e.id}`, e?.setting_grade || round(total / questions?.length, 4))
    })
  }

  const setValueTotal = (total: number) => {
    setValue(`get_percent_questions_${defaultType}`, total)
  }

  const setValueTotalMix = (name: string, total: number) => {
    const totalValue = Math.round(
      questions.reduce(
        (acc: any, question: any) =>
          acc + (isNaN(Number(question.setting_grade)) ? 0 : Number(question.setting_grade)),
        0
      )
    )
    setValue(`get_percent_questions_${name}`, totalValue > 0 ? total : 0)
  }

  const currentQuestions = useMemo(() => {
    return filteredQuestions.slice(
      (filter.currentPage - 1) * filter.pageSize,
      filter.currentPage * filter.pageSize
    )
  }, [filteredQuestions, filter.currentPage, filter.pageSize])

  useEffect(() => {
    if (currentQuestions.length === 0) {
      setFilter((prev) => {
        const obj = { ...prev }
        if (obj.currentPage > 1) {
          obj.currentPage = obj.currentPage - 1
        }
        return obj
      })
    }
  }, [currentQuestions])

  useEffect(() => {
    if (defaultType === 'multi' && watchType !== TYPE_OF_TEST.mix.value) {
      calculateScoreChildValue(100)
      setValueTotal(100)
    } else if (defaultType === 'constructed' && watchType !== TYPE_OF_TEST.mix.value) {
      calculateScoreChildValue(100)
      setValueTotal(100)
    }

    setTimeout(() => {
      setCheckTrue(true)
    }, 500)
  }, [])

  // Set value of percent
  useEffect(() => {
    if (checkTrue) {
      if (watchType !== TYPE_OF_TEST.mix.value) {
        switch (defaultType) {
          case 'multi':
            calculateScoreChildNoValue(100)
            setValueTotal(100)
            break
          case 'constructed':
            calculateScoreChildNoValue(100)
            setValueTotal(100)
            break
        }
      } else {
        switch (defaultType) {
          case 'multi':
            const getTotalPercent = Number(getValues(`get_percent_questions_multi`))
            calculateScoreChildNoValue(getTotalPercent)
            break
          case 'constructed':
            const totalConstructedPercent = Number(getValues(`get_percent_questions_constructed`))
            calculateScoreChildNoValue(totalConstructedPercent)
            break
        }
      }
    }
  }, [currentQuestions, watchType, percentQuestionsMulti])

  useEffect(() => {
    if (!checkTrue && watchType === TYPE_OF_TEST.mix.value) {
      if (defaultType === 'multi') {
        setValueTotalMix('multi', totalQuestionsMultiple)
        const getTotalPercent = Number(getValues(`get_percent_questions_multi`))
        calculateScoreChildValue(getTotalPercent)
      } else {
        setValueTotalMix('constructed', totalQuestionsConstructed)
        const totalConstructedPercent = Number(getValues(`get_percent_questions_constructed`))
        calculateScoreChildValue(totalConstructedPercent)
      }
    }
  }, [watchType, questions])

  useEffect(() => {
    if (checkTrue && watchType === TYPE_OF_TEST.mix.value) {
      if (defaultType === 'multi') {
        setValueTotalMix('multi', totalQuestionsMultiple)
      } else {
        setValueTotalMix('constructed', totalQuestionsConstructed)
      }
    }
  }, [watchType])

  const handleChangePage = (page: number, pageSize: number) => {
    setFilter({ ...filter, currentPage: page, pageSize })
  }

  const onSubmit = () => {
    const nameValue = getValues(searchName ?? 'search_name')
    setFilter({ ...filter, currentPage: 1, search: nameValue })
    // setLoading(true)
  }

  useEffect(() => {
    if (filter?.search) {
      const filtered =
        questions?.filter((q) =>
          normalizeLikeParam(htmlToRaw(q.question_content))
            .toLowerCase()
            ?.includes(normalizeLikeParam(filter.search))
        ) || []
      setFilteredQuestions(filtered)
    } else {
      setFilteredQuestions(questions)
    }
  }, [questions, filter])

  useEffect(() => {
    setTimeout(() => {
      if (editorRef?.current) {
        convertMathToImage(document.querySelector('#preview-question'))
      }
    }, 1000)
  }, [editorRef?.current, openPreview?.value])

  const { profileMe } = useUserContext()
  const allowRenderCreateEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.CREATE_EVENT_TEST
  )
  const allowRenderEditEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.EDIT_EVENT_TEST
  )

  return (
    <Spin size='large' spinning={loadPreview}>
      <div className='card card-bordered'>
        <div className='sapp-p-24px'>
          <div
            className={`d-flex justify-content-between align-items-start gap-5 mb-5
          `}
          >
            <div className='d-flex'>
              <div className='me-5'>
                <HookFormTextField
                  control={control}
                  name={searchName ?? `search_name`}
                  placeholder='Câu hỏi'
                  className='w-200px bg-gray-100 sapp-search-custom lh-1'
                  preIcon={
                    <i
                      className={`ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y
                       ms-5
                      `}
                    ></i>
                  }
                  isListScreen
                  onSubmit={onSubmit}
                />
              </div>
              <div className='me-5'>
                <ButtonPrimary title='Search' onClick={onSubmit} size='small' />
              </div>
              {/* <div className='me-5'>
                <HookFormTextField
                  control={control}
                  name={`get_percent_questions_${defaultType}`}
                  placeholder='100%'
                  type='number'
                  className='w-200px sapp-input-percent-total fs-6 lh-1 sapp-table-class-field'
                  disabled={watchType !== TYPE_OF_TEST.mix.value ? true : false}
                  onChange={(e) => {
                    setValue(e.target.value)
                    setPercentQuestionsMulti(e.target.value)
                  }}
                  postFix={<div className='px-2'>%</div>}
                  isListScreen
                />
              </div> */}
            </div>
            {(showAddQuestion || allowRenderCreateEventTest || allowRenderEditEventTest) && (
              <ButtonIconPrimary
                iconName={'plus'}
                title={'Add Question'}
                disabled={
                  disabled ||
                  (maxQuestionsNumber !== 'NO_MAX' && questions.length === maxQuestionsNumber)
                }
                onClick={() => handleChooseQuestions()}
                size='small'
              />
            )}
          </div>
          <table className='table align-middle fs-6 gy-5 dataTable no-footer'>
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-0'>
                <th className='w-40px'>#</th>
                {headers?.map((column) => (
                  <th key={column.label} className={`${column.className} fs-7 fw-bold`}>
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-semibold'>
              {(() => {
                if (!currentQuestions?.length) {
                  return (
                    <tr>
                      <td colSpan={7}>
                        <NotData />
                      </td>
                    </tr>
                  )
                }
                return currentQuestions?.map((question: any, i) => {
                  return (
                    <tr key={question.id}>
                      <td>{i + 1 + (filter.currentPage - 1) * filter.pageSize}</td>
                      <td>
                        <div className='sapp-text-truncate-1 text-wrap text-break'>
                          {htmlToRaw(question.question_content)}
                        </div>
                      </td>
                      <td>{qType[question.qType as keyof typeof qType] ?? '-'}</td>
                      <td className='sapp-w-175px'>
                        <HookFormTextField
                          control={control}
                          name={`question_percent_${question.id}`}
                          placeholder={' '}
                          type='number'
                          defaultValue={Number(question?.setting_grade)}
                          // postFix={<div className='px-2'>%</div>}
                          // className='is-invalid'
                          disabled={disabled || disbaleMark}
                          isListScreen
                          className={`${errors ? 'is-invalid' : ''}`}
                        />
                      </td>
                      <td className='w-50px'>
                        <div className='d-flex justify-content-end align-items-center gap-3'>
                          <ButtonIconOnly
                            iconName={'eye'}
                            activeColor='primary'
                            bg='gray-100'
                            applyActiveColorButton={false}
                            onClick={async () => {
                              setLoadPreview(true)
                              const res = await QuestionBankAPI.getQuestionById(question?.id)
                              if (res.data.question_topic_id) {
                                const { data: topic } = await QuestionBankAPI.getTopicById(
                                  res.data.question_topic_id
                                )
                                setOpenPreview({
                                  value: true,
                                  data: { questions: res.data, topic: topic.description },
                                })
                              } else {
                                setOpenPreview({
                                  value: true,
                                  data: { questions: res.data, topic: '' },
                                })
                              }
                              setLoadPreview(false)
                              // if (!question?.question_topic?.id) {
                              //   toast.error('Question not found!')
                              //   return
                              // }
                              // const link = document.createElement('a')
                              // link.href = `${PageLink.TOPIC}/${question?.question_topic?.id}?questionId=${question.id}&questionQType=${question.qType}`
                              // link.target = '_blank'
                              // link.style.display = 'none'
                              // document.body.appendChild(link)
                              // link.click()
                              // document.body.removeChild(link)
                            }}
                          />
                          {showdeleteQuestion && (
                            <div className='ms-1'>
                              <ButtonIconOnly
                                bg='gray-100'
                                iconName={'trash'}
                                activeColor='danger'
                                disabled={disabled}
                                onClick={() => {
                                  setTimeout(() => {
                                    confirm({
                                      okButtonCaption: 'Yes',
                                      cancelButtonCaption: 'No',
                                      body: 'Bạn có chắc chắn muốn xóa không?',
                                      onClick: () => {
                                        const arr = [...filteredQuestions]
                                        const newArr = arr?.filter((f) => f.id !== question.id)
                                        if (arr.length === 1) {
                                          setQuestions([])
                                        }
                                        if (newArr.length === 0 && filter.currentPage > 0) {
                                          handleChangePage(filter.currentPage, filter.pageSize)
                                        } else {
                                          setQuestions(newArr)
                                        }
                                      },
                                    })
                                  })
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })
              })()}
            </tbody>
          </table>
          <ErrorMessage>{error || ''}</ErrorMessage>
          <PagiantionSAPP
            currentPage={filter.currentPage}
            handlePaginationChange={(page: number, pageSize: number) => {
              handleChangePage(page, pageSize)
            }}
            pageSize={filter.pageSize}
            totalItems={filteredQuestions.length}
          />
        </div>
        {/* <PreviewQuestion
        open={openPreview.value}
        onClose={handleClosePreview}
        data={openPreview.data}
      /> */}
        <div className={`editor-wrap`} ref={editorRef}>
          <QuestTionPreview
            data={openPreview?.data?.questions}
            type={openPreview?.data?.questions?.qType}
            topic={openPreview?.data?.topic}
            handleClose={handleClosePreview}
            setOpenViewFile={setViewFile}
            open={openPreview.value}
          />
        </div>
      </div>
      <ModalPreviewFile open={viewFile.status} setOpen={setViewFile} url={viewFile.url} />
    </Spin>
  )
}

export default TestQuestionTable
