import React, { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { reInitMenu } from 'src/_metronic/helpers'
import { PageDataProvider } from 'src/_metronic/layout/core'
import SappHelmet from 'src/common/SappHelmet'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import BackToTop from '../backtop'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'

interface IProps {
  children: ReactNode
  pageTitle: string | undefined
  titleTab?: string
  backgroundColor?: string
  removePadding?: boolean
  classNameLayout?: string
  showHeader?: boolean
  onSave?: () => void
  onCancel: () => void
  loading?: boolean
  disabled?: boolean
  confirmOnCancel?: boolean
  customPrimaryButton?: ReactNode
  withContextHolder?: boolean
  maxWidth?: string | number | undefined
}

const CreateEditLayout = ({
  children,
  pageTitle,
  titleTab,
  backgroundColor: backgroundColor = 'bg-white',
  classNameLayout,
  onSave,
  onCancel,
  loading,
  disabled = false,
  confirmOnCancel = false,
  customPrimaryButton,
  maxWidth = 886,
}: IProps) => {
  const location = useLocation()

  // call init menu when key of location change
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const { confirm, contextHolder } = useConfirm()

  const onClose = () => {
    let body = [DESCRIPTION_POPUPCONFIRM]
    confirmOnCancel
      ? confirm({
          okButtonCaption: 'Yes',
          cancelButtonCaption: 'No',
          body,
          onClick: onCancel,
        })
      : onCancel()
  }

  return (
    <PageDataProvider>
      {contextHolder}
      <React.Fragment>
        {/* Start config title and meta */}
        <SappHelmet title={pageTitle || titleTab} />
        {/* End config title and meta */}

        <div
          className={`relative d-flex flex-column flex-column-fluid ${backgroundColor}`}
          id='kt_app_page'
          style={{ height: '100vh' }}
        >
          {/* Header */}
          <div className='fixed-top w-100 px-7 py-4 d-flex justify-content-between align-items-center bg-white shadow-xs'>
            <ButtonSecondary
              title='Cancel'
              className='py-2 px-5'
              type='button'
              onClick={onClose}
              size='small'
            />
            <p className='m-0 fs-3 fw-bold'>{titleTab || pageTitle}</p>

            {!customPrimaryButton ? (
              <ButtonPrimary
                title='Save'
                className='py-2 px-5'
                type='button'
                onClick={onSave}
                disabled={loading || disabled}
                loading={loading}
                size='small'
              />
            ) : (
              customPrimaryButton
            )}
          </div>
          <div
            className={`mt-25 flex-column h-100 flex-row-fluid scroll-y ${classNameLayout ?? ''}`}
            id='kt_app_wrapper'
          >
            {/* Start main of layout */}
            <div
              className={`app-main flex-column flex-row-fluid ${backgroundColor}`}
              id='kt_app_main'
            >
              <div className='d-flex flex-column flex-column-fluid'>
                <div id='kt_app_content' className='app-content flex-column-fluid'>
                  <div
                    id='kt_app_content_container'
                    className='container px-9 pb-7'
                    style={{ maxWidth: maxWidth }}
                  >
                    {children}
                  </div>
                </div>
              </div>
              {/* End main of layout */}
            </div>
          </div>
        </div>

        {/* Start button backtop */}
        <BackToTop />
        {/* End button backtop */}
      </React.Fragment>
    </PageDataProvider>
  )
}

export default CreateEditLayout
