import { uniqBy } from 'lodash'

type Option = {
  label?: string
  value?: string
  [key: string]: any
}

/**
 * Ensures the selected option is included in the dropdown options for an infinite scroll select component.
 *
 * In scenarios where a selected value exists but is not loaded due to pagination (e.g., option 1000
 * being on the 100th page of an infinite scroll), this function merges the existing options with
 * the selected option. It guarantees that the selected value is displayed in the dropdown without
 * duplicating entries.
 *
 * @param {Option[]} options - An array of options to include in the selection.
 * @param {Option} existedOption - A single option that should be added to the options list.
 * @param {string} key - The key by which to compare uniqueness (defaults to "value").
 * @returns {Option[]} - A filtered and deduplicated array of options.
 *
 * @example
 * const options = [
 *   { label: 'Option 1', value: '1' },
 *   { label: 'Option 2', value: '2' },
 *   ....
 *   { label: 'Option 1000', value: '1000' } at page 100
 * ];
 * const existedOption = { label: 'Option 1000', value: '1000' };
 *
 * const result = getSelectOptions(options, existedOption);
 * result: [
 *   { label: 'Option 1', value: '1' },
 *   { label: 'Option 2', value: '2' },
 *   { label: 'Option 1000', value: '1000' },
 *   ... // the rest without { label: 'Option 1000', value: '1000' }
 * ]
 */

export const getSelectOptions = (
  options?: Option[],
  existedOption?: Option,
  key: string = 'value'
): Option[] => {
  return uniqBy(
    [...(existedOption ? [existedOption] : []), ...(options ?? [])].filter((item) => item[key]),
    (e) => e[key]
  )
}
