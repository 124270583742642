import EditClassProfile from './EditClassProfile'

interface IProps {
  classDetail: any | undefined
}

const SettingClassUser = ({ classDetail }: IProps) => {
  return <EditClassProfile classDetail={classDetail} />
}

export default SettingClassUser
