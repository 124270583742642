import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { isUndefined } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import { RolesAPI } from 'src/apis/roles'
import { StaffAPI, uploadAvatarStaff } from 'src/apis/staffs'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import HeaderTab from 'src/components/base/HeaderTab'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  STATUS_FORM,
  VALIDATE_MAX_FULLNAME,
  VALIDATE_MIN_FULLNAME,
  VALIDATE_MIN_USERNAME,
  VALIDATE_PHONE,
  VALIDATION_FIELD,
} from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM, LANG_PLACEHOLDER } from 'src/constants/lang'
import { CODE_ADMIN } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { IError } from 'src/type'
import { IStudentDetail } from 'src/type/students'
import { phoneRegExp, sizeInBytes, usernamePattern } from 'src/utils'
import { z } from 'zod'
const { Option } = Select

interface IInputProps {
  full_name: string
  username: string
  email: string
  role: Array<any>
  phone: number
  avatar: File
  status: string
  employee_code?: string
}

interface IProps {
  userDetail: IStudentDetail | undefined
  getProfileDetail: Dispatch<SetStateAction<any>>
}

const ProfileDetail = ({ userDetail, getProfileDetail }: IProps) => {
  const navigate = useNavigate()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [roleLoading, setRoleLoading] = useState(false)

  const [showAvatarDefault, setShowAvatarDefault] = useState(false)
  const [roles, setRoles] = useState<any>([])
  const { confirm, contextHolder } = useConfirm()

  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`/profile/overview`),
    })
  }

  const validationSchema = z.object({
    full_name: z
      .string({ required_error: VALIDATION_FIELD })
      .min(3, { message: VALIDATE_MIN_FULLNAME })
      .max(100, { message: VALIDATE_MAX_FULLNAME }),
    email: z.string({ required_error: VALIDATION_FIELD }).email(),
    role: z.array(z.string()).nonempty({ message: VALIDATION_FIELD }),
    phone: z
      .string({ required_error: VALIDATION_FIELD })
      .regex(new RegExp(phoneRegExp), { message: VALIDATE_PHONE }),
    status: z.string(),
    avatar: z.any(),
    username: z
      .string({ required_error: VALIDATION_FIELD })
      .regex(new RegExp(usernamePattern), {
        message:
          'Username must have at least 6 characters without Vietnamese and space between words',
      })
      .min(6, { message: VALIDATE_MIN_USERNAME })
      .max(40, { message: 'Username Must Be Shorter Than Or Equal To 40 Characters' }),
  })

  const { handleSubmit, control, setValue, setError } = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const { profileMe: contextProfileMe } = useUserContext()
  const [profileMe, setProfileMe] = useState(contextProfileMe)
  useEffect(() => {
    setProfileMe(userDetail)
  }, [userDetail])
  const [defaultRoles, setDefaultRoles] = useState<any>()
  useEffect(() => {
    const defaultRole = profileMe?.roles?.map((role) => ({ name: role?.name, value: role?.id }))
    setDefaultRoles(defaultRole)
  }, [profileMe])

  const optionRoles = roles?.roles?.map((role: any) => ({ name: role?.name, value: role?.id }))
  const [defaultRoleValue, setDefaultRoleValue] = useState<any>([])

  // Sử dụng useEffect để theo dõi sự thay đổi của profileMe và cập nhật defaultRoleValue
  useEffect(() => {
    if (!profileMe) {
      setDefaultRoleValue([])
      return
    } else {
      const newDefaultRoleValue = profileMe?.roles?.map((role) => role?.id)
      setDefaultRoleValue(newDefaultRoleValue)
    }
  }, [profileMe])
  const onSubmit = async (data: IInputProps) => {
    const { email, full_name, phone, status, avatar } = data

    // Prevent form submission
    if (!isUndefined(avatar) && (avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE))
      return
    setLoadingSubmit(true)

    try {
      if (!isUndefined(avatar)) {
        await uploadAvatarStaff({ staffId: userDetail?.id, avatar: avatar })
      }
      const rolesList =
        defaultRoles &&
        defaultRoles.some((role: any) => role.name === data?.role?.map((role) => role?.name))
          ? defaultRoles
          : data?.role?.map((role) => ({ id: role }))

      const paramsUpdate: any = {
        full_name,
        email: email.toLowerCase(),
        phone,
        status,
        roles: rolesList || [],
        avatar: null,
      }

      if (!showAvatarDefault || !isUndefined(avatar)) {
        delete paramsUpdate.avatar
      }

      await StaffAPI.update({
        id: userDetail?.id,
        data: paramsUpdate,
      })
        .then(() => {
          toast.success('Update Successfully!')
          if (userDetail?.id) {
            getProfileDetail(userDetail?.id)
          }
          navigate('/profile/overview')
        })
        .catch((err) => {
          err?.response?.data?.error?.others?.forEach((e: IError) => {
            const errorMessage = e?.errors?.[0]?.message

            setError(e.property, { message: errorMessage })
          }, {})
          // if (err?.response?.data?.error?.code === '403|000000') {
          //   toast.error(DENIED_PERMISSIONS)
          // }
        })
    } catch (error) {
      setLoadingSubmit(false)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const fetchRoles = async (page_index: number, page_size: number, params?: Object) => {
    try {
      const res = await RolesAPI.get(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  const handlNextPageRole = async (params: Object) => {
    const totalPages = roles?.metadata?.total_pages
    const pageIndex = roles?.metadata?.page_index as number
    const pageSize = roles?.metadata?.page_size as number

    if (totalPages && pageIndex < totalPages) {
      const res: any = await fetchRoles(pageIndex + 1, pageSize, params)
      const newRoles = res?.data?.roles
      const updatedRoles = roles?.roles?.concat(newRoles)
      const uniqueRoles = Array.from(new Set(updatedRoles.map((role: any) => role.name))).map(
        (name) => {
          return updatedRoles.find((role: any) => role.name === name)
        }
      )

      setRoles({
        metadata: res?.data?.metadata,
        roles: uniqueRoles,
      })
    }
  }

  const getRoles = async ({ params }: any) => {
    setRoleLoading(true)
    try {
      const res: any = await fetchRoles(1, 10, params)
      setRoles(res?.data)
      setRoleLoading(false)
    } catch {
      setRoleLoading(false)
    }
  }

  useEffect(() => {
    getRoles({})
  }, [])

  useEffect(() => {
    if (!userDetail?.id) return
    setValue('email', userDetail?.detail?.email ?? '')
    setValue('full_name', userDetail?.detail?.full_name ?? '')
    setValue('phone', userDetail?.detail?.phone ?? '')
    setValue('status', userDetail?.status ?? '')
    setValue('username', userDetail?.username ?? '')
    setValue('role', defaultRoleValue ?? [])
  }, [
    setValue,
    userDetail?.detail?.email,
    userDetail?.detail?.full_name,
    userDetail?.detail?.phone,
    userDetail?.id,
    userDetail?.status,
    userDetail?.roles,
    userDetail?.username,
    profileMe,
    defaultRoleValue,
    roles,
  ])
  const [uniqueRoles, setUniqueRoles] = useState<any>([])

  useEffect(() => {
    if (optionRoles?.length > 0 && defaultRoles) {
      const allValues = [...optionRoles, ...defaultRoles].map((role) => role.value)
      const uniqueValues = new Set(allValues)

      const newUniqueRoles = Array.from(uniqueValues)
        .map((value) => {
          return [...optionRoles, ...defaultRoles].find((role) => role.value === value)
        })
        .map(({ name, ...rest }) => ({ ...rest, label: name }))

      setUniqueRoles(newUniqueRoles)
    }
  }, [defaultRoleValue, roles])

  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10'>
        <HeaderTab title='Profile Details' />

        <div className='collapse show'>
          <div className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>Avatar</label>
                <div className='col-lg-8'>
                  <SAPPHookUploadFile
                    name='avatar'
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    imagePreview={
                      userDetail?.detail?.avatar?.['150x150'] ??
                      userDetail?.detail?.avatar?.ORIGIN ??
                      avatar
                    }
                    accept={ACCEPT_UPLOAD_MIME_TYPE}
                    maxFileSize={DEFAULT_MAX_FILE_SIZE}
                    setShowAvatarDefault={setShowAvatarDefault}
                    removeAvatar={avatar}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-semibold fs-6'>
                  {LANG_PLACEHOLDER.FULLNAME}
                </label>

                <div className='col-lg-8 fv-row'>
                  <HookFormTextField
                    name='full_name'
                    control={control}
                    placeholder={LANG_PLACEHOLDER.FULLNAME}
                    defaultValue={userDetail?.detail?.full_name}
                    required
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-semibold fs-6'>
                  Username
                </label>

                <div className='col-lg-8 fv-row'>
                  <HookFormTextField
                    name='username'
                    control={control}
                    placeholder='Username'
                    defaultValue={userDetail?.username}
                    disabled
                    required
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-semibold fs-6'>Email</label>

                <div className='col-lg-8 fv-row'>
                  <HookFormTextField
                    name='email'
                    control={control}
                    placeholder='Email'
                    defaultValue={userDetail?.detail?.email}
                    required
                    disabled
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-semibold fs-6'>Phone</label>

                <div className='col-lg-8 fv-row'>
                  <HookFormTextField
                    name='phone'
                    control={control}
                    placeholder='Phone Number'
                    defaultValue={userDetail?.detail?.phone}
                    required
                    disabled
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-semibold fs-6'>Role</label>

                <div className='col-lg-8 fv-row'>
                  <HookFormSelectMultiple
                    loading={roleLoading}
                    name='role'
                    control={control}
                    placeholder='Role'
                    options={uniqueRoles}
                    defaultValue={defaultRoles}
                    disabled
                    onSearch={(text: string | undefined) => getRoles({ params: { name: text } })}
                    handleNextPage={(e: any) => handlNextPageRole({ params: { name: e } })}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-semibold fs-6'>Status</label>

                <div className='col-lg-8 fv-row'>
                  <HookFormSelectAntd
                    name='status'
                    control={control}
                    size='large'
                    defaultValue={userDetail?.status}
                    className='fs-6'
                    disabled
                    options={STATUS_FORM}
                  />
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <ButtonSecondary
                title='Discard'
                onClick={hanleCancel}
                className='btn-active-light-primary me-2'
              />
              <ButtonPrimary
                title='Save Changes'
                loading={loadingSubmit}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileDetail
