import { Select } from 'antd'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { NotificationAPI } from 'src/apis/notifications'
import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { MOCKUP_HEADER, PageLink } from 'src/constants'
import { CODE_ADMIN, TITLE_NOTIFICATIONS_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { Role } from 'src/type'
import { INotificationRes, NotificationStatus } from 'src/type/notification'
import { cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import NotificationAction from './NotificationAction'

const { Option } = Select

export const NOTIFICATION_STATUS = [
  {
    label: 'Đã gửi',
    value: 'SENT',
  },
  {
    label: 'Hủy',
    value: 'CANCEL',
  },
  {
    label: 'Thu hồi',
    value: 'RETRIEVE',
  },
  {
    label: 'Hẹn giờ gửi',
    value: 'TIMER',
  },
  {
    label: 'Đang hiển thị',
    value: 'SHOWING',
  },
  {
    label: 'Đã kết thúc',
    value: 'ENDED',
  },
]
export const NOTIFICATION_TYPE = [
  {
    label: 'Email',
    value: 'EMAIL',
  },
  {
    label: 'Notification',
    value: 'NOTIFICATION',
  },
  {
    label: 'Noti & email',
    value: 'ALL',
  },
  {
    label: 'Pinned',
    value: 'PINNED',
  },
]

type NotificationType = 'EMAIL' | 'NOTIFICATION' | 'ALL' | 'PINNED'

// const NOTIFICATION_STATUS_MAP: Record<NotificationStatus, NotificationStatus[]> = {
//   SENT: ['RETRIEVE'],
//   CANCEL: [],
//   RETRIEVE: [],
//   TIMER: ['CANCEL'],
// }
interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  notiList?: {
    notifications: INotificationRes[]
    meta: {
      total_pages: number
      total_records: number
      page_index: number
      page_size: number
    }
  }
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchNotiList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
}
const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Title',
    className: 'min-w-350px pe-5 ',
  },
  {
    label: 'Type',
    className: 'min-w-200px pe-5',
  },
  {
    label: 'Send to',
    className: 'min-w-250px pe-5',
  },
  {
    label: 'Status',
    className: 'min-w-90px pe-5',
  },
  {
    label: '',
    className: 'min-w-30px pe-5',
  },
]

const NotificationTable = ({
  fetchNotiList,
  filterParams,
  getParams,
  isCheckedAll,
  loading,
  notiList,
  queryParams,
  setLoading,
  toggleCheckAll,
  handleChangeParams,
}: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)
  const navigate = useNavigate()
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)

  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.title.trimStart().trimEnd() ?? '',
      queryParams.type ?? '',
      queryParams.sendTo ?? '',
      queryParams.sortType ?? '',
      queryParams.status ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : ''
    )
  )
  const { control, watch, setValue } = useForm<any>({
    mode: 'onChange',
  })
  useEffect(() => {
    setLoading(true)
    fetchNotiList(page_index || 1, page_size || 10, filterParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    notiList?.notifications
      ?.map((e: any) => ({ label: e.status, value: e.status }))
      ?.forEach((option: any, index: any) => {
        setValue(`status${index}`, option.value)
      })
  }, [setValue, notiList?.notifications])

  const handleChange = async (value: string, id: string, data: Partial<INotificationRes>) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: 'Bạn có chắc chắn muốn đổi trạng thái không?',
      onClick: async () => {
        try {
          await NotificationAPI.updateNotification(id, {
            status: value,
          })
          fetchNotiList(page_index || 1, page_size || 10, filterParams)
        } catch (error: any) {
          // if (error?.response?.data?.error?.code === '403|000000') {
          fetchNotiList(page_index || 1, page_size || 10, filterParams)
          // }
        }
      },
      onClose: () => {
        fetchNotiList(page_index || 1, page_size || 10, filterParams)
      },
    })
  }

  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchNotiList(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }

  const isSelectDisabled = (
    currentStatus: NotificationStatus,
    notificationType: NotificationType
  ): boolean => {
    switch (notificationType) {
      case 'EMAIL':
      case 'ALL':
        switch (currentStatus) {
          case 'SENT':
          case 'CANCEL':
          case 'RETRIEVE':
            return true
          default:
            return false
        }

      case 'NOTIFICATION':
        switch (currentStatus) {
          case 'CANCEL':
          case 'RETRIEVE':
            return true
          default:
            return false
        }
      case 'PINNED':
        switch (currentStatus) {
          case 'CANCEL':
          case 'RETRIEVE':
          case 'ENDED':
            return true
          default:
            return false
        }
      default:
        return false
    }
  }

  const isOptionDisabled = (
    currentStatus: NotificationStatus,
    newStatus: NotificationStatus,
    notificationType: NotificationType
  ): boolean => {
    const mapping = {
      EMAIL: {
        SENT: [''],
        CANCEL: [''],
        RETRIEVE: [''],
        TIMER: ['CANCEL'],
        SHOWING: [''],
        ENDED: [''],
      },
      NOTIFICATION: {
        SENT: ['RETRIEVE'],
        CANCEL: [''],
        RETRIEVE: [''],
        TIMER: ['CANCEL'],
        SHOWING: [''],
        ENDED: [''],
      },
      ALL: {
        SENT: [''],
        CANCEL: [''],
        RETRIEVE: [''],
        TIMER: ['CANCEL'],
        SHOWING: [''],
        ENDED: [''],
      },
      PINNED: {
        SENT: [''],
        CANCEL: [''],
        RETRIEVE: [''],
        TIMER: ['CANCEL'],
        SHOWING: ['RETRIEVE'],
        ENDED: [''],
      },
    }

    const disabledActions = mapping[notificationType][currentStatus]

    return !disabledActions.includes(newStatus)
  }
  const { profileMe } = useUserContext()
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_NOTIFICATIONS_GR.EDIT_NOTIFICATION)

  const Action = (type: string, data: any) => {
    if (type === 'edit') {
      navigate(`${PageLink.SEND_NOTIFICATIONS}/${data.id}`)
    }
  }

  return (
    <div className='card-body py-10 px-10'>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={notiList?.notifications || []}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
        hasCheck={false}
        classResponsive={''}
      >
        {loading ? (
          <>
            {MOCKUP_HEADER.map((header, i) => (
              <LoadingTable key={header} headers={headers} />
            ))}
          </>
        ) : (
          <>
            {notiList?.notifications?.map((e, index: number) => {
              //const isChecked = checkedList.includes(e.id) ${e.status === 'TIMER'}
              return (
                <tr className='position-relative' key={e.id}>
                  <td>
                    {index + 1 + (notiList?.meta?.page_index - 1) * notiList?.meta?.page_size}
                  </td>
                  <td className='text-start'>
                    <Link
                      className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                      to={`${PageLink.SEND_NOTIFICATIONS}/${e.id}`}
                    >
                      {e?.title}
                    </Link>
                  </td>
                  <td className='text-start text-capitalize sapp-text-light-inverse'>
                    {e?.type?.toLowerCase() === 'all'
                      ? 'Noti & email'
                      : e?.type?.toLowerCase() ?? '-'}
                  </td>

                  <td className='text-start sapp-text-light-inverse'>
                    {e?.groups?.map((f, index) => (
                      <React.Fragment key={index}>
                        {f.name}
                        {index < e.groups.length - 1 && <br />}
                      </React.Fragment>
                    )) ?? '-'}
                  </td>

                  <td className='text-start'>
                    <HookFormSelectAntd
                      allowClear={false}
                      size='large'
                      name={`status${index}`}
                      control={control}
                      dropdownStyle={{ maxWidth: 100 }}
                      classNameHeight='sapp-h-35px'
                      placeholder='Status'
                      filterOption={true}
                      onChange={(selectedValue: unknown) =>
                        handleChange(selectedValue as string, e.id || '', e as any)
                      }
                      disabled={
                        !allowRenderEdit ||
                        isSelectDisabled(
                          watch(`status${index}`) as NotificationStatus,
                          e.type as NotificationType
                        )
                      }
                      suffixIcon={
                        isSelectDisabled(
                          watch(`status${index}`) as NotificationStatus,
                          e.type as NotificationType
                        ) ? null : (
                          <svg
                            width='12'
                            height='8'
                            viewBox='0 0 12 8'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M5.90836 7.36667C5.79868 7.3673 5.68996 7.34628 5.58843 7.30481C5.4869 7.26334 5.39456 7.20224 5.31669 7.125L0.316689 2.125C0.238582 2.04753 0.176587 1.95536 0.13428 1.85382C0.0919725 1.75227 0.0701904 1.64334 0.0701904 1.53333C0.0701904 1.42332 0.0919725 1.3144 0.13428 1.21285C0.176587 1.1113 0.238582 1.01914 0.316689 0.941668C0.472824 0.786459 0.684035 0.699341 0.904189 0.699341C1.12434 0.699341 1.33555 0.786459 1.49169 0.941668L5.90836 5.35834L10.3167 0.941668C10.4728 0.786459 10.684 0.699341 10.9042 0.699341C11.1243 0.699341 11.3356 0.786459 11.4917 0.941668C11.5698 1.01914 11.6318 1.1113 11.6741 1.21285C11.7164 1.3144 11.7382 1.42332 11.7382 1.53333C11.7382 1.64334 11.7164 1.75227 11.6741 1.85382C11.6318 1.95536 11.5698 2.04753 11.4917 2.125L6.49169 7.125C6.33647 7.27896 6.12697 7.36575 5.90836 7.36667Z'
                              fill='#7E8299'
                            />
                          </svg>
                        )
                      }
                      options={NOTIFICATION_STATUS.map((status) => ({
                        label: status.label,
                        value: status.value,
                        disabled: isOptionDisabled(
                          e.status,
                          status.value as NotificationStatus,
                          e.type as NotificationType
                        ),
                      }))}
                    />
                  </td>
                  <td
                    className='text-end sapp-absolute-column'
                    style={{ zIndex: `${(notiList?.notifications || []).length + 10 - index}` }}
                  >
                    {e?.status === 'TIMER' && <NotificationAction Action={Action} data={e} />}
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <div className='px-10'>
        <PagiantionSAPP
          currentPage={notiList?.meta?.page_index || 1}
          pageSize={notiList?.meta?.page_size || 10}
          totalItems={notiList?.meta?.total_records}
          handlePaginationChange={handlePaginationChange}
        />
      </div>
    </div>
  )
}

export { NotificationTable }
