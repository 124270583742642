import { zodResolver } from '@hookform/resolvers/zod'
import React, { memo, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { CoursesAPI } from 'src/apis/courses'
import SappDrawer from 'src/components/base/SappDrawer'
import HookFormCheckBox from 'src/components/base/checkbox/HookFormCheckBox'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { useConfirm } from 'src/hooks/use-confirm'
import { z } from 'zod'
import { Select } from 'antd'
import ErrorMessage from 'src/common/ErrorMessage'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { useParams } from 'react-router-dom'
import { useCourseContext } from 'src/context/CourseProvider'
import { ICourseLink, ICourseList, ICourseSectionList, IInputCourseLink } from 'src/type/courses'
import { convertItemSelect } from 'src/utils'
import SappLabel from 'src/components/base/label/SappLabel'
import { debounce, isEmpty, isUndefined } from 'lodash'
import NotData from 'src/components/NotData'
import { DESCRIPTION_POPUPCONFIRM, LANG_COURSES } from 'src/constants/lang'

const LinkSection = ({ open, setOpen, position, body, title }: ICourseLink) => {
  //TODO: state này để  get course
  const [courses, setCourses] = useState<ICourseList>()
  //TODO: state này để lấy id của course
  const [courseId, setCourseId] = useState(null)
  //TODO: state này dùng để lấy course section
  const [courseSectionList, setCourseSectionList] = useState<ICourseSectionList | undefined>()
  const { confirm, contextHolder } = useConfirm()
  //TODO: lấy id từ url
  const { id } = useParams()
  const { getCourseDetail } = useCourseContext()
  const [loading, setLoading] = useState(false)

  //TODO: validate form
  const validationSchema = z.object({
    courseId: z.string().min(1),
    course_section: z
      .array(
        z.object({
          course_section_ids: z.boolean(),
        })
      )
      .refine((val) => val.some((item) => item.course_section_ids === true), {
        message: 'Course section must contain at least 1 element(s)',
        path: ['course_section_link'],
      })
      .default([]),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm<IInputCourseLink>({
    mode: 'onSubmit',
    resolver: zodResolver(validationSchema),
  })

  //TODO: Loop through each section and set the default value to an empty string
  useEffect(() => {
    courseSectionList?.sections.forEach((_data, index: number) => {
      setValue(`course_section.${index}.course_section_ids`, false)
    })
  }, [courseSectionList, setValue])

  // TODO: dùng để submit form
  const onSubmit = async (data: IInputCourseLink) => {
    const course_section_ids = data?.course_section?.map((section) => section?.course_section_ids)
    const ids = courseSectionList?.sections?.map((section) => section?.id) as any[]

    const resultArray = course_section_ids
      .map((boolValue: boolean, index: number) => {
        if (boolValue) {
          return { id: ids[index], position: position + index + 1 }
        } else {
          return null
        }
      })
      .filter(Boolean)

    setLoading(true)
    try {
      const res = await CoursesAPI.linkCourseSections({ course_id: id, children: resultArray })
      if (res?.success && id) {
        getCourseDetail(id)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }

    reset()
    setOpen(false)
    // setCourses([])
    setValue('courseId', '')
    setCourseId(null)
  }

  // TODO: function này dùng để đóng drawer
  const handleClose = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        setOpen(false)
        setValue('courseId', '')
        reset()
        setCourseId(null)
        // setCourses(null)
      },
    })
  }

  //TODO: hàm này dùng call API get course
  const fetchCourse = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await CoursesAPI.get({
        page_index: page_index,
        page_size: page_size,
        params: params,
      })
      return res
    } catch (error) {}
  }

  const handlNextPageCourse = async (params: Object) => {
    const totalPages = courses?.metadata?.total_pages
    const pageIndex = courses?.metadata?.page_index as number
    const pageSize = courses?.metadata?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchCourse(pageIndex + 1, pageSize, params)
      const results = courses.courses.concat(res?.data?.courses as any)
      setCourses({
        metadata: res?.data?.metadata,
        courses: results,
      })
    }
  }

  //TODO: hàm này dùng để call API course section list
  const fetchCourseSectionList = async (
    page_index: number,
    page_size: number,
    courseId: string | null
  ) => {
    await CoursesAPI.courseSectionList({
      page_index: page_index,
      page_size: page_size,
      courseId: courseId,
      type: 'PART',
    }).then((res) => setCourseSectionList(res?.data))
  }

  //TODO: call API course section
  useEffect(() => {
    if (courseId) {
      fetchCourseSectionList(1, 10, courseId)
    }
  }, [courseId])

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchCourseSectionList(page_index, page_size, courseId)
  }

  const getCourse = async (params?: any) => {
    const res = await fetchCourse(1, 20, params)
    setCourses(res?.data)
  }

  const debounceSearchCourse = debounce((e) => {
    getCourse({ name: e })
  }, 500)

  return (
    <SappDrawer
      rootClassName='sapp-question_essay-drawer sapp-drawer--custom'
      open={open}
      title={title}
      cancelButtonCaption={'Cancel'}
      okButtonCaption={'Confirm'}
      handleSubmit={handleSubmit(onSubmit)}
      handleClose={handleClose}
      width='60%'
      body={body}
      classNameHeader='sapp-h-90px'
      loading={loading}
    >
      {contextHolder}
      <HookFormSelectAntd
        name='courseId'
        control={control}
        size='large'
        label={LANG_COURSES.selectCourse}
        placeholder={LANG_COURSES.pleaseSelect}
        showSearch
        onChange={(val) => setCourseId(val as any)}
        onSearch={(e: any) => {
          if (e === undefined) {
            return
          }
          debounceSearchCourse(e)
        }}
        handleNextPage={(e: any) => handlNextPageCourse({ params: { name: e } })}
        required
        onFocus={async () => {
          if (isUndefined(courses?.courses)) {
            await getCourse({})
            return
          }
        }}
        classNameHeight='sapp-h-45px'
        options={
          convertItemSelect(
            courses?.courses && courses?.courses?.filter((course) => course.id !== id)
          ) ?? []
        }
      />

      {getValues('courseId') && (
        <>
          <SappLabel label={LANG_COURSES.selectSection} className='mt-10' />
          {!isEmpty(courseSectionList?.sections) ? (
            courseSectionList?.sections
              ?.sort((a, b) => new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf())
              ?.map((data, index: number) => (
                <div className={`${index === 0 ? 'mt-0' : 'mt-8'}`} key={data?.id}>
                  <HookFormCheckBox
                    control={control}
                    name={`course_section.${index}.course_section_ids`}
                    title={data?.name}
                    classNameTitle='ms-3 sapp-form-check-label'
                    defaultValue={false}
                  />
                </div>
              ))
          ) : (
            <NotData />
          )}
        </>
      )}
      {(errors?.course_section as any)?.course_section_link && getValues('courseId') && (
        <ErrorMessage>{(errors?.course_section as any)?.course_section_link?.message}</ErrorMessage>
      )}
      <PagiantionSAPP
        currentPage={courseSectionList?.meta?.page_index || 1}
        pageSize={courseSectionList?.meta?.page_size || 10}
        totalItems={courseSectionList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </SappDrawer>
  )
}
export default memo(LinkSection)
