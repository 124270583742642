import { useEffect } from 'react'
import StudentProfileDetail from './StudentProfileDetail'

interface IProps {}

const OverviewStudentProfile = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <StudentProfileDetail />
    </>
  )
}

export default OverviewStudentProfile
