import { zodResolver } from '@hookform/resolvers/zod'
import { debounce } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { MailLogApi } from 'src/apis/mail-logs'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import ModalPreviewMailLog from 'src/components/base/ModalPreviewMailLog'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ListFilterLayout from 'src/components/layout/listFilter'
import PageLayouts from 'src/components/layout/PageLayouts'
import { MAIL_LOG_STATUS, PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { ITabs } from 'src/type'
import { IMailAction, IMailLog, IMailLogList } from 'src/type/mail-log'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { z } from 'zod'
import TableMailLog from './tableMailLog'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: 'Mail Logs',
  },
]
const fieldNames = ['email', 'action', 'status']

const initialValues: Record<string, string> = {
  email: '',
  action: '',
  status: '',
}

const MailLog = () => {
  const [mailLogList, setMailLogList] = useState<IMailLogList>()
  const navigate = useNavigate()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const [mailActions, setMailActions] = useState<IMailAction[]>()
  const [showMailAction, setShowMailAction] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [mailLogDetail, setMailLogDetail] = useState<IMailLog>()

  const searchParams = new URLSearchParams(search)

  const queryParams = {
    email: searchParams.get('email') ?? '',
    action: searchParams.get('action') ?? '',
    status: searchParams.get('status') ?? '',
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }
  const validationSchema = z.object({
    email: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    action: z.string().optional(),
    status: z.string().optional(),
  })
  const { control, getValues, reset, setValue, watch } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      email: getValues('email'),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
      action: getValues('action'),
      status: getValues('status'),
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const filterParams = cleanParamsAPI({
    email: queryParams.email.trimStart().trimEnd() ?? '',
    action: queryParams.action,
    status: queryParams.status,
    fromDate: queryParams.fromDate,
    toDate: queryParams.toDate,
  })

  const getParams = (
    email: string,
    action: string,
    status: string,
    fromDate?: Date | string,
    toDate?: Date | string,
    dateField?: string
  ) => ({
    email,
    action,
    status,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })

  const fetchMailLogList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      setLoading(true)
      const res = await MailLogApi.get({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setMailLogList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('email').trimStart().trimEnd() ?? '',
        getValues('action'),
        getValues('status'),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : ''
      )
    )
    fetchMailLogList(1, queryParams.page_size || 10, cleanedParams)

    handleChangeParams(1, queryParams.page_size || 10)
    setShowMailAction(true)
  }

  const handleResetFilter = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    navigate(PageLink.MAIL_LOGS)
    fetchMailLogList(1, 10)
  }

  useEffect(() => {
    const fromDate = watch('fromDate')
    const toDate = watch('toDate')

    if (fromDate && toDate && moment(toDate).isBefore(fromDate)) {
      setValue('toDate', moment(fromDate).endOf('day').toDate())
    }

    if (toDate && fromDate && moment(fromDate).isAfter(toDate)) {
      setValue('fromDate', moment(toDate).startOf('day').toDate())
    }
  }, [watch('fromDate'), watch('toDate')])

  const disabledFromDate = (current: any) => {
    const toDate = watch('toDate')
    if (toDate) {
      return current > moment(toDate).endOf('day')
    }
    return false
  }

  const disabledToDate = (toDate: any) => {
    const fromDate = watch('fromDate')
    if (fromDate) {
      return toDate < moment(fromDate).startOf('day')
    }
    return false
  }

  // goi API class code
  const fetchMailActions = async () => {
    try {
      const res = await MailLogApi.getActions()
      return res
    } catch (error) {}
  }

  const getMailActions = async () => {
    const res = await fetchMailActions()
    if (res?.data) {
      setMailActions(res?.data)
    }
  }

  // call search codes
  const debounceSearchMailActions = debounce((e) => {
    getMailActions()
  }, 500)

  const newMailActions = mailActions

  // gọi lại API show mail actions
  useEffect(() => {
    if (queryParams.action && !showMailAction) {
      getMailActions()
    }
  }, [queryParams.action])

  // Show popup khi đã lấy được thông tin mail log
  useEffect(() => {
    if (mailLogDetail) {
      setShowPreview(true)
    }
  }, [mailLogDetail])

  const handlePreviewMailLog = async (id: string) => {
    try {
      const res = await MailLogApi.getMailLog(id)
      if (res.data) {
        setMailLogDetail(res.data)
      }
      return res
    } catch (error) {}
  }

  return (
    <PageLayouts pageTitle={'Mail Logs'} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='card-header border-0 pt-10'>
          <ListFilterLayout className='w-100'>
            <div className='card-title justify-content-center mb-0 mx-0 mt-0'>
              <HookFormTextField
                control={control}
                name='email'
                placeholder='Search by receiver'
                defaultValue={queryParams?.email}
                onSubmit={onSubmit}
                isListScreen
              />
            </div>

            <HookFormSelectAntd
              classNameHeight='sapp-h-40'
              control={control}
              name='action'
              placeholder='Action'
              defaultValue={queryParams.action ?? ''}
              showSearch
              onFocus={async () => {
                if (!mailActions) {
                  await getMailActions()
                  return
                }
              }}
              onSearch={(e) => {
                if (e === undefined) {
                  return
                }
                debounceSearchMailActions(e)
              }}
              options={newMailActions ?? []}
            />

            <HookFormSelectAntd
              name='status'
              placeholder='Status'
              control={control}
              size='large'
              defaultValue={queryParams.status ?? ''}
              classNameHeight='sapp-h-40'
              options={MAIL_LOG_STATUS}
            />

            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams?.fromDate}
              disabledDate={disabledFromDate}
              isListScreen
            />

            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams?.toDate}
              disabledDate={disabledToDate}
              isListScreen
            />
          </ListFilterLayout>
        </div>
        <div className='card-header border-0 pt-6'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={onSubmit}
              resetClick={handleResetFilter}
              disabled={loading}
              loading={loading}
            />
          </div>
        </div>
        <TableMailLog
          handlePreviewMailLog={handlePreviewMailLog}
          mailLogList={mailLogList ?? ({} as IMailLogList)}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchMailLogList={fetchMailLogList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          checkedList={[]}
          toggleCheckAll={() => {}}
          isCheckedAll={false}
        />
      </div>
      <ModalPreviewMailLog
        openPreview={showPreview}
        setOpenPreview={setShowPreview}
        mailLogDetail={mailLogDetail as IMailLog}
      />
    </PageLayouts>
  )
}
export default withAuthComponents(MailLog)
