import { MutableRefObject, ReactNode, useEffect, useRef } from 'react'
import { moveAndResizeElement } from 'src/utils/dragandresize'

interface IProp {
  children: ReactNode
  getResize?: (data: any, index: number) => void
  index: number
  position?: any
  parentContentRef: MutableRefObject<HTMLElement | any>
}
export const ResizableComponent = ({
  children,
  getResize,
  index,
  position,
  parentContentRef,
}: IProp) => {
  const elementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (elementRef.current && parentContentRef.current) {
      moveAndResizeElement(elementRef.current, ({ top, left, width, height }) => {
        getResize && getResize({ top, left, width, height }, index)
      })
    }
  }, [])

  return (
    <div
      ref={elementRef}
      className='sapp-opacity-bg-border resizable moveable-resizable'
      style={{
        width: position?.width,
        height: position?.height,
        top: position?.top,
        left: position?.left,
        background: 'transparent',
        border: 'none',
        resize: 'none',
        position: 'absolute',
        cursor: 'all-scroll',
      }}
    >
      <div className='resizers' style={{ display: 'none' }}>
        <div className='resizer top-left'></div>
        <div className='resizer top-right'></div>
        <div className='resizer bottom-left'></div>
        <div className='resizer bottom-right'></div>
        <div className='resizer right'></div>
        <div className='resizer left'></div>
      </div>
      {children}
    </div>
  )
}
