import { fetcher } from 'src/services/request'
import { IResponse } from 'src/type'
import { IFacility, IFacilities, IRoom, IRoomList } from 'src/type/area'

export default class ClassroomApi {
  static getAreaClasses({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IFacilities>> {
    return fetcher(`facilities?page_index=${page_index}&page_size=${page_size}`, {
      params: { ...params },
    })
  }

  static getAreaClassDetail(id: string): Promise<IResponse<IFacility>> {
    return fetcher(`facilities/${id}`)
  }

  static createAreaClass(data: {
    name: string
    code: string
    ward_code: string
    address?: string
  }) {
    return fetcher(`facilities`, {
      method: 'POST',
      data,
    })
  }

  static editAreaClass(
    id: string,
    data: { name?: string; code?: string; ward_code?: string; address?: string; status?: string }
  ) {
    return fetcher(`facilities/${id}`, {
      method: 'PUT',
      data,
    })
  }

  static deleteAreaClass(id: string) {
    return fetcher(`facilities/${id}`, {
      method: 'DELETE',
    })
  }

  static getRoomList({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IRoomList>> {
    return fetcher(`rooms?page_index=${page_index}&page_size=${page_size}`, {
      params: { ...params },
    })
  }

  static getRoomDetail(id: string): Promise<IResponse<IRoom>> {
    return fetcher(`rooms/${id}`)
  }

  static createRoom(data: {
    name: string
    code: string
    capacity: number
    deferred_student: number
    new_student: number
    type: string
    facility_id: string
    address?: string
  }) {
    return fetcher(`rooms`, {
      method: 'POST',
      data,
    })
  }

  static editRoom(
    id: string,
    data: { name?: string; code?: string; ward_code?: string; address?: string; status?: string }
  ) {
    return fetcher(`rooms/${id}`, {
      method: 'PUT',
      data,
    })
  }

  static deleteRoom(id: string) {
    return fetcher(`rooms/${id}`, {
      method: 'DELETE',
    })
  }
}
