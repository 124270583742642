import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { debounce, isEmpty, isUndefined } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import avatarDefault from 'src/_metronic/assets/media/avatars/blank.png'
import { RolesAPI } from 'src/apis/roles'
import { createStaffs, StaffAPI, uploadAvatarStaff } from 'src/apis/staffs'
import withAuthComponents from 'src/components/auth/with-auth-components'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_PASSWORD,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  MESSAGE_VALIDATION_USERNAME,
  PageLink,
  PASSWORD_NOT_MATCH,
  STATUS_FORM,
  VALIDATE_MAX_FULLNAME,
  VALIDATE_MIN_FULLNAME,
  VALIDATE_MIN_USERNAME,
  VALIDATE_PASSWORD,
  VALIDATE_PHONE,
  VALIDATION_FIELD,
} from 'src/constants'
import { LANG_PLACEHOLDER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_STAFF_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { EStatusUser, IError, ITabs, Role } from 'src/type'
import { IStaffs } from 'src/type/staffs.'
import { IStudentDetail } from 'src/type/students'
import { phoneRegExp, sizeInBytes, usernamePattern, validatePassword } from 'src/utils'
import { z } from 'zod'
const { Option } = Select

export interface IUpdateStaff {
  full_name: string
  email: string
  phone: number
  status: string
  roles: Array<any>
  avatar: any
  sex: string
}

const CreateAndUpdateStaff = () => {
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [roles, setRoles] = useState<any>()
  const [staffDetail, setStaffDetail] = useState<IStudentDetail>()
  const { id } = useParams()
  const navigate = useNavigate()
  const [showAvatarDefault, setShowAvatarDefault] = useState(false)
  const [loading, setLoading] = useState(false)
  const { profileMe } = useUserContext()

  const fetchRoles = async (page_index: number, page_size: number, params?: Object) => {
    try {
      const res = await RolesAPI.get(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  const handlNextPageRole = async (params: Object) => {
    const totalPages = roles?.metadata?.total_pages
    const pageIndex = roles?.metadata?.page_index as number
    const pageSize = roles?.metadata?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res: any = await fetchRoles(pageIndex + 1, pageSize, params)
      const results = roles?.roles?.concat(res?.data?.roles)
      setRoles({
        metadata: res?.data?.metadata,
        roles: results,
      })
    }
  }

  const [roleLoading, setRoleLoading] = useState(false)
  const getRoles = async ({ params }: any) => {
    setRoleLoading(false)
    try {
      const res: any = await fetchRoles(1, 20, params)
      setRoles(res?.data)
    } catch {
      setRoleLoading(false)
    }
  }

  useEffect(() => {
    if (allowRenderUpdateRole) {
      getRoles({})
    }
  }, [])

  const DEFAULT_SCHEMA = {
    full_name: z
      .string({ required_error: VALIDATION_FIELD })
      .min(3, { message: VALIDATE_MIN_FULLNAME })
      .max(100, { message: VALIDATE_MAX_FULLNAME }),
    username: z
      .string({ required_error: VALIDATION_FIELD })
      .regex(new RegExp(usernamePattern), {
        message: MESSAGE_VALIDATION_USERNAME,
      })
      .min(6, { message: VALIDATE_MIN_USERNAME })
      .max(40, { message: 'Username Must Be Shorter Than Or Equal To 40 Characters' }),
    email: z.string({ required_error: VALIDATION_FIELD }).email(),
    phone: z
      .string({ required_error: VALIDATION_FIELD })
      .regex(new RegExp(phoneRegExp), { message: VALIDATE_PHONE }),
    avatar: z.any(),
    status: z.string().optional(),
    role: z.any(),
    sex: z.string({ required_error: VALIDATION_FIELD }).optional(),
  }

  const schemaCreate = z
    .object(
      Object.assign({}, DEFAULT_SCHEMA, {
        password: z.string().optional().default(''),
        confirmPassword: z.string().optional().default(''),
      })
    )
    .refine((data) => data.password === data.confirmPassword, {
      message: PASSWORD_NOT_MATCH,
      path: ['confirmPassword'],
    })

  const schemaUpdate = z.object(DEFAULT_SCHEMA)

  const validation = useMemo(
    () => (id ? schemaUpdate : schemaCreate),
    [id, schemaCreate, schemaUpdate]
  )

  const { control, handleSubmit, setValue, setError, reset, clearErrors } = useForm<IStaffs>({
    resolver: zodResolver(validation),
    mode: 'onChange',
  })

  const onSubmitCreate = async (data: IStaffs) => {
    const { email, full_name, phone, username, avatar, sex } = data
    let payload = {
      email: email.toLowerCase(),
      full_name: full_name,
      password: data.password,
      phone: phone,
      username: username.toLowerCase(),
      status: EStatusUser.ACTIVE,
      roles: data.role?.map((role) => ({ id: role })) || [],
      sex: sex,
    }

    if (data.password) {
      if (!validatePassword.test(data.password)) {
        setError('password', { message: VALIDATE_PASSWORD })
        return
      } else {
        clearErrors('password')
        clearErrors('confirmPassword')
      }
    } else {
      delete payload.password
    }
    setLoading(true)
    try {
      const res = await createStaffs({ ...payload })
      const dataStaffs = res?.data
      toast.success('Create Successfully!')
      setUploadSuccess(true)
      if (allowRenderUpdateRole) {
        const roleIds = data?.role?.map((role) => role.value || role) || []
        try {
          await StaffAPI.updateRoles({
            id: dataStaffs?.id,
            role_ids: roleIds,
          })
        } catch (error) {
          console.error('Error updating staff roles:', error)
        }
      }

      if (isUndefined(avatar)) {
        navigate(PageLink.STAFFS)
      }

      if (!isUndefined(avatar)) {
        await uploadAvatarStaff({ staffId: dataStaffs?.id, avatar: avatar }).then(() =>
          navigate(PageLink.STAFFS)
        )
      }
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IError) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, { message: errorMessage })
      }, {})
    } finally {
      setUploadSuccess(false)
      setLoading(false)
    }
  }

  const onSubmitUpdate = async (data: IStaffs) => {
    const { email, full_name, phone, status, avatar, sex } = data
    setLoading(true)

    try {
      if (!isUndefined(avatar)) {
        await uploadAvatarStaff({ staffId: staffDetail?.id, avatar: avatar })
      }

      const paramsUpdate: IUpdateStaff = {
        full_name,
        email: email.toLowerCase(),
        phone,
        status,
        roles: data.role?.map((role) => ({ id: role })),
        avatar: null,
        sex,
      }

      if (!showAvatarDefault || !isUndefined(avatar)) {
        delete paramsUpdate.avatar
      }

      StaffAPI.update({
        id: staffDetail?.id,
        data: paramsUpdate,
      })
        .then(() => {
          toast.success('Update Successfully!')
          navigate(PageLink.STAFFS)
        })
        .catch((err) => {
          err?.response?.data?.error?.others?.forEach((e: IError) => {
            const errorMessage = e?.errors?.[0]?.message

            setError(e.property, { message: errorMessage })
          }, {})
        })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSubmit: SubmitHandler<IStaffs> = async (data) => {
    // if (Object.keys(errors).length === 0) {
    // Prevent form submission
    if (
      !isUndefined(data.avatar) &&
      (data?.avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE)
    )
      return

    if (id) {
      onSubmitUpdate(data)
      return
    }
    onSubmitCreate(data)
    // }
  }

  const optionRole = roles?.roles?.map((role: any) => ({ label: role?.name, value: role?.id }))

  const getStaffDetail = async () => {
    const res = await StaffAPI.detail(id)
    setStaffDetail(res?.data)
  }

  useEffect(() => {
    if (!id) return

    getStaffDetail()
  }, [id])

  useEffect(() => {
    if (!id) {
      reset()
      setUploadSuccess(true)
    } else {
      setValue('full_name', staffDetail?.detail?.full_name ?? '')
      setValue('username', staffDetail?.username ?? '')
      setValue('email', staffDetail?.detail?.email ?? '')
      setValue('phone', staffDetail?.detail?.phone ?? '')
      setValue('status', staffDetail?.status ?? '')
      setValue('role', staffDetail?.roles?.map((role) => role?.id) ?? [])
      setValue('sex', staffDetail?.detail?.sex ?? '')
    }
  }, [
    id,
    reset,
    setValue,
    staffDetail?.detail?.email,
    staffDetail?.detail?.full_name,
    staffDetail?.detail?.phone,
    staffDetail?.roles,
    staffDetail?.status,
    staffDetail?.username,
  ])

  const { confirm, contextHolder } = useConfirm()

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(PageLink.STAFFS),
    })
  }

  const handleSubmitForm = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn lưu không?'],
      onClick: handleSubmit(onSubmit),
    })
  }

  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)
  const allowRenderUpdateRole = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_STAFF_GR.GET_ASSIGN_PERMISSION_STAFF) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  return (
    <CreateEditLayout
      onSave={handleSubmitForm}
      onCancel={handleCancel}
      loading={loading}
      pageTitle={`${id ? 'Update Staff' : 'Create Staff'}`}
    >
      {contextHolder}
      <div className='form d-flex flex-column flex-lg-row'>
        <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
          <div className='card card-flush sapp-pt--image py-4'>
            <div className='card-header'>
              <div className='card-title'>
                <h2>Avatar</h2>
              </div>
            </div>

            <div className='card-body text-center pt-0'>
              <SAPPHookUploadFile
                name='avatar'
                control={control}
                setValue={setValue}
                setError={setError}
                imagePreview={
                  staffDetail?.detail?.avatar?.['150x150'] ??
                  staffDetail?.detail?.avatar?.ORIGIN ??
                  avatarDefault
                }
                accept={ACCEPT_UPLOAD_MIME_TYPE}
                maxFileSize={DEFAULT_MAX_FILE_SIZE}
                uploadSuccess={uploadSuccess}
                setShowAvatarDefault={setShowAvatarDefault}
                removeAvatar={avatarDefault}
              />
            </div>
          </div>

          {id && (
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>Status</h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <HookFormSelectAntd
                  size='large'
                  name='status'
                  control={control}
                  dropdownStyle={{ minWidth: 'fit-content' }}
                  placeholder='Status'
                  filterOption={true}
                  defaultValue={STATUS_FORM[0].value}
                  options={STATUS_FORM}
                />
              </div>
            </div>
          )}
        </div>

        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='tab-content'>
            <div className='tab-pane fade show active' id='kt_ecommerce_add_product_general'>
              <div className='d-flex flex-column gap-7 gap-lg-10'>
                <div className='card card-flush pb-4 pt-8'>
                  <div className='card-body pt-0'>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='full_name'
                        label={LANG_PLACEHOLDER.FULLNAME}
                        required
                        guideline={GUIDELINE_FULLNAME}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='username'
                        label='Username'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_USERNAME}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='email'
                        label='Email'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_EMAIL}
                      />
                    </div>
                    {!id && (
                      <div className={`mb-10 fv-row`}>
                        <HookFormTextField
                          control={control}
                          name='password'
                          label='Password'
                          type='password'
                          guideline={GUIDELINE_PASSWORD}
                        />
                      </div>
                    )}

                    {!id && (
                      <div className='mb-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='confirmPassword'
                          label='Confirm Password'
                          type='password'
                          guideline={GUIDELINE_PASSWORD}
                        />
                      </div>
                    )}

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='phone'
                        label='Phone Number'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_PHONE}
                      />
                    </div>
                    {allowRenderUpdateRole && (
                      <div>
                        <HookFormSelectMultiple
                          name='role'
                          control={control}
                          placeholder='Role'
                          options={optionRole}
                          label='Role'
                          defaultValue={[]}
                          loading={roleLoading}
                          onSearch={(e: any) => {
                            if (e === undefined) {
                              return
                            }
                            getRoles({ params: { name: e } })
                          }}
                          handleNextPage={(e: any) => handlNextPageRole({ params: { name: e } })}
                        />
                      </div>
                    )}

                    <div className={`fv-row mt-10`}>
                      <HookFormSelectAntd
                        size='large'
                        name='sex'
                        control={control}
                        dropdownStyle={{ minWidth: 'fit-content' }}
                        placeholder='Please select'
                        label='Gender'
                        defaultValue={GENDER_FORM?.[0]?.value}
                        options={GENDER_FORM}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CreateEditLayout>
  )
}

export default withAuthComponents(CreateAndUpdateStaff)
