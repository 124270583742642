import { Dispatch, ReactNode, SetStateAction } from 'react'
import { IStep } from 'src/pages/shop/faqs/FaqDetail'

export interface IButtonProps {
  title: string | undefined
  onClick?: any
  className?: string
  link?: string
  disabled?: boolean
  loading?: boolean
  type?: 'button' | 'submit' | 'reset'
  bg?: 'danger' | 'primary' | 'secondary'
  disable?: boolean
  classNameTitle?: string
  isListScreen?: boolean
  height?: string
}
export interface ParentsNode {
  nextParentNode: ParentsNode
  children?: ParentsNode[]
  code: string
  course_id: string
  course_learning_outcome?: {
    description: string
    id: string
    name: string
  }
  course_section_type: string
  created_at: string
  description: string
  display_icon: string
  expanded: boolean
  id: string
  is_linked_section: boolean
  is_linked_section_child: boolean
  is_original: boolean
  name: string
  parent_id: string
  parents: []
  position?: number
  position_updated_at: string
  quiz?: {
    id: string | null | undefined
    name: string | null | undefined
    quiz_timed: number | null | undefined
    quiz_type: string | null | undefined
  }
  treeIndex?: number
  total_tab?: number
}

export interface ITabs {
  link: string
  title: string
}

export enum EEntranceLevel {
  IIA = 'IIA',
  IA = 'IA',
  IB = 'IB',
  IIB = 'IIB',
}

export enum EErrorCourse {
  CODE = 'code',
  COURSE_LEVELS = 'course_levels',
  NAME = 'name',
  CONNECTIONS = 'course_connections',
}

export interface ICommonFormError {
  errors: Array<any>
}

export interface IErrorFormClass extends ICommonFormError {
  property: EClassForm.CODE
}

export interface IErrorFormArea extends ICommonFormError {
  property: EAreaForm.CODE | EAreaForm.NAME | EAreaForm.ADDRESS
}

export interface IError extends ICommonFormError {
  property:
    | EUserManagement.EMAIL
    | EUserManagement.PHONE
    | EUserManagement.USERNAME
    | EUserManagement.EMPLOYEE_CODE
}

export interface IErrorCourse extends ICommonFormError {
  property:
    | EErrorCourse.CODE
    | EErrorCourse.COURSE_LEVELS
    | EErrorCourse.NAME
    | EErrorCourse.CONNECTIONS
}

export enum EUserManagement {
  EMAIL = 'email',
  PHONE = 'phone',
  USERNAME = 'username',
  EMPLOYEE_CODE = 'employee_code',
}

export enum EClassForm {
  CODE = 'code',
}

export enum EAreaForm {
  NAME = 'name',
  CODE = 'code',
  ADDRESS = 'address',
  STATUS = 'status',
}

export interface IResponse<T> {
  error: any
  status: number
  success: boolean
  data: T
}

export interface IMeta {
  total_pages: number
  total_records: number
  page_index: number
  page_size: number
}
export interface IResponseMeta {
  success: boolean
  data: {
    meta: IMeta
  }
}

export interface IForgotPassword {
  actor_id: string
  actor_type: 'STAFF'
  code: string
  created_at: Date
  deleted_at: Date
  duration: number
  expired_at: Date
  id: string
  status: string
  type: string
  updated_at: Date
}

export interface IResizeAvatar {
  '40x40'?: string | null
  '50x50'?: string | null
  '150x150'?: string | null
  '160x160'?: string | null
  ORIGIN?: string | null
}

export interface IResizeIdentityCard {
  '280x180'?: string | null
  '320x190'?: string | null
  '370x220'?: string | null
  ORIGIN?: string | null
}

export enum EStatusUser {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
}
export interface Role {
  permissions: string[] | string
  code: string
}

export interface ArrayFieldForm {
  open: boolean
  type?: string
  isEdit?: boolean
}

export enum ImportType {
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
  STAFF = 'STAFF',
  CLASS = 'CLASS',
  CLASS_STUDENT = 'CLASS_STUDENT',
  TEST_PARTICIPANT = 'TEST_PARTICIPANT',
  CHECK_DUPLICATE_CONTACT = 'CHECK_DUPLICATE_CONTACT',
}

export interface ITable {
  headers:
    | { key?: string | undefined; label: any; className?: string; onClick?: () => void }[]
    | undefined
  dataResponse?:
    | {
        metadata: IMeta
        meta: IMeta
        [key: string]: any
      }
    | undefined
    | any
  children: ReactNode
  loading: boolean
  handlePaginationChange?: (page_index: number, page_size: number) => void
  onChange?: ((e: React.ChangeEvent<any>) => void | undefined) | undefined
  hasCheck?: boolean
  isCheckedAll?: boolean | undefined
  data?: Array<any> | undefined
  totalItems?: number | undefined
  className?: string
  classNameTable?: string | undefined
  showHashtag?: boolean
}
export interface IPropsCheckbox {
  id?: string
  ktCheck?: boolean
  checkTarget?: string
  checked: boolean | undefined
  onChange?: ((e: React.ChangeEvent<any>) => void) | ((e?: any) => void)
  className?: string
  disabled?: boolean
  name?: string
  value?: string | boolean
  state?: 'default' | 'error' | 'success' | 'primary'
  size?: 'small' | 'medium' | 'lager'
  lowerOptions?: boolean
}

export enum EDateTime {
  dateFormat = 'DD/MM/YYYY',
  weekFormat = 'MM/DD',
  monthFormat = 'MM/YYYY',
  fullDate = 'DD/MM/YYYY HH:mm',
  backendFormat = 'yyyy-MM-dd',
}

export interface IStepProgress {
  setStep: Dispatch<SetStateAction<IStep[]>>
  step: IStep[]
}

export * from './exam'
export * from './classroom'
export * from './event-test'
export * from './major'
export * from './university'
export * from './department'
export * from './common'
export * from './area'
