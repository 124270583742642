import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { QuestionBankAPI } from 'src/apis/question-bank'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ListFilterLayout from 'src/components/layout/listFilter'
import PageLayouts from 'src/components/layout/PageLayouts'
import ChooseQuestionType from 'src/components/question-bank/choose-question-type/ChooseQuestionType'
import QuestionListTable from 'src/components/question-bank/question/QuestionListTable'
import { IQuestionTagList } from 'src/components/question-bank/shared/interfaces'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import ListGrouping from 'src/components/user-management/UserListGrouping'
import { FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_TOPIC_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import useChecked from 'src/hooks/use-checked'
import useCourseFilter, { ISearchCourse } from 'src/hooks/use-course-filter'
import { ITabs, Role } from 'src/type'
import { ICourse } from 'src/type/courses'
import { ITopicList, ITopicObject } from 'src/type/question-bank'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: ``,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.questionList,
  },
]

const fieldNames = [
  'course_id',
  'question_content',
  'part',
  'chapter',
  'unit',
  'activity',
  'topic_id',
  'tag_id',
  'sortType',
  'fromDate',
  'toDate',
]
const initialValues: any = {
  course_id: '',
  question_content: '',
  part: '',
  chapter: '',
  unit: '',
  activity: '',
  topic_id: '',
  tag_id: '',
  sortType: '',
  fromDate: '',
  toDate: '',
}

interface ISearchForm {
  page_index?: number
  page_size?: number
  search_key?: string
  course_id?: string
  course_section_id?: string
  part?: string
  chapter?: string
  unit?: string
  activity?: string
  topic_id?: string
  tag_id?: string
  sortType?: string
  fromDate?: Date | string | null
  toDate?: Date | string | null
  suffix_types?: string
}

const QuestionList = () => {
  const defaultValueMeta = {
    page_index: 0,
    page_size: 0,
    total_pages: 0,
    total_records: 0,
  }
  const [questionList, setQuestionList] = useState<any>()
  const location = useLocation()
  const queryParamss = new URLSearchParams(location.search)
  const page_index = parseInt(queryParamss.get('page_index') as string)
  const [loading, setLoading] = useState(true)
  const [dateField, setDateField] = useState<string>('created_at')
  const page_size = parseInt(queryParamss.get('page_size') as any)
  const searchParams = new URLSearchParams(location.search)
  const [openChooseQuestionTypeModal, setOpenChooseQuestionTypeModal] = useState<boolean>(false)
  const [loadingCourse, setLoadingCourse] = useState<boolean>(false)
  const [listItemSet, setListItemSet] = useState<ITopicList>({
    topics: [],
    meta: defaultValueMeta,
  })
  const [questionTags, setQuestionTags] = useState<IQuestionTagList>({
    question_tags: [],
    metadata: defaultValueMeta,
  })

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<
    typeof initialValues
  >(questionList?.questions)

  const validationSchema = z.object({
    question_content: z.string().optional(),
    course_id: z.string().optional(),
    part: z.string().optional(),
    chapter: z.string().optional(),
    unit: z.string().optional(),
    activity: z.string().optional(),
    topic_id: z.string().optional(),
    tag_id: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  // Using validate for input
  const { control, handleSubmit, getValues, reset, setValue } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const {
    listCourse,
    setListCourse,
    getListSort,
    debouncedGetListSort,
    clearChildren,
    getCourses,
    debouncedGetCourses,
    handleNextPage,
    handleNextListSort,
  } = useCourseFilter(setValue)

  const fetchQuestions = async (page_index: number, page_size: number) => {
    try {
      const params = handleParseParam()
      const questions = await QuestionBankAPI.getQuestionList(page_index, page_size, params)
      setQuestionList(questions?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const handleResetFilter = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })

    setLoading(true)
    navigate(`${PageLink.QUESTION_LIST}`)
    setDateField('')
    clearChildren('course')
    toggleCheckAll(false)
    fetchQuestions(1, 10)
  }

  const navigate = useNavigate()

  const getParams = (
    question_content: string,
    course_id: string,
    part_id: string,
    chapter_id: string,
    unit_id: string,
    activity_id: string,
    topic_id: string,
    tag_id: string,
    sortType: string,
    fromDate?: any,
    toDate?: any
  ) => ({
    question_content,
    course_id,
    part_id,
    chapter_id,
    unit_id,
    activity_id,
    topic_id,
    tag_id,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
  })

  const queryParams = {
    question_content: searchParams.get('question_content') ?? '',
    course_id: searchParams.get('course_id') ?? '',
    part_id: searchParams.get('part_id') ?? '',
    chapter_id: searchParams.get('chapter_id') ?? '',
    unit_id: searchParams.get('unit_id') ?? '',
    activity_id: searchParams.get('activity_id') ?? '',
    sortType: searchParams.get('sortType') ?? '',
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    topic_id: searchParams.get('topic_id') ?? '',
    tag_id: searchParams.get('tag_id') ?? '',
  }

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParams = {
      page_index: currenPage,
      page_size: size,
      question_content: getValues('question_content'),
      course_id: replaceValueAll(getValues('course_id')) || '',
      part_id: replaceValueAll(getValues('part')) || '',
      chapter_id: replaceValueAll(getValues('chapter')) || '',
      unit_id: replaceValueAll(getValues('unit')) || '',
      activity_id: replaceValueAll(getValues('activity')) || '',
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
      topic_id: replaceValueAll(getValues('topic_id')) || '',
      tag_id: replaceValueAll(getValues('tag_id')) || '',
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    const prepareJson = Object.entries(listCourse || {}).reduce((previousValue, [key, value]) => {
      previousValue[key as keyof ISearchCourse] = {
        ...value,
        data: value.data.map((e: Partial<ICourse>) => ({
          id: e.id,
          name: e.name,
        })),
      }
      return previousValue
    }, {} as ISearchCourse)
    try {
      localStorage.setItem('storedQuestionList', JSON.stringify(prepareJson))
    } catch (error) {}

    navigate(`?${queryString}`)
  }

  const handleParseParam = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const nameValue = getValues('question_content')
    const trimmedName = nameValue ? nameValue.trimStart().trimEnd() : ''
    const courses = getValues('course_id') || ''
    const sortType = getValues('sortType') || ''
    const fromDateValue = getDateInfo(getValues('fromDate') as unknown as Date) || ''
    const toDateValue = getDateInfo(getValues('toDate') as unknown as Date) || ''
    const formattedFromDate = !isNaN(fromDateValue?.day)
      ? formatISOFromDate(fromDateValue.year, fromDateValue.month, fromDateValue.day)
      : ''
    const formattedToDate = !isNaN(toDateValue?.day)
      ? formatISOToDate(toDateValue.year, toDateValue.month, toDateValue.day)
      : ''
    const partValue = getValues('part')
    const chapterValue = getValues('chapter')
    const unitValue = getValues('unit')
    const activityValue = getValues('activity')
    let courseSectionIdValue = ''

    if (activityValue) {
      courseSectionIdValue = activityValue
    } else if (unitValue) {
      courseSectionIdValue = unitValue
    } else if (chapterValue) {
      courseSectionIdValue = chapterValue
    } else if (partValue) {
      courseSectionIdValue = partValue
    }
    const requestData = {
      question_content: trimmedName,
      course_id: courses,
      course_section_id: courseSectionIdValue,
      sortType: sortType,
      fromDate: getValues('fromDate') ? formattedFromDate : '',
      toDate: getValues('toDate') ? formattedToDate : '',
      topic_id: getValues('topic_id') ?? null,
      tag_id: getValues('tag_id') ?? null,
    }

    const validFields = Object.entries(requestData).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        acc[key as keyof ISearchForm] = value
      }
      return acc
    }, {} as ISearchForm)
    return validFields
  }

  useEffect(() => {
    getListItemSet(1, 50)
    getListQuestionTags(1, 50)
  }, [])

  //  Handle Filter by Item Set
  const debounceSearchItemSet = debounce((e) => {
    getListItemSet(1, 10, { text: e })
  })

  const getListItemSet = async (page_index?: number, page_size?: number, param?: Object) => {
    try {
      const response = await QuestionBankAPI.getTopic({
        page_index: page_index ?? 1,
        page_size: page_size ?? 20,
        params: param,
      })
      setListItemSet((prev: ITopicList) => {
        return {
          meta: response.data.meta,
          topics: [...prev.topics, ...response.data.topics].filter(
            (item, index, self) => index === self.findIndex((t) => t.id === item.id)
          ),
        }
      })
    } catch (error) {}
  }

  const handleNextPageItemSet = (params: Object) => {
    const total_pages = listItemSet?.meta?.total_pages
    const page_index = listItemSet?.meta?.page_index
    const page_size = listItemSet?.meta?.page_size
    if (total_pages) {
      if (page_index < total_pages) {
        if (!loadingCourse) {
          getListItemSet(page_index + 1, page_size, params)
        }
      }
    }
  }
  // End Handle Filter By Topic

  // Start Handle Filter By Tag
  const getListQuestionTags = async (page_index?: number, page_size?: number, param?: Object) => {
    try {
      const response = await QuestionBankAPI.getQuestionTags({
        page_index: page_index ?? 1,
        page_size: page_size ?? 10,
        params: param,
      })

      setQuestionTags((prev: IQuestionTagList) => {
        return {
          metadata: response.data.metadata,
          question_tags: [...prev.question_tags, ...response.data.question_tags].filter(
            (item, index, self) => index === self.findIndex((t) => t.id === item.id)
          ),
        }
      })
    } catch (error) {}
  }

  const debounceSearchTag = debounce((e) => {
    getListQuestionTags(1, 10, { name: e })
  })

  const handleNextPageITag = (params: Object) => {
    const total_pages = questionTags?.metadata?.total_pages
    const page_index = questionTags?.metadata?.page_index
    const page_size = questionTags?.metadata?.page_size
    if (total_pages) {
      if (page_index < total_pages) {
        if (!loadingCourse) {
          getListQuestionTags(page_index + 1, page_size, params)
        }
      }
    }
  }
  // End Handle Filter By Tag

  const onSubmit = () => {
    handleChangeParams(page_index || 1, page_size || 10)
    setLoading(true)
    fetchQuestions(1, 10)
  }

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)

  const cleanedParams = cleanParamsAPI(
    getParams(
      queryParams.question_content ?? '',
      replaceValueAll(queryParams.course_id),
      replaceValueAll(queryParams.part_id),
      replaceValueAll(queryParams.chapter_id),
      replaceValueAll(queryParams.unit_id),
      replaceValueAll(queryParams.activity_id),
      replaceValueAll(queryParams.topic_id),
      replaceValueAll(queryParams.tag_id),
      replaceValueAll(queryParams.sortType),
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : ''
    )
  )

  const { profileMe } = useUserContext()
  const allowRenderCreateTopic: boolean =
    profileMe?.roles?.some(
      (role: Role) =>
        role.permissions?.includes(TITLE_TOPIC_GR.CREATE_QUESTION_TOPIC) ||
        role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false
  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.questionList} breadcrumbs={breadcrumbs}>
      <ChooseQuestionType
        isSingleQuestion={true}
        open={openChooseQuestionTypeModal}
        setOpen={setOpenChooseQuestionTypeModal}
      />
      <div className='card'>
        <div className='px-10 border-0 pt-10'>
          <ListFilterLayout>
            <HookFormTextField
              control={control}
              name='question_content'
              placeholder='Search'
              defaultValue={queryParams.question_content ?? ''}
              onSubmit={onSubmit}
              isListScreen
            />

            <HookFormSelectAntd
              name='course_id'
              placeholder='Course'
              control={control}
              showSearch
              handleNextPage={handleNextPage}
              onSearch={async (e) => {
                debouncedGetCourses(e)
                return
              }}
              onFocus={async () => {
                if (listCourse?.course?.data.length <= 0) {
                  await getCourses()
                  return
                }
              }}
              onChange={(e: any) => {
                if (e === undefined) {
                  return
                }
                getListSort({ parentId: e, type: 'part', parentType: 'course', isSelect: true })
              }}
              loading={listCourse.course.loading}
              allowClear
              onClear={() => {
                setListCourse(clearChildren('course'))
              }}
              classNameHeight='sapp-h-40'
              options={listCourse.course.data?.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />

            <HookFormSelectAntd
              name='part'
              placeholder='Section'
              control={control}
              showSearch
              loading={listCourse.part.loading}
              onSearch={async (e) => {
                debouncedGetListSort({
                  type: 'part',
                  name: e,
                  parentId: listCourse.course.id,
                  parentType: 'course',
                })
                return
              }}
              onChange={(e: any) => {
                if (e === undefined) {
                  return
                }
                getListSort({
                  parentId: e,
                  type: 'chapter',
                  parentType: 'part',
                  isSelect: true,
                })
              }}
              handleNextPage={() => handleNextListSort('part', 'course')}
              allowClear
              onClear={() => {
                setListCourse(clearChildren('part'))
              }}
              classNameHeight='sapp-h-40'
              options={listCourse.part.data?.map((item) => ({ value: item.id, label: item.name }))}
            />

            <HookFormSelectAntd
              name='chapter'
              placeholder='Subsection'
              control={control}
              classNameHeight='sapp-h-40'
              showSearch
              loading={listCourse.chapter.loading}
              onSearch={async (e) => {
                debouncedGetListSort({
                  type: 'chapter',
                  name: e,
                  parentId: listCourse.part.id,
                  parentType: 'part',
                })
                return
              }}
              onChange={(e: any) => {
                if (e === undefined) {
                  return
                }
                getListSort({
                  parentId: e,
                  type: 'unit',
                  parentType: 'chapter',
                  isSelect: true,
                })
              }}
              allowClear
              onClear={() => {
                setListCourse(clearChildren('chapter'))
              }}
              handleNextPage={() => handleNextListSort('chapter', 'part')}
              options={listCourse.chapter.data?.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />

            <HookFormSelectAntd
              name='unit'
              placeholder='Unit'
              control={control}
              showSearch
              loading={listCourse.unit.loading}
              onSearch={async (e) => {
                debouncedGetListSort({
                  type: 'activity',
                  name: e,
                  parentId: listCourse.chapter.id,
                  parentType: 'unit',
                })
                return
              }}
              onChange={(e: any) => {
                if (e === undefined) {
                  return
                }
                getListSort({
                  parentId: e,
                  type: 'activity',
                  parentType: 'unit',
                  isSelect: true,
                })
              }}
              allowClear
              onClear={() => {
                setListCourse(clearChildren('unit'))
              }}
              handleNextPage={() => handleNextListSort('unit', 'chapter')}
              classNameHeight='sapp-h-40'
              options={listCourse.unit.data?.map((item) => ({ value: item.id, label: item.name }))}
            />

            <HookFormSelectAntd
              name='activity'
              placeholder='Activity'
              control={control}
              showSearch
              loading={listCourse.activity.loading}
              onChange={(e: any) => {
                setListCourse((c) => ({ ...c, activity: { ...c.activity, id: e } }))
              }}
              onSearch={async (e) => {
                debouncedGetListSort({
                  type: 'activity',
                  name: e,
                  parentId: listCourse.unit.id,
                  parentType: 'unit',
                })
                return
              }}
              allowClear
              handleNextPage={() => handleNextListSort('activity', 'unit')}
              classNameHeight='sapp-h-40'
              options={listCourse.activity.data?.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />

            <HookFormSelectAntd
              control={control}
              name='topic_id'
              placeholder='Item Set'
              defaultValue={queryParams.topic_id ?? ''}
              showSearch
              classNameHeight='sapp-h-40'
              handleNextPage={debounce(
                (e: string) => handleNextPageItemSet({ params: { text: e } }),
                500
              )}
              allowClear
              onFocus={async () => {
                if (listItemSet?.topics?.length <= 0) {
                  await getListItemSet()
                  return
                }
              }}
              onClear={() => {
                setValue('topic_idt', null)
              }}
              onSearch={async (e) => {
                debounceSearchItemSet(e)
                return
              }}
              loading={loadingCourse}
              options={listItemSet?.topics?.map((item) => ({ value: item.id, label: item.name }))}
            />

            <HookFormSelectAntd
              control={control}
              name='tag_id'
              placeholder='Tag'
              classNameHeight='sapp-h-40'
              defaultValue={queryParams.tag_id ?? ''}
              showSearch
              handleNextPage={debounce(
                (e: string) => handleNextPageITag({ params: { name: e } }),
                500
              )}
              allowClear
              onFocus={async () => {
                if (questionTags?.question_tags?.length <= 0) {
                  await getListQuestionTags()
                  return
                }
              }}
              onClear={() => {
                setValue('tag', null)
              }}
              onSearch={async (e) => {
                debounceSearchTag(e)
                return
              }}
              loading={loadingCourse}
              options={questionTags.question_tags?.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />

            <HookFormSelectAntd
              name='sortType'
              placeholder='Sort By'
              control={control}
              defaultValue={queryParams.sortType ?? ''}
              classNameHeight='sapp-h-40'
              options={FILTER_SELECTALL_SORTBY}
            />

            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams.fromDate ?? ''}
              isListScreen
            />

            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams.toDate ?? ''}
              isListScreen
            />
          </ListFilterLayout>
        </div>
        <div className='card-header border-0 pt-6'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={handleSubmit(onSubmit)}
              resetClick={handleResetFilter}
              disabled={loading}
              loading={loading}
            />
          </div>
          <>
            {checkedList.length > 0 ? (
              <div className='col-xl-8 col-lg-9 col-sm-8 px-xl-3 pe-xl-0'>
                <ListGrouping
                  selected={checkedList}
                  blockUser={() => {}}
                  openBlocked={false}
                  setOpenBlocked={() => {}}
                  title='Delete'
                />
              </div>
            ) : (
              <div className='col-sm-8 col-xl-8 col-lg-4 px-xl-3 px-md-0 px-0 pe-xl-0 d-flex justify-content-end gap-4 flex-wrap'>
                <div className='justify-content-end d-flex my-0'>
                  {allowRenderCreateTopic && (
                    <ButtonIconPrimary
                      iconName='plus'
                      title={LANG_SIDEBAR.addSingleQuestion}
                      onClick={() => setOpenChooseQuestionTypeModal(true)}
                      size='small'
                    />
                  )}
                </div>
                <div className='justify-content-end d-flex my-0'>
                  {allowRenderCreateTopic && (
                    <ButtonIconPrimary
                      iconName='plus'
                      title={LANG_SIDEBAR.addItemSet}
                      onClick={() => navigate(`${PageLink.TOPIC + (location.search || '')}`)}
                      size='small'
                    />
                  )}
                </div>
              </div>
            )}
          </>
        </div>
        <QuestionListTable
          questionList={questionList}
          setQuestionList={setQuestionList}
          cleanedParams={cleanedParams}
          loading={loading}
          setLoading={setLoading}
          getParams={getParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
          fetchQuestions={fetchQuestions}
          queryParam={queryParams}
        />
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(QuestionList)
