import React, { useState } from 'react'
import HookFormSelectAntd from '../HookFormSelectAntd'
import useInfiniteFacilities from 'src/hooks/useInfiniteFacilities'
import { getSelectOptions } from 'src/helper/getSelectOptions'

interface FacilitySelectProps {
  control: any
  name: string
  placeholder?: string
  defaultValue?: string
  classNameHeight?: 'sapp-h-45px' | 'sapp-h-35px' | 'sapp-h-40'
  allowClear?: boolean
  required?: boolean
  disabled?: boolean
  label?: string
  validateRequired?: boolean
  classDetail?: { value: string; label: string }
}

const FacilitySelect: React.FC<FacilitySelectProps> = ({
  control,
  name,
  placeholder = '',
  defaultValue = '',
  classNameHeight = 'sapp-h-40',
  allowClear = false,
  required = false,
  disabled = false,
  label,
  validateRequired = false,
  classDetail,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const {
    facilities,
    hasNextPage,
    fetchNextPage,
    isLoading: isFacilitiesLoading,
    refetch: refetchFacilities,
    debounceSearch: searchFacilities,
  } = useInfiniteFacilities(dropdownVisible)

  const baseOptions =
    facilities?.map((facility) => ({
      label: facility.name,
      value: facility.id,
    })) ?? []

  const options = classDetail ? getSelectOptions(baseOptions, classDetail) : baseOptions

  return (
    <HookFormSelectAntd
      control={control}
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      classNameHeight={classNameHeight}
      allowClear={allowClear}
      required={validateRequired || required}
      disabled={disabled}
      label={label}
      filterOption={false}
      loading={isFacilitiesLoading}
      showSearch
      onSearch={searchFacilities}
      onDropdownVisibleChange={(open) => {
        setDropdownVisible(open)
        if (open && !facilities?.length) {
          refetchFacilities()
        }
      }}
      handleNextPage={() => {
        hasNextPage && fetchNextPage()
      }}
      options={options}
    />
  )
}

export default FacilitySelect
