const ArrowRight: React.FC<React.HTMLAttributes<HTMLSpanElement>> = (props) => {
  return (
    <span {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' width='13' height='8' fill='none'>
        <path
          fill='#78829D'
          transform='rotate(-90 6.5 4)'
          d='M6.404 7.085a.875.875 0 0 1-.622-.254L.532 1.58A.875.875 0 1 1 1.766.338l4.638 4.638L11.032.338a.875.875 0 1 1 1.234 1.243l-5.25 5.25a.875.875 0 0 1-.612.254Z'
        />
      </svg>
    </span>
  )
}

export default ArrowRight
