import React, { ReactNode } from 'react'
import SappFilterButton from './SAPPFIlterButton'
import { KTCardBody } from 'src/_metronic/helpers'

interface IProps {
  layoutFilter: ReactNode
  onSubmit: () => void
  onReset: () => void
  loading: boolean
  layoutAction: ReactNode
  layoutTable: ReactNode
}

const LayoutTable = ({
  layoutFilter,
  loading,
  onReset,
  onSubmit,
  layoutAction,
  layoutTable,
}: IProps) => {
  return (
    <div className='card'>
      {layoutFilter}
      <div className='card-header border-0 pt-5'>
        <div className='d-flex'>
          <SappFilterButton
            titleReset='Reset'
            titleSubmit='Search'
            okClick={onSubmit}
            resetClick={onReset}
            disabled={loading}
            loading={loading}
          />
        </div>
        {layoutAction}
      </div>
      <KTCardBody>{layoutTable}</KTCardBody>
    </div>
  )
}

export default LayoutTable
