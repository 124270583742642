import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { ClassesApi } from 'src/apis/classes'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HeaderTab from 'src/components/base/HeaderTab'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import SappDrawer from 'src/components/base/SappDrawer'
import FacilitySelect from 'src/components/base/select/common/FacilitySelect'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import AddCourseToClass from 'src/components/classes/add-course/AddCourseToClass'
import AddExam from 'src/components/classes/add-exam/AddExam'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import { VALIDATE_FIELD_MAX_LENGTH, VALIDATION_FIELD } from 'src/constants'
import {
  CLASS_PROFILE,
  OPTIONS_OF_CONTRUCTION_MODE,
  OPTIONS_OF_DURATION,
  OPTIONS_OF_STATUS,
  OPTIONS_OF_TYPE_CLASS,
  TITLE_OPTIONS_CLASS,
} from 'src/constants/classes'
import { TITLE_OPTIONS_AREA } from 'src/constants/classroom'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_CLASS_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { getSelectOptions } from 'src/helper/getSelectOptions'
import { useConfirm } from 'src/hooks/use-confirm'
import useInfiniteFacilities from 'src/hooks/useInfiniteFacilities'
import { Role } from 'src/type'
import { IClass } from 'src/type/classes'
import { IClassType } from 'src/type/courses'
import { z } from 'zod'

interface IProps {
  classDetail: any | undefined
}

const EditClassProfile = ({ classDetail }: IProps) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const [areaClasses, setAreaClasses] = useState<any[]>([])
  const [selectedRadio, setSelectedRadio] = useState<string>(classDetail?.duration_type)
  const [checkedListCourse, setCheckedListCourse] = useState<any>([classDetail?.course])
  const [openAddCourse, setOpenAddCourse] = useState<boolean>(false)
  const [typeClass, setTypeClass] = useState<string>('')
  const [openAddExam, setOpenAddExam] = useState<boolean>(false)
  const [checkedListExam, setCheckedListExam] = useState<any>([])
  const [openBlock, setOpenBlock] = useState<boolean>(false)
  const [statusBlock, setStatusBlock] = useState<any>(classDetail?.status)
  const isCheckEnded = classDetail?.status === 'ENDED' ? true : false
  const is_student_studying_normal_course = classDetail?.is_student_studying_normal_course
  const is_student_studying_foundation_course = classDetail?.is_student_studying_foundation_course
  const [typeInstruction, setTypeInstruction] = useState(classDetail?.instruction_mode)
  const [warning, setWarning] = useState<{ course: string; duration: string }>({
    course: '',
    duration: '',
  })

  const getCourseCategoryId = () =>
    classDetail?.course?.course_categories?.[0]?.id ||
    classDetail?.examination_subject?.examination?.course_category_id ||
    null
  const [courseCategoryId, setCourseCategoryId] = useState<string>(getCourseCategoryId())

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`${CLASS_PROFILE}/${id}/overview`),
    })
  }

  const schema = {
    name: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, VALIDATION_FIELD)
      .max(1000, VALIDATE_FIELD_MAX_LENGTH('Name', 1000)),
    code: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, VALIDATION_FIELD)
      .max(1000, VALIDATE_FIELD_MAX_LENGTH('Code', 1000)),
    status: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    instruction_mode: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, VALIDATION_FIELD),
    type: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    capacity: z.coerce.number().min(1, VALIDATION_FIELD),
    duration_type: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    ...(statusBlock === 'BLOCKED' && {
      reason: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    }),
    ...(selectedRadio === 'FIXED' && {
      duration_at: z
        .object({
          fromDate: z.date(),
          toDate: z.date(),
        })
        .optional()
        .refine((data) => data?.fromDate && data?.toDate, VALIDATION_FIELD),
    }),
    ...(selectedRadio === 'FLEXIBLE' && {
      flexible_days: z.coerce.number().min(1, VALIDATION_FIELD),
    }),
  }

  const schemaOnline = z.object(
    Object.assign({}, schema, {
      facility_id: z.any(),
    })
  )

  const schemaOffline = z.object(
    Object.assign({}, schema, {
      facility_id: z
        .string({ required_error: VALIDATION_FIELD })
        .min(1, { message: VALIDATION_FIELD }),
    })
  )

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { isLoading },
  } = useForm<any>({
    resolver: zodResolver(typeInstruction !== 'ONLINE' ? schemaOffline : schemaOnline),
    mode: 'onSubmit',
  })

  // function scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  // submit form
  const onSubmit = async (data: IClass) => {
    setOpenBlock(false)

    const paramsUpdate: IClass = {
      id: id,
      name: data.name,
      code: data.code,
      status: data.status,
      facility_id: data.facility_id || null,
      instruction_mode: data.instruction_mode,
      type: data.type,
      capacity: data.capacity,
      duration_type: data.duration_type,
      excepted_course_section: [],
      description: getValues('description'),
      reason: data?.reason,
      course_id: null,
    }

    if (data.duration_type === 'FLEXIBLE' && data.flexible_days) {
      const getThisDay = new Date()
      const startAt = getThisDay.toISOString()
      const openAt = getThisDay.toISOString()
      const finishAt = new Date(
        getThisDay.setDate(getThisDay.getDate() + data.flexible_days)
      ).toISOString()
      const flexibleDays = {
        flexible_days: data.flexible_days,
        started_at: startAt,
        opening_at: openAt,
        finished_at: finishAt,
      }
      Object.assign(paramsUpdate, flexibleDays)
    } else {
      const objectDateFixed = {
        opening_at: getValues('opening_at'),
        started_at: data?.duration_at.fromDate,
        finished_at: data?.duration_at.toDate,
        flexible_days: 1,
      }
      Object.assign(paramsUpdate, objectDateFixed)
    }

    if (checkedListCourse[0]?.id !== undefined) {
      Object.assign(paramsUpdate, { course_id: checkedListCourse[0]?.id })
    }

    let objectListExam = {}
    if (checkedListExam[0]) {
      objectListExam = {
        examination_subject_id: `${checkedListExam[0]?.id}`,
      }
    } else {
      objectListExam = {
        examination_subject_id: null,
      }
    }
    Object.assign(paramsUpdate, objectListExam)
    if (
      classDetail?.course_id !== paramsUpdate.course_id &&
      classDetail?.class_lectures?.length > 0
    ) {
      paramsUpdate.lectures = []
    }

    updateClass(paramsUpdate)
  }

  const queryClient = useQueryClient()

  const [loadingClass, setLoadingClass] = useState(false)

  const updateClass = async (paramsUpdate: IClass) => {
    setLoadingClass(true)
    try {
      const res = await ClassesApi.updateClass({ ...paramsUpdate })
      toast.success('Update Successfully!')
      navigate(`/classes/detail/${res?.data?.id}/overview`)
      scrollToTop()

      queryClient.invalidateQueries('classDetail')
    } catch (error) {
      // do nothing
    } finally {
      setLoadingClass(false)
    }
  }

  const handleChangeDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(e.currentTarget.value)
  }

  const handleChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatusBlock(e)
  }

  const handleCloseBlock = () => {
    setOpenBlock(false)
  }

  useEffect(() => {
    if (id && classDetail !== undefined) {
      setValue('name', classDetail?.name)
      setValue('code', classDetail.code)
      setValue('status', classDetail.status)
      setValue('facility_id', classDetail?.facility_id || '')
      setValue('lecture_id', classDetail?.lecture?.detail?.full_name)
      setValue('instruction_mode', classDetail?.instruction_mode)
      setValue('type', classDetail?.type)
      setValue('capacity', classDetail?.capacity)
      setValue('course_id', classDetail?.course_id)
      setValue('mentor_id', classDetail?.mentor?.detail?.full_name)
      setValue('mentor_type', classDetail?.mentor_type)
      setValue('duration_type', classDetail?.duration_type)
      setValue('excepted_course_section', [])
      setValue('description', classDetail?.description)
      setValue('reason', classDetail?.reason)
      setValue('opening_at', classDetail?.opening_at)
      setValue('duration_at', {
        fromDate: classDetail?.started_at ? dayjs(classDetail?.started_at)?.toDate() : null,
        toDate: classDetail?.finished_at ? dayjs(classDetail.finished_at)?.toDate() : null,
      })
    }
  }, [id, setValue, classDetail])
  const [prevValue, setPrevValue] = useState<string>()
  const handleConfirm = (prevValues: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Course và Exam sẽ bị xóa đi khi bạn đổi Type class, bạn có muốn tiếp tục không?'],
      onClick: () => handleChangeType(prevValues),
      onClose: () => setValue('type', prevValue || classDetail?.type),
    })
  }
  const handleSelectChange = (prevValues: string) => {
    handleConfirm(prevValues)
  }

  const handleChangeType = (prevValues?: string) => {
    setPrevValue(prevValues)
    setCheckedListExam([])
    setCourseCategoryId('')
    setCheckedListCourse([])
  }

  const handleResetListExam = () => {
    if (
      !(classDetail?.type === IClassType.REVISION && classDetail.examination_subject_id !== null)
    ) {
      setCheckedListExam([])
    }
  }

  useEffect(() => {
    if (classDetail.examination_subject) {
      setCheckedListExam(() => [classDetail.examination_subject])
    }
  }, [])

  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)
  const allowRenderEditRole = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_CLASS_GR.EDIT_CLASS) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  useEffect(() => {
    const durationType = getValues('duration_type')
    const durationTime = getValues('duration_at')
    const flexibleDay = getValues('flexible_days')
    if (
      durationType !== classDetail.duration_type ||
      (flexibleDay && classDetail.flexible_days && flexibleDay !== classDetail.flexible_days) ||
      (durationTime?.fromDate &&
        classDetail.started_at &&
        durationTime?.fromDate.getTime() !== new Date(classDetail.started_at).getTime()) ||
      (durationTime?.toDate &&
        classDetail.finished_at &&
        durationTime?.toDate.getTime() !== new Date(classDetail.finished_at).getTime())
    ) {
      setWarning((prev) => {
        return {
          ...prev,
          duration: 'This action might change the access time of Test/Quiz',
        }
      })
    } else {
      setWarning((prev) => {
        return {
          ...prev,
          duration: '',
        }
      })
    }
  }, [watch('duration_type'), watch('duration_at'), watch('flexible_days')])

  useEffect(() => {
    if (
      classDetail?.course_id &&
      checkedListCourse[0]?.id &&
      classDetail?.course_id !== checkedListCourse[0]?.id
    ) {
      setWarning((prev) => {
        return {
          ...prev,
          course: 'This action will reset the teacher assignment settings.',
        }
      })
    } else {
      setWarning((prev) => {
        return {
          ...prev,
          course: '',
        }
      })
    }
  }, [checkedListCourse])

  return (
    <>
      {contextHolder}
      {classDetail && (
        <div className='card mb-5 mb-xl-10'>
          <HeaderTab title='Edit Class Information' />
          <div className='collapse show'>
            <div className='card-body card-body px-10 pt-8 pb-4'>
              {/* NAME */}
              <div className='row mb-8'>
                <SappLabel
                  label={TITLE_OPTIONS_CLASS.name}
                  className='col-lg-3 d-flex align-items-center'
                />
                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    required
                    control={control}
                    name='name'
                    defaultValue={classDetail?.name}
                    disabled={isCheckEnded}
                  />
                </div>
              </div>

              {/* CODE */}
              <div className='row mb-8'>
                <SappLabel
                  label={TITLE_OPTIONS_CLASS.code}
                  className='col-lg-3 d-flex align-items-center'
                />
                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    required
                    control={control}
                    name='code'
                    defaultValue={classDetail.code}
                    disabled={isCheckEnded}
                  />
                </div>
              </div>

              {/* STATUS */}
              <div className='row mb-8'>
                <SappLabel
                  label={TITLE_OPTIONS_CLASS.status}
                  className='col-lg-3 d-flex align-items-center'
                />
                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    required
                    name='status'
                    control={control}
                    filterOption={true}
                    defaultValue={classDetail.status}
                    onSelect={handleChangeStatus}
                    disabled={isCheckEnded || is_student_studying_normal_course}
                    options={OPTIONS_OF_STATUS}
                  />
                </div>
              </div>

              {/* CONSTRUCTION MODE */}
              <div className='row mb-8'>
                <SappLabel
                  label={TITLE_OPTIONS_CLASS.constructionMode}
                  className='col-lg-3 d-flex align-items-center'
                />
                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    required
                    name='instruction_mode'
                    control={control}
                    filterOption={true}
                    defaultValue={classDetail?.instruction_mode || ''}
                    disabled={isCheckEnded || is_student_studying_normal_course}
                    onChange={(e: unknown) => setTypeInstruction(e as unknown)}
                    options={OPTIONS_OF_CONTRUCTION_MODE}
                  />
                </div>
              </div>

              {/* FACILITY */}
              <div className='row mb-8'>
                <div className='col-lg-9 fv-row'>
                  <FacilitySelect
                    control={control}
                    name='facility_id'
                    placeholder='Facility'
                    label={TITLE_OPTIONS_AREA.facility}
                    disabled={isCheckEnded}
                    classDetail={{
                      label: classDetail?.facility?.name,
                      value: classDetail?.facility?.id,
                    }}
                  />
                </div>
              </div>

              {/* TYPE */}
              <div className='row mb-8'>
                <SappLabel
                  label={TITLE_OPTIONS_CLASS.type}
                  className='col-lg-3 d-flex align-items-center'
                />
                <div className='col-lg-9 fv-row'>
                  <HookFormSelectAntd
                    required
                    name='type'
                    control={control}
                    placeholder=' '
                    filterOption={true}
                    defaultValue={classDetail?.type}
                    onChange={(prevValues: any) => handleSelectChange(prevValues)}
                    disabled={
                      isCheckEnded ||
                      is_student_studying_normal_course ||
                      is_student_studying_foundation_course ||
                      classDetail?.type === 'FOUNDATION' ||
                      (classDetail?.type === IClassType.REVISION &&
                        classDetail.examination_subject_id !== null)
                    }
                    options={OPTIONS_OF_TYPE_CLASS}
                  />
                </div>
              </div>

              {/* Số học viên tối đa */}
              <div className='row mb-8'>
                <SappLabel
                  label={TITLE_OPTIONS_CLASS.maximumStudents}
                  className='col-lg-3 d-flex align-items-center'
                />
                <div className='col-lg-9 fv-row'>
                  <HookFormTextField
                    required
                    control={control}
                    name='capacity'
                    type='number'
                    defaultValue={classDetail?.capacity}
                    disabled={isCheckEnded}
                    minNumber={1}
                    requiredZero={true}
                  />
                </div>
              </div>

              {/* DURATION */}
              <div className='row mb-8'>
                <SappLabel
                  label={TITLE_OPTIONS_CLASS.duration}
                  className='col-lg-3 d-flex align-items-center'
                />
                <div className='col-lg-9 fv-row'>
                  <HookFormRadioGroup
                    direction='horizontal'
                    separator={false}
                    name='duration_type'
                    control={control}
                    justify='start'
                    gap={32}
                    labelClass='fw-semibold fs-6 sapp-table-class-field'
                    onChange={handleChangeDuration}
                    defaultValue={classDetail?.duration_type}
                    options={[
                      {
                        label: `${OPTIONS_OF_DURATION.labelFixed}`,
                        value: `${OPTIONS_OF_DURATION.valueFixed}`,
                      },
                      {
                        label: `${OPTIONS_OF_DURATION.labelFlexible}`,
                        value: `${OPTIONS_OF_DURATION.valueFlexible}`,
                      },
                    ]}
                    disabled={isCheckEnded || is_student_studying_normal_course}
                  />
                  <div className='row mt-8'>
                    {selectedRadio === `${OPTIONS_OF_DURATION.valueFixed}` && (
                      <>
                        <div className='col-lg-6 fv-row'>
                          <HookFormDateTime
                            control={control}
                            name='opening_at'
                            placeholder='Opening Date'
                            defaultValue={classDetail?.opening_at}
                            disabled={isCheckEnded || is_student_studying_normal_course}
                          />
                        </div>
                        <div className='col-lg-6 fv-row'>
                          <RangeDateTimePicker
                            control={control}
                            name='duration_at'
                            allowClear={false}
                            disabled={isCheckEnded || is_student_studying_normal_course}
                          />
                        </div>
                      </>
                    )}

                    {selectedRadio === `${OPTIONS_OF_DURATION.valueFlexible}` && (
                      <div className='position-relative col-lg-6'>
                        <HookFormTextField
                          required
                          control={control}
                          name='flexible_days'
                          placeholder=' '
                          type='number'
                          defaultValue={classDetail?.flexible_days}
                          postFix={<div className='px-2'>days</div>}
                          disabled={isCheckEnded || is_student_studying_normal_course}
                          minNumber={1}
                          maxNumber={100000}
                          requiredZero={true}
                        />
                      </div>
                    )}
                  </div>
                  {warning.duration !== '' && (
                    <div className='mt-5 sapp-text-danger'>{warning.duration}</div>
                  )}
                </div>
              </div>

              {/* COURSE */}
              <div className='row mb-8'>
                <SappLabel
                  label={TITLE_OPTIONS_CLASS.course}
                  className='col-lg-3 d-flex align-items-center'
                />
                <div className='col-lg-9 fv-row position-relative'>
                  <div>
                    <ButtonIcon
                      title={`${checkedListCourse[0]?.id !== undefined ? ' ' : 'Add Course'}`}
                      className={clsx(
                        'sapp-h-45px d-flex justify-content-start align-items-center opacity-100 w-100',
                        {
                          'disabled-background':
                            isCheckEnded ||
                            is_student_studying_normal_course ||
                            is_student_studying_foundation_course ||
                            classDetail?.type === IClassType.FOUNDATION,
                        }
                      )}
                      customButton={`btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 sapp-font-medium text-gray-500 p-0 ${
                        checkedListCourse[0]?.id !== undefined ? 'position-relative' : ''
                      }`}
                      type='button'
                      onClick={() => {
                        if (getValues('type') !== '') {
                          setOpenAddCourse(true)
                          setTypeClass(getValues('type'))
                        }
                      }}
                      disabled={
                        isCheckEnded ||
                        is_student_studying_normal_course ||
                        !allowRenderEditRole ||
                        is_student_studying_foundation_course ||
                        classDetail?.type === IClassType.FOUNDATION
                      }
                    >
                      {checkedListCourse[0]?.id !== undefined ? (
                        <div className='sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4 sapp-padding-end-6'>
                          {checkedListCourse[0]?.name}
                        </div>
                      ) : (
                        <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                      )}
                      {allowRenderEditRole && (
                        <KTIcon
                          iconName='notepad-edit'
                          className={`fs-1 text-gray-500 ps-5 pe-1 sapp-icon-last ${
                            checkedListCourse[0]?.id !== undefined ? 'last' : 'mid'
                          }`}
                          iconType='outline'
                        />
                      )}
                    </ButtonIcon>
                    {checkedListCourse[0]?.id !== undefined && allowRenderEditRole && (
                      <div className='sapp-icon-last'>
                        <ButtonIconOnly
                          iconName='trash'
                          onClick={() => {
                            !isCheckEnded &&
                              !is_student_studying_normal_course &&
                              handleChangeType()
                          }}
                          iconType='outline'
                          bg={`sapp-custom-bg-transparent ${
                            isCheckEnded || is_student_studying_normal_course ? 'pe-none' : ''
                          }`}
                          activeColor='danger'
                          disabled={!allowRenderEditRole || is_student_studying_foundation_course}
                          iconGrayColor={'500'}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {warning?.course !== '' && (
                  <div className='row'>
                    <div className='col-lg-3 d-flex align-items-center'></div>
                    <div className='col-lg-9 fv-row position-relative'>
                      <div className='mt-5 sapp-text-danger'>{warning?.course}</div>
                    </div>
                  </div>
                )}
              </div>

              {/* EXAM */}
              <div className='row mb-8'>
                <SappLabel
                  label={TITLE_OPTIONS_CLASS.exam}
                  className='col-lg-3 d-flex align-items-center'
                />
                <div
                  className={clsx('col-lg-9 fv-row position-relative', {
                    'cursor-not-allowed':
                      isCheckEnded ||
                      is_student_studying_normal_course ||
                      checkedListCourse[0] == null ||
                      (classDetail?.type === IClassType.REVISION &&
                        classDetail.examination_subject_id !== null),
                  })}
                >
                  <ButtonIcon
                    title={`${checkedListExam[0]?.id !== undefined ? ' ' : 'Exam'}`}
                    className={clsx(
                      'sapp-h-45px d-flex justify-content-start align-items-center opacity-100 w-100',
                      {
                        'disabled-background':
                          isCheckEnded ||
                          is_student_studying_normal_course ||
                          !allowRenderEditRole ||
                          checkedListCourse[0] == null ||
                          (classDetail?.type === IClassType.REVISION &&
                            classDetail.examination_subject_id !== null),
                      }
                    )}
                    customButton={`btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 sapp-text-primary p-0 ${
                      checkedListExam[0]?.id !== undefined ? 'position-relative' : ''
                    }`}
                    type='button'
                    onClick={() => {
                      setOpenAddExam(true)
                    }}
                    disabled={
                      isCheckEnded ||
                      is_student_studying_normal_course ||
                      !allowRenderEditRole ||
                      checkedListCourse[0] == null ||
                      (classDetail?.type === IClassType.REVISION &&
                        classDetail.examination_subject_id !== null)
                    }
                  >
                    {checkedListExam[0]?.id !== undefined ? (
                      <div className='sapp-text-primary sapp-text-truncate-1 text-start sapp-fs-14 fw-semibold ps-4 sapp-padding-end-6'>
                        {checkedListExam[0]?.examination.name ?? '-'}
                      </div>
                    ) : (
                      <KTIcon iconName='plus' className='fs-1 text-gray-500 ps-5 pe-1' />
                    )}
                    {allowRenderEditRole && classDetail?.type !== 'REVISION' && (
                      <KTIcon
                        iconName='notepad-edit'
                        className={`fs-1 text-gray-500 ps-5 pe-1 sapp-icon-last ${
                          checkedListExam[0]?.id !== undefined ? 'last' : 'mid'
                        }`}
                        iconType='outline'
                      />
                    )}
                  </ButtonIcon>
                  {checkedListExam[0]?.id !== undefined &&
                    allowRenderEditRole &&
                    classDetail?.type !== 'REVISION' && (
                      <div className='sapp-icon-last'>
                        <ButtonIconOnly
                          iconName='trash'
                          onClick={() => {
                            if (!isCheckEnded && !is_student_studying_normal_course) {
                              setCheckedListExam([])
                              setCourseCategoryId('')
                            }
                          }}
                          iconType='outline'
                          bg={`sapp-custom-bg-transparent ${
                            isCheckEnded || is_student_studying_normal_course ? 'pe-none' : ''
                          }`}
                          activeColor='danger'
                          disabled={!allowRenderEditRole}
                          iconGrayColor={'500'}
                        />
                      </div>
                    )}
                </div>
              </div>

              {/* DESCRIBE */}
              <div className='row mb-8'>
                <SappLabel
                  label={TITLE_OPTIONS_CLASS.describe}
                  className='col-lg-3 d-flex align-items-center'
                />
                <div className='col-lg-9 fv-row'>
                  <HookFormEditor
                    height={352}
                    placeholder=' '
                    name='description'
                    control={control}
                    math={true}
                    className='w-100 fs-6 lh-1 sapp-table-class-field'
                    defaultValue={classDetail?.description}
                    disabled={isCheckEnded}
                    resourceLocation={RESOURCE_LOCATION.COURSE}
                    object_id={undefined}
                  />
                </div>
              </div>
            </div>
            <SappDrawer
              open={openBlock}
              title='Block Class'
              width={'50%'}
              handleSubmit={() => {
                handleSubmit((data: any) => {
                  onSubmit(data)
                })()
              }}
              handleClose={handleCloseBlock}
              confirmOnclose
              cancelButtonCaption={'Cancel'}
              okButtonCaption={'Save'}
              footerClassName='border-0'
            >
              <HookFormEditor
                label='Reason'
                placeholder=' '
                required
                control={control}
                name='reason'
                math={true}
                height={500}
                defaultValue={classDetail?.reason}
                resourceLocation={RESOURCE_LOCATION.COURSE}
                object_id={undefined}
              />
            </SappDrawer>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <ButtonSecondary
                title='Discard'
                className='me-5'
                onClick={handleCancel}
                type='button'
              />
              <ButtonPrimary
                type='submit'
                title='Save'
                loading={loadingClass || isLoading}
                disabled={isCheckEnded || !allowRenderEditRole || isLoading}
                onClick={() => {
                  if (statusBlock === 'BLOCKED') {
                    setOpenBlock(true)
                  } else {
                    handleSubmit((data: any) => {
                      onSubmit(data)
                    })()
                  }
                }}
              />
            </div>
            <AddCourseToClass
              id={id}
              open={openAddCourse}
              setOpen={setOpenAddCourse}
              checkedList={checkedListCourse}
              setCheckedList={setCheckedListCourse}
              typeClass={typeClass}
              handleResetListExam={handleResetListExam}
              courseCategoryId={courseCategoryId}
              subject_id={classDetail?.examination_subject?.subject_id}
              program={classDetail?.examination_subject?.examination?.course_category?.name}
              subject={classDetail?.examination_subject?.subject?.name}
              setCheckedListExam={setCheckedListExam}
              checkedListExam={checkedListExam}
            />

            <AddExam
              open={openAddExam}
              setOpen={setOpenAddExam}
              id={checkedListCourse[0]?.id}
              course_id={checkedListCourse?.[0]?.id}
              course_category_id={checkedListCourse?.[0]?.course_categories?.[0]?.id}
              course_category_type={checkedListCourse?.[0]?.course_categories?.[0]?.name}
              checkedList={checkedListExam}
              setCheckedList={setCheckedListExam}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default EditClassProfile
