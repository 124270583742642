import { fetcher } from 'src/services/request'
import {
  IDepartmentForm,
  IDepartmentRes,
  IDepartmentsTreeRes,
  IDepartmentTree,
  IResponse,
} from 'src/type'
import { DEPARTMENT_TYPES } from 'src/type/department/enum'

export class DepartmentAPI {
  static getList({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IDepartmentRes>> {
    return fetcher(`departments?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getTree({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IDepartmentsTreeRes>> {
    return fetcher(`departments?page_index=${page_index}&page_size=${page_size}`, {
      params: {
        ...params,
        tree_view: true,
      },
    })
  }

  static getDetail(id: string): Promise<IResponse<IDepartmentTree>> {
    return fetcher(`departments/${id}`)
  }

  static getParent(
    page_index: number,
    page_size: number | undefined,
    type: DEPARTMENT_TYPES,
    department_id?: string
  ) {
    return fetcher(`/departments/parent-list`, {
      params: {
        page_index: page_index,
        page_size: page_size,
        type: type,
        department_id: department_id,
      },
    })
  }

  static create(data: IDepartmentForm) {
    return fetcher(`/departments`, {
      method: 'POST',
      data: data,
    })
  }

  static update(id: string, data: IDepartmentForm) {
    return fetcher(`/departments/${id}`, {
      method: 'PUT',
      data: data,
    })
  }

  static delete(department_ids: string[]): Promise<IResponse<any>> {
    return fetcher('departments', {
      method: 'DELETE',
      data: {
        department_ids,
      },
    })
  }
}
