import { Skeleton, Spin } from 'antd'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
import GuidelineField from 'src/common/GuidelineField'
import { ArrowDownIcon } from 'src/common/icons'
import SappLabel from 'src/components/base/label/SappLabel'
import './HookFormSelect.scss'
import SAPPSelect from './SAPPSelect'
import { ISappSelectHookForm } from './type'

const HookFormSelectAntd = ({
  name,
  control,
  defaultValue = '',
  className = '',
  onChange,
  label,
  size,
  guideline,
  dropdownStyle,
  placeholder,
  showSearch = false,
  filterOption,
  filterSort,
  skeleton,
  required,
  customError,
  onSearch,
  disabled,
  loading,
  allowClear = true,
  onClear,
  suffixIcon = <ArrowDownIcon />,
  handleNextPage,
  onBlur,
  onSelect,
  onFocus,
  isLocked = false,
  classNameHeight,
  onDropdownVisibleChange,
  notFoundContent,
  options,
}: ISappSelectHookForm) => {
  return (
    <>
      {label && <SappLabel label={label} required={required} />}
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            {!skeleton ? (
              <SAPPSelect
                {...field}
                className={clsx(className, `w-100 sapp-hook-form-select`)}
                classNameHeight={classNameHeight}
                onChange={(val = '') => {
                  field.onChange(val)
                  onChange && onChange(val)
                }}
                size={size}
                dropdownStyle={{
                  zIndex: '2000',
                  minWidth: '150px',
                  ...(dropdownStyle && dropdownStyle),
                }}
                placeholder={placeholder || 'Please Select'}
                showSearch={showSearch}
                onSearch={onSearch}
                filterOption={
                  filterOption ??
                  (showSearch &&
                    ((input, option) => {
                      const optionText = (
                        option?.userName ??
                        option?.children ??
                        option?.label ??
                        ''
                      )
                        .toString()
                        .toLowerCase()
                      const optionEmail = (option?.email ?? '').toString().toLowerCase()
                      const trimmedInput = input?.toLowerCase()?.trimStart()?.trimEnd()
                      // Check if the trimmed option text includes the trimmed input
                      return optionText.includes(trimmedInput) || optionEmail.includes(trimmedInput)
                    }))
                }
                filterSort={filterSort}
                status={`${error || customError ? 'error' : ''}`}
                disabled={disabled}
                allowClear={allowClear}
                onClear={onClear}
                loading={loading}
                suffixIcon={!isLocked ? suffixIcon : null}
                onPopupScroll={(e) => {
                  const { target } = e
                  if (
                    Math.ceil((target as HTMLElement).scrollTop) +
                      Math.ceil((target as HTMLElement).offsetHeight) ===
                    Math.ceil((target as HTMLElement).scrollHeight)
                  ) {
                    if (handleNextPage) {
                      handleNextPage()
                    }
                  }
                }}
                onBlur={onBlur}
                onSelect={onSelect}
                onFocus={onFocus}
                onDropdownVisibleChange={onDropdownVisibleChange}
                value={field.value ? field.value : undefined}
                options={options}
                notFoundContent={loading ? notFoundContent ?? <Spin size='small' /> : 'No data'}
              />
            ) : (
              <Skeleton.Input
                active
                size={size === 'middle' ? 'default' : size}
                className='w-100'
              />
            )}

            <ErrorMessage>{error?.message}</ErrorMessage>
          </>
        )}
      />
      <GuidelineField guideline={guideline} />
    </>
  )
}

export default HookFormSelectAntd
