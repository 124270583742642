import { Dispatch, SetStateAction, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { KTCardBody } from 'src/_metronic/helpers'
import { CoursesAPI } from 'src/apis/courses'
import LoadingTable from 'src/common/LoadingTable'
import ActionCell from 'src/components/base/action/ActionCell'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import { MOCKUP_HEADER } from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM, LANG_COURSE_SUBJECT } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { ICourseSubjectList } from 'src/type/courses'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import CreateAndUpdateSubject from './CreateAndUpdateSubject'
import SappBaseTable from 'src/common/SappBaseTable'

interface IProps {
  subjectList: ICourseSubjectList | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  fetchSubject: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  setOpenCourse: Dispatch<SetStateAction<boolean>>
  open: boolean
  idItem: string
  setIdItem: Dispatch<SetStateAction<string>>
  queryParams: {
    name?: string
    course_category_id?: string
    fromDate?: Date
    toDate?: Date
    page_index?: number
    page_size?: number
  }
  getParams: (
    name: string,
    course_category_id: string,
    fromDate: Date | string,
    toDate: Date | string,
    dateField: string
  ) => {
    name?: string
    course_category_id?: string
    fromDate?: string | Date
    toDate?: string | Date
    dateField?: string
  }

  checkedList: string[]
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, memorize?: boolean) => void
  isCheckedAll: boolean
}

const headers = [
  {
    label: '#',
    className: 'min-w-50px pe-5',
  },
  {
    label: LANG_COURSE_SUBJECT.SUBJECT_NAME,
    className: 'min-w-250px pe-5',
  },
  {
    label: LANG_COURSE_SUBJECT.CODE,
    className: 'min-w-250px pe-5',
  },
  {
    label: LANG_COURSE_SUBJECT.PROGRAM,
    className: 'min-w-250px pe-5',
  },
  {
    label: LANG_COURSE_SUBJECT.UPDATED_AT,
    className: 'min-w-250px pe-5',
  },
  {
    label: LANG_COURSE_SUBJECT.ACTION,
    className: 'pe-5',
  },
]

const TableListSubject = ({
  subjectList,
  fetchSubject,
  filterParams,
  loading,
  setLoading,
  setOpenCourse,
  open,
  idItem,
  setIdItem,
  queryParams,
  getParams,
  toggleCheckAll,
  isCheckedAll,
}: IProps) => {
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()

  const showQueryParams =
    queryParams.name ||
    queryParams?.course_category_id ||
    queryParams.fromDate ||
    queryParams.toDate

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = queryParams?.fromDate && getDateInfo(queryParams?.fromDate)
  const dateQueryToDate = queryParams?.toDate && getDateInfo(queryParams?.toDate)

  //TODO: dùng để change params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams?.name?.trimStart()?.trimEnd() ?? '',
      queryParams?.course_category_id?.trimStart()?.trimEnd() ?? '',
      queryParams.fromDate && dateQueryFromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate && dateQueryToDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      showQueryParams ? 'updated_at' : 'created_at'
    )
  )

  const handleOpenModal = (id: string) => {
    setOpenCourse(true)
    setIdItem(id)
  }

  const handleDeleteSubject = async (id?: string) => {
    if (!id) return
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: async () => {
        setLoading(true)
        try {
          await CoursesAPI.deleteSubject(id)
          await fetchSubject(1, 10)
          toast.success('Delete Successfully!')
        } catch (err) {
        } finally {
          setLoading(false)
        }
      },
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchSubject(queryParams?.page_index || 1, queryParams?.page_size || 10, filterParams)
  }, [])

  //TODO: đổi params trên router
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      name: queryParams.name ?? '',
      course_category_id: queryParams?.course_category_id ?? '',
      fromDate: queryParams.fromDate ? formatDate(queryParams.fromDate) : '',
      toDate: queryParams.toDate ? formatDate(queryParams.toDate) : '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchSubject(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }

  return (
    <KTCardBody>
      {contextHolder}
      <SappBaseTable
        data={subjectList?.subjects}
        headers={headers}
        hasCheck={false}
        loading={loading}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
        dataResponse={subjectList}
        totalItems={subjectList?.meta?.total_records || 10}
        handlePaginationChange={handlePaginationChange}
      >
        {subjectList?.subjects?.map((subject, index: number) => {
          return (
            <tr key={subject.id}>
              <td>
                {index + 1 + subjectList?.meta?.page_size * (subjectList?.meta?.page_index - 1)}
              </td>
              <td
                className='text-hover-primary cursor-pointer text-gray-800'
                onClick={() => handleOpenModal(subject.id)}
              >
                <div className='sapp-text-truncate-2'>{subject?.name}</div>
              </td>
              <td className='text-start'>{subject?.code ?? '--'}</td>
              <td className='text-start'>
                <div className='sapp-text-truncate-2'>{subject?.course_category?.name ?? '--'}</div>
              </td>
              <td className='text-start'>
                <DatetimeColumn created_at={subject?.created_at} updated_at={subject?.updated_at} />
              </td>
              <td className='text-end'>
                <ActionCell customWidth='w-150px'>
                  <div className='menu-item px-3' onClick={() => handleOpenModal(subject.id)}>
                    <div className='menu-link px-3'>Edit</div>
                  </div>
                  <div className='menu-item px-3' onClick={() => handleDeleteSubject(subject?.id)}>
                    <div className='menu-link px-3'>Delete</div>
                  </div>
                </ActionCell>
              </td>
            </tr>
          )
        })}
      </SappBaseTable>
      <CreateAndUpdateSubject
        handleClose={() => setOpenCourse(false)}
        setLoading={setLoading}
        open={open}
        id={idItem}
        fetchSubject={fetchSubject}
        currentPage={queryParams?.page_index || 1}
        pageSize={queryParams?.page_size || 10}
        loading={loading}
        filterParamsPagination={filterParamsPagination}
      />
    </KTCardBody>
  )
}

export default TableListSubject
