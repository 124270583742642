import { Dispatch, SetStateAction, useState } from 'react'
import Detail from './Detail'
import SubmitForm from './SubmitForm'
import { GradeAPI } from 'src/apis/grade'
import { removeHighlightFromDOM, restoreHighlights } from 'src/utils/grade'
import { GradingDetail, IResponse, QuestionAnswer, QuestionGradingResponse } from 'src/type/grade'
import { Skeleton } from 'antd'
import { toast } from 'react-hot-toast'
import { useConfirm } from 'src/hooks/use-confirm'

interface CommentAndGradeProps {
  listGrades: GradingDetail[]
  setListGrades: Dispatch<SetStateAction<GradingDetail[]>>
  questionGradingId: string
  editingItem: GradingDetail | null
  setEditingItem: Dispatch<SetStateAction<GradingDetail | null>>
  fetchGrade: (activeQuestion: QuestionAnswer[]) => void
  activeQuestion: QuestionAnswer[]
  getQuizAttemptInfo: () => void
  activeRequirement: number
  loading: boolean
  gradeStatus: string
}

const CommentAndGrade = ({
  listGrades,
  setListGrades,
  questionGradingId,
  editingItem,
  setEditingItem,
  fetchGrade,
  activeQuestion,
  getQuizAttemptInfo,
  activeRequirement,
  loading,
  gradeStatus,
}: CommentAndGradeProps) => {
  const { confirm, contextHolder } = useConfirm()

  const [loadingComment, setLoadingComment] = useState(false)

  const isComment = editingItem?.status === 'COMMENT' || editingItem?.validate === 'comment'

  // Handle form submission
  const onSubmit = async (data: { grade: string; comment: string }) => {
    if (editingItem) {
      setLoadingComment(true)
      try {
        const roundedGrade =
          data?.grade || Number(data?.grade)
            ? Number(data?.grade)
            : Number(data?.grade) === 0
            ? 0
            : null
        const roundedComment = data?.grade || Number(data?.grade) ? Number(data?.grade) : null
        let response: IResponse<QuestionGradingResponse>
        if (editingItem.begin === 0 && editingItem.end === 0) {
          response = await GradeAPI.gradeQuestionByFile(questionGradingId, {
            requirement_id: activeQuestion?.[activeRequirement]?.requirement_id || null,
            grade: roundedGrade,
            grading_file: {
              file_key: editingItem?.grading_file?.file_key || '',
              file_name:
                editingItem?.grading_file?.file_name || editingItem?.grading_file?.name || '',
            },
          })
        } else {
          response = await GradeAPI.gradeQuestion(questionGradingId, {
            requirement_id: activeQuestion?.[activeRequirement]?.requirement_id || null,
            begin: Number(editingItem?.begin || null),
            end: Number(editingItem?.end || null),
            comment: data?.comment || '',
            grade: isComment ? roundedComment : roundedGrade,
            status: isComment ? 'COMMENT' : 'GRADING',
          })
        }
        if (response) {
          // Update grades list after deletion
          setListGrades((prevList) => {
            const list = prevList.map((grade) =>
              grade?.begin === editingItem?.begin && grade.end === editingItem?.end
                ? {
                    ...grade,
                    comment: data?.comment || '',
                    grade: roundedGrade,
                    status: isComment ? 'COMMENT' : 'GRADING',
                  }
                : { ...grade, status: isComment ? 'COMMENT' : 'GRADING' }
            )
            restoreHighlights(list, activeRequirement.toString())
            return list
          })
          setEditingItem(null) // Xóa trạng thái chỉnh sửa
          toast.success('Save successfully!')
          await fetchGrade(activeQuestion)
          await getQuizAttemptInfo()
        }
      } catch (error) {
        console.error('Error when submit grade:', error)
      } finally {
        setLoadingComment(false)
      }
    }
  }

  // Handle cancel action
  const onCancel = () => {
    const gradeToDelete = listGrades.find(
      (grade) => grade.begin === editingItem?.begin && grade?.end === editingItem?.end
    )

    if (gradeToDelete && !gradeToDelete.comment && !gradeToDelete.grade) {
      removeHighlightFromDOM(
        Number(gradeToDelete?.begin),
        Number(gradeToDelete?.end),
        activeRequirement.toString()
      )

      // Update grades list after deletion
      setListGrades((prevGrades) => {
        const updatedGrades = prevGrades.filter(
          (grade) => !(grade?.begin === editingItem?.begin && grade.end === editingItem?.end)
        )

        return updatedGrades
      })
    }
    setEditingItem(null)
  }

  // Handle edit action
  const editGrade = (item: GradingDetail) => {
    setEditingItem(item)
  }

  const handleRemove = (itemDelete: GradingDetail) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: () => {
        deleteGrade(itemDelete)
      },
    })
  }

  // Handle delete action
  const deleteGrade = async (item: GradingDetail) => {
    try {
      let response: IResponse<QuestionGradingResponse>

      if (item?.validate === 'file') {
        response = await GradeAPI.deleteQuestionByFile(
          questionGradingId,
          activeQuestion?.[activeRequirement]?.requirement_id || null
        )
      } else {
        response = await GradeAPI.deleteQuestion(questionGradingId, {
          requirement_id: activeQuestion?.[activeRequirement]?.requirement_id || null,
          begin: Number(item?.begin),
          end: Number(item?.end),
        })
      }

      await fetchGrade(activeQuestion)
      await getQuizAttemptInfo()

      const gradeToDelete = listGrades.find(
        (grade) => grade.begin === item.begin && grade.end === item.end
      )

      if (gradeToDelete && response) {
        await new Promise<void>((resolve) => {
          removeHighlightFromDOM(
            Number(gradeToDelete?.begin),
            Number(gradeToDelete?.end),
            activeRequirement.toString()
          )
          resolve()
        })
        toast.success('Remove successfully!')
      }
    } catch (error) {
      console.error('Error when remove grade:', error)
    }
  }
  return (
    <>
      {contextHolder}
      <div className='comment-wrapper'>
        {loading ? (
          <Skeleton active paragraph={{ rows: 8 }} />
        ) : (
          listGrades.map((item) =>
            editingItem && editingItem.begin === item.begin && editingItem.end === item.end ? (
              <SubmitForm
                key={`${item.begin}-${item.end}`}
                item={item}
                onSubmit={onSubmit}
                onCancel={onCancel}
                isFile={item?.begin === 0 && item?.end === 0}
                loading={loadingComment}
              />
            ) : (
              <Detail
                key={`${item.begin}-${item.end}`}
                item={item}
                onEdit={() => editGrade(item)}
                onDelete={() => {
                  handleRemove(item)
                }}
                gradeStatus={gradeStatus}
                index={activeRequirement.toString()}
              />
            )
          )
        )}
      </div>
    </>
  )
}

export default CommentAndGrade
