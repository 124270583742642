import React from 'react'
import './styles.scss'
import clsx from 'clsx'

type FieldNoteProps = React.HTMLAttributes<HTMLSpanElement>

const FieldNote: React.FC<FieldNoteProps> = (props) => {
  return (
    <span {...props} className={clsx('field-note', props.className)}>
      {props.children}
    </span>
  )
}

export default FieldNote
