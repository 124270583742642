import { TablePaginationConfig } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { ClassesApi } from 'src/apis/classes'
import LayoutTable from 'src/common/LayoutTable'
import withAuthComponents from 'src/components/auth/with-auth-components'
import FilterClassList from 'src/components/classes/class-list/FilterClassList'
import ModalImportClasses from 'src/components/classes/ModalImportClasses'
import PageLayouts from 'src/components/layout/PageLayouts'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import { PageLink } from 'src/constants'
import { TITLE_OPTIONS_CLASS } from 'src/constants/classes'
import { LANG_CLASSES, LANG_SIDEBAR } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_CLASS_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { ITabs, Role } from 'src/type'
import { IClassList } from 'src/type/classes'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import TableClasses from './tableClasses'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: TITLE_OPTIONS_CLASS.listClasses,
  },
]

const fieldNames = [
  'class_name',
  'class_code',
  'course_category_id',
  'course_level_id',
  'facility_id',
  'status',
  'class_type',
  'subject_id',
  'fromDate',
  'toDate',
]

const initialValues: Record<string, any> = {
  class_name: '',
  class_code: '',
  course_category_id: '',
  course_level_id: '',
  facility_id: '',
  status: '',
  class_type: '',
  subject_id: '',
  fromDate: '',
  toDate: '',
}

const Classes = () => {
  const { profileMe } = useUserContext()
  const allowRenderImportClass = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_CLASS_GR.IMPORT_CLASS) ||
      role.permissions?.includes(TITLE_CLASS_GR.CREATE_CLASS) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderCreateClass = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_CLASS_GR.CREATE_CLASS) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const [openImportClass, setOpenImportClass] = useState<boolean>(false)
  const navigate = useNavigate()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  // state để dùng class code

  const searchParams = new URLSearchParams(search)

  const queryParams = {
    class_name: searchParams.get('class_name') ?? '',
    class_code: searchParams.get('class_code') ?? '',
    course_category_id: searchParams.get('course_category_id') ?? '',
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    status: searchParams.get('status') as string,
    facility_id: searchParams.get('facility_id'),
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
    instruction_mode: searchParams.get('instruction_mode') ?? '',
    class_type: searchParams.get('class_type') ?? '',
    subject_id: searchParams.get('subject_id') ?? '',
  }

  const { control, getValues, reset, setValue, watch } = useForm<any>({ mode: 'onSubmit' })

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      class_name: getValues('class_name'),
      instruction_mode: getValues('instruction_mode'),
      class_type: getValues('class_type'),
      course_category_id: getValues('course_category_id'),
      subject_id: getValues('subject_id'),
      facility_id: getValues('facility_id'),
      sortType: getValues('sortType'),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
      status: getValues('status'),
      class_code: getValues('class_code'),
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const [classList, setClassList] = useState<IClassList[]>([])
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: queryParams?.page_index || 1,
    pageSize: queryParams?.page_size || 10,
    total: 10,
    showSizeChanger: true,
    showQuickJumper: true,
  })

  const fetchData = async (page: number, pageSize: number, params?: Object) => {
    const response = await ClassesApi.get({ page_index: page, page_size: pageSize, params: params })
    return {
      data: response?.data?.classes,
      total: response?.data?.meta?.total_records,
    }
  }

  const fetchTableData = async (page: number, pageSize: number, params?: Object) => {
    setLoading(true)
    try {
      const response = await fetchData(page, pageSize, params)
      setClassList(response.data)
      setPagination((prev) => ({ ...prev, total: response.total }))
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const filterParams = cleanParamsAPI({
    class_name: queryParams.class_name.trimStart().trimEnd() ?? '',
    course_category_id: queryParams.course_category_id ?? '',
    facility_id: queryParams.facility_id ?? '',
    sortType: queryParams.sortType,
    fromDate: queryParams.fromDate,
    toDate: queryParams.toDate,
    status: queryParams.status,
    instruction_mode: queryParams.instruction_mode,
    class_type: queryParams.class_type,
    subject_id: queryParams.subject_id,
    class_code: queryParams.class_code,
  })

  let cleanedParams = {}

  const onSubmit = () => {
    setLoading(true)
    cleanedParams = {
      class_name: getValues('class_name') || undefined,
      class_code: getValues('class_code') || undefined,
      course_category_id: getValues('course_category_id') || undefined,
      facility_id: getValues('facility_id') || undefined,
      sortType: getValues('sortType') || undefined,
      fromDate: getValues('fromDate')
        ? formatISOFromDate(
            getDateInfo(getValues('fromDate')).year,
            getDateInfo(getValues('fromDate')).month,
            getDateInfo(getValues('fromDate')).day
          )
        : undefined,
      toDate: getValues('toDate')
        ? formatISOToDate(
            getDateInfo(getValues('toDate')).year,
            getDateInfo(getValues('toDate')).month,
            getDateInfo(getValues('toDate')).day
          )
        : undefined,
      status: getValues('status') || undefined,
      instruction_mode: getValues('instruction_mode') || undefined,
      class_type: getValues('class_type') || undefined,
      subject_id: getValues('subject_id') || undefined,
      dateField: 'updated_at',
    }
    fetchTableData(1, pagination.pageSize || 10, cleanedParams)
    setPagination({ current: 1 })
    handleChangeParams(1, queryParams.page_size || 10)
  }

  const handleResetFilter = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setLoading(true)
    navigate(PageLink.CLASSES)
    fetchTableData(1, 10)
  }

  useEffect(() => {
    const fromDate = watch('fromDate')
    const toDate = watch('toDate')

    if (fromDate && toDate && moment(toDate).isBefore(fromDate)) {
      setValue('toDate', moment(fromDate).endOf('day').toDate())
    }

    if (toDate && fromDate && moment(fromDate).isAfter(toDate)) {
      setValue('fromDate', moment(toDate).startOf('day').toDate())
    }
  }, [watch('fromDate'), watch('toDate')])

  return (
    <PageLayouts pageTitle={TITLE_OPTIONS_CLASS.listClasses} breadcrumbs={breadcrumbs}>
      <LayoutTable
        layoutFilter={
          <FilterClassList
            control={control}
            queryParams={queryParams}
            onSubmit={onSubmit}
            watch={watch}
            setValue={setValue}
          />
        }
        layoutAction={
          <div>
            {allowRenderImportClass && (
              <ButtonIconPrimary
                iconName='file-up'
                title={LANG_CLASSES.importClass}
                onClick={() => setOpenImportClass(true)}
                size='small'
                iconType='outline'
                className='me-4'
              />
            )}
            {/* start:: button open modal */}
            {allowRenderCreateClass && (
              <ButtonIconPrimary
                iconName={'plus'}
                title={LANG_CLASSES.addClass}
                onClick={() => navigate(PageLink.CREATE_CLASSES)}
                size='small'
              />
            )}
            {/* end:: button open modal */}
          </div>
        }
        layoutTable={
          <TableClasses
            classList={classList}
            loading={loading}
            setLoading={setLoading}
            handleChangeParams={handleChangeParams}
            fetchClassList={fetchTableData}
            filterParams={filterParams}
            queryParams={queryParams}
            pagination={pagination}
            setPagination={setPagination}
            cleanedParams={cleanedParams}
          />
        }
        loading={loading}
        onReset={handleResetFilter}
        onSubmit={onSubmit}
      />
      <ModalImportClasses open={openImportClass} setOpen={setOpenImportClass}></ModalImportClasses>
    </PageLayouts>
  )
}
export default withAuthComponents(Classes)
