import { Select, Spin } from 'antd'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
import GuidelineField from 'src/common/GuidelineField'
import { DownIcon } from 'src/common/icons'
import SappLabel from 'src/components/base/label/SappLabel'
import './HookFormSelect.scss'
import { BaseOptionType } from 'antd/es/select'

interface IProps {
  placeholder?: string
  control: Control<any>
  defaultValue?: any
  name: string
  options: BaseOptionType[]
  label?: string
  guideline?: string[]
  required?: boolean
  handleNextPage?: any
  onSelect?: any
  disabled?: boolean
  classNameHeight?: 'min-h-45px' | 'min-h-40px'
  onSearch: (text: string | undefined) => void
  loading: boolean
  maxCount?: number
  onFocus?: (e: React.FocusEvent) => void
}

const HookFormSelectMultiple = ({
  placeholder,
  control,
  defaultValue,
  name,
  options,
  label,
  guideline,
  required,
  handleNextPage,
  classNameHeight = 'min-h-45px',
  disabled = false,
  onSearch,
  onFocus,
  loading,
  maxCount,
}: IProps) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined)

  const debounceSearch = debounce((e) => {
    setSearchText(e)
  }, 350)

  useEffect(() => {
    onSearch(searchText)
  }, [searchText])

  return (
    <>
      {label && <SappLabel label={label} required={required} />}
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <>
            <Select
              loading={loading}
              {...field}
              placeholder={placeholder}
              style={{ width: '100%' }}
              dropdownStyle={{ zIndex: '2000' }}
              mode='multiple'
              size='large'
              className={`sapp-hook-form-select hookform-multi-select ${classNameHeight}`}
              showSearch
              status={error ? 'error' : ''}
              onSearch={(e: string) => {
                debounceSearch(e)
              }}
              onFocus={(e: React.FocusEvent) => {
                onFocus && onFocus(e)
              }}
              filterOption={(input, option) => {
                return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }}
              onBlur={() => setSearchText(undefined)}
              onPopupScroll={(e) => {
                const { target } = e
                if (
                  (target as any).scrollTop + (target as any).offsetHeight ===
                  (target as any).scrollHeight
                ) {
                  if (handleNextPage) {
                    handleNextPage()
                  }
                }
              }}
              notFoundContent={loading ? <Spin size='small' className='' /> : null}
              suffixIcon={<DownIcon />}
              disabled={disabled}
              options={options}
              maxCount={maxCount}
              onChange={(value) => {
                field.onChange(value) // Update the form value
                setSearchText('') // Reset the search text
              }}
            />
            <GuidelineField guideline={guideline} />
            <ErrorMessage>{error?.message}</ErrorMessage>
          </>
        )}
      />
    </>
  )
}

export default HookFormSelectMultiple
