export const CLASS_PROFILE = '/classes/detail'

export const CLASS_PROFILE_URL = {
  OVERVIEW: 'overview',
  SETTING: 'setting',
  STUDENTS: 'students',
  MENTORS: 'mentors',
  EXAMINATION: 'examination',
  MARK: 'mark',
  TEACHERS: 'teachers',
  TESTQUIZ: 'test-quiz',
}

export const TITLE_OPTIONS_CLASS = {
  classes: 'Classes',
  listClasses: 'Class List',
  createClass: 'Create Class',
  classDetail: 'Class Detail',
  name: 'Name',
  code: 'Code',
  status: 'Status',
  constructionMode: 'Construction Mode',
  type: 'Type',
  maximumStudents: 'Số học viên tối đa',
  lecturer: 'Teacher',
  mentor: 'Staff',
  duration: 'Duration',
  course: 'Course',
  exam: 'Exam',
  describe: 'Describe',
  reason: 'Block Reason',
  updateClass: 'Edit Class',
}

export const OPTIONS_OF_STATUS = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Public',
    value: 'PUBLIC',
  },
  {
    label: 'Blocked',
    value: 'BLOCKED',
  },
]
export const OPTIONS_OF_STATUS_FILTER = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Public',
    value: 'PUBLIC',
  },
  {
    label: 'Blocked',
    value: 'BLOCKED',
  },
  {
    label: 'Ended',
    value: 'ENDED',
  },
]

export const OPTIONS_OF_CONTRUCTION_MODE = [
  {
    label: 'Online',
    value: 'ONLINE',
  },
  {
    label: 'Offline',
    value: 'OFFLINE',
  },
  {
    label: 'Blended',
    value: 'BLENDED',
  },
]

export const OPTIONS_OF_TYPE_CLASS = [
  {
    label: 'Trial',
    value: 'TRIAL',
  },
  {
    label: 'Lesson',
    value: 'LESSON',
  },
  {
    label: 'Revision',
    value: 'REVISION',
  },
]

export const OPTIONS_OF_DURATION = {
  labelFixed: 'Fixed',
  valueFixed: 'FIXED',
  labelFlexible: 'Flexible',
  valueFlexible: 'FLEXIBLE',
}

export const TITLE_USER_STATUS = {
  NORMAL: 'NORMAL',
  RESERVED: 'RESERVED',
  MOVED_OUT: 'MOVED_OUT',
  MOVED_IN: 'MOVED_IN',
  TRANSFER_TO: 'TRANSFER_TO',
  TRANSFERRED: 'TRANSFERRED',
  CANCELED: 'CANCELED',
}

export const CONTENT_USER_STATUS = {
  NORMAL: 'Bình thường',
  RESERVED: 'Bảo lưu',
  MOVED_OUT: 'Chuyển đi',
  MOVED_IN: 'Chuyển đến',
  TRANSFER_TO: 'Chuyển nhượng cho người khác',
  TRANSFERRED: 'Được chuyển nhượng',
  CANCELED: 'Hủy học',
}

export const categoryCourseType = ['CFA, ACCA', 'CMA']

export const OPTIONS_OF_TYPE_CLASS_LIST = [
  {
    label: 'Trial',
    value: 'TRIAL',
  },
  {
    label: 'Lesson',
    value: 'LESSON',
  },
  {
    label: 'Revision',
    value: 'REVISION',
  },
  {
    label: 'Foundation',
    value: 'FOUNDATION',
  },
]

export const FILTER_SELECT_SORTBY_EXAM = [
  // {
  //   label: 'All',
  //   value: 'all',
  // },
  {
    label: 'Latest',
    value: 'LATEST',
  },
  {
    label: 'Oldest',
    value: 'OLDEST',
  },
]

export const CONFIRM_CHANGE_COURE =
  'Changing Course will reset the teacher assignment settings. Do you want to save the change?'
export const VALIDATE_LECTURE = 'Teacher assignment is required'

export const OPTIONS_SUBJECTS = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
]

export const programOrder = ['CFA', 'CMA', 'ACCA', 'Cert/Dip']

const cfaSectionsCode = ['Quan', 'Econ', 'FSA', 'CI', 'Equity', 'FI', 'Der', 'AI', 'PM', 'Ethics']
const accaSubjectOrder = Array.from({ length: 9 }, (_, i) => `F${i + 1}`).concat(
  'MA1',
  'MA2',
  'FA1',
  'FA2',
  'PFE',
  'SBL',
  'SBR',
  'APM',
  'AFM'
)
const certDipSubjectOrder = ['CertIFR', 'DipIFR']

export const subjectOrder = [
  'CFA-Level-1',
  'CFA-Level-2',
  'CMA-Part-1',
  'CMA-Part-2',
  ...accaSubjectOrder,
  ...certDipSubjectOrder,
]

export const cmaSectionOrder = Array.from(
  { length: 2 },
  (_, i) => Array.from({ length: 6 }, (_, j) => `${i + 1}${String.fromCharCode(65 + j)}`) // 'A' is ASCII 65
).flat()

export const sectionOrder = [
  ...Array.from(cfaSectionsCode, (item) => `${item} Lv1`),
  ...Array.from(cfaSectionsCode, (item) => `${item} Lv2`),
  ...cmaSectionOrder,
  ...accaSubjectOrder,
]
