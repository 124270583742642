import { useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import { ClassesApi } from 'src/apis/classes'
import withAuthComponents from 'src/components/auth/with-auth-components'
import Card from 'src/components/classes/profile/Card'
import RenderClassProfile from 'src/components/classes/profile/RenderClassProfile'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { CLASS_PROFILE, CLASS_PROFILE_URL, TITLE_OPTIONS_CLASS } from 'src/constants/classes'
import { ITabs } from 'src/type'
import './ClassDetail.scss'

const ClassDetail = () => {
  const { id } = useParams()

  const breadcrumbs: ITabs[] = [
    {
      link: `${PageLink.DASHBOARD}`,
      title: 'LMS',
    },
    {
      link: `${PageLink.CLASSES}`,
      title: `${TITLE_OPTIONS_CLASS.listClasses}`,
    },
    {
      link: '',
      title: `${TITLE_OPTIONS_CLASS.classDetail}`,
    },
  ]

  // Config Tabs
  const tabs: ITabs[] = [
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.OVERVIEW}`,
      title: 'Overview',
    },
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.SETTING}`,
      title: 'Settings',
    },
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.STUDENTS}`,
      title: 'Students',
    },
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.MENTORS}`,
      title: 'Mentors',
    },
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.TEACHERS}`,
      title: 'Teachers',
    },
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.EXAMINATION}`,
      title: 'Examinations',
    },
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.MARK}`,
      title: 'Marks',
    },
    {
      link: `${CLASS_PROFILE}/${id}/${CLASS_PROFILE_URL.TESTQUIZ}`,
      title: 'Test/Quiz',
    },
  ]

  const {
    data: classDetail,
    isLoading,
    error,
  } = useQuery(['classDetail', id], async () => {
    const res = await ClassesApi.detail(id)
    return res?.data
  })

  return (
    <PageLayouts
      pageTitle='Class Detail'
      breadcrumbs={breadcrumbs}
      classNameLayout='sapp-overflow-y-layout'
    >
      <Card classDetail={classDetail} tabs={tabs} loading={isLoading} />
      <RenderClassProfile classDetail={classDetail} loading={isLoading} />
    </PageLayouts>
  )
}

export default withAuthComponents(ClassDetail)
