import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { MY_PROFILE, STUDENT_PROFILE } from 'src/constants'
import ClassUserManagement from '../class-user-management'
import StudentCertificate from './student-certificate'
import OverviewStudentProfile from './student-overview'
import SettingStudent from './student-setting'
import StudentSecurity from './student-security/StudentSecurity'
import { useEffect } from 'react'

const RenderStudentProfile = () => {
  const location = useLocation()
  const { id } = useParams()
  const navigate = useNavigate()

  const renderProfile = () => {
    const pathSegment = location.pathname.split(`/${id}/`)[1]

    switch (true) {
      case pathSegment?.startsWith(MY_PROFILE.OVERVIEW):
        return <OverviewStudentProfile />
      case pathSegment?.startsWith(MY_PROFILE.SETTING):
        return <SettingStudent />
      case pathSegment === MY_PROFILE.CLASS:
        return <ClassUserManagement id={id!} />
      case pathSegment === MY_PROFILE.SECURITY:
        return <StudentSecurity />
      case pathSegment === MY_PROFILE.CERTIFICATE:
        return <StudentCertificate />
      default:
        return <OverviewStudentProfile />
    }
  }

  useEffect(() => {
    const paths: Record<string, string> = {
      [`${STUDENT_PROFILE}/${id}/overview`]: `${STUDENT_PROFILE}/${id}/overview/general`,
      [`${STUDENT_PROFILE}/${id}`]: `${STUDENT_PROFILE}/${id}/overview/general`,
      [`${STUDENT_PROFILE}/${id}/setting`]: `${STUDENT_PROFILE}/${id}/setting/general`,
    }

    const targetPath = paths[location.pathname]
    if (targetPath) navigate(targetPath)
  }, [location])

  return <>{renderProfile()}</>
}

export default RenderStudentProfile
