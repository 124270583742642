import { VALIDATION_FIELD } from 'src/constants'
import { DEPARTMENT_TYPES } from 'src/type/department/enum'
import { z } from 'zod'

export const schema = (type?: DEPARTMENT_TYPES) =>
  z.object({
    name: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    short_name: z.string().optional().nullable(),
    type: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    parent_id:
      type === DEPARTMENT_TYPES.BOARD
        ? z.string({ message: VALIDATION_FIELD }).optional().nullable()
        : z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    unit: z.string().array().nonempty({ message: VALIDATION_FIELD }).max(2),
    description: z.string().optional().nullable(),
  })
