import { PageLink } from '.'
import {
  TITLE_CERTIFICATE_GR,
  TITLE_CLASS_GR,
  TITLE_COURSE_GR,
  TITLE_EVENT_TEST_GR,
  TITLE_EXAM_GR,
  TITLE_GR,
  TITLE_GROUPS_GR,
  TITLE_LOGS_GR,
  TITLE_MARKETING_GR,
  TITLE_NOTIFICATIONS_GR,
  TITLE_QUESTIONS_GR,
  TITLE_QUIZ_GR,
  TITLE_RESOURCES_GR,
  TITLE_SECTION_QUIZ_GR,
  TITLE_STAFF_GR,
  TITLE_STORY_GR,
  TITLE_SUPPORTER_GROUP_GR,
  TITLE_TOPIC_GR,
} from './permission'

export const permissionsToCheck = {
  allowRenderClassList: TITLE_CLASS_GR.VIEW_LIST_CLASS,
  allowRenderClassCreate: TITLE_CLASS_GR.CREATE_CLASS,
  allowRenderClassEdit: TITLE_CLASS_GR.EDIT_CLASS,
  allowRenderReportClass: TITLE_CLASS_GR.IMPORT_CLASS,
  allowRenderRemoveStudentInClass: TITLE_CLASS_GR.REMOVE_STUDENT_IN_CLASS,
  allowRenderCreateStudentInClass: TITLE_CLASS_GR.CREATE_STUDENT_IN_CLASS,
  allowRenderImportClass: TITLE_CLASS_GR.IMPORT_CLASS,
  allowRenderEditDuration: TITLE_CLASS_GR.EDIT_DURATION,
  allowRenderReportStudent: TITLE_CLASS_GR.IMPORT_STUDENT_IN_CLASS,
  allowRenderEditCourseContent: TITLE_CLASS_GR.EDIT_COURSE_CONTENT_CLASS,
  allowRenderEditCourseContentOfStudent: TITLE_CLASS_GR.EDIT_COURSE_CONTENT_OF_STUDENT_IN_CLASS,

  allowRenderListCourse: TITLE_COURSE_GR.VIEW_COURSE,
  allowRenderCreateCourse: TITLE_COURSE_GR.CREATE_COURSE,
  allowRenderEditCourse: TITLE_COURSE_GR.EDIT_COURSE,

  allowRenderTopicList: TITLE_TOPIC_GR.VIEW_QUESTION_TOPIC,
  allowRenderCreateTopic: TITLE_TOPIC_GR.CREATE_QUESTION_TOPIC,
  allowRenderEditTopic: TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC,
  allowRenderRemoveTopic: TITLE_TOPIC_GR.REMOVE_QUESTION_TOPIC,

  allowRenderCaseStudyList: TITLE_STORY_GR.VIEW_LIST_STORY,
  allowRenderCreateCaseStudy: TITLE_STORY_GR.CREATE_STORY,
  allowRenderRemoveCaseStudy: TITLE_STORY_GR.REMOVE_STORY,
  allowRenderEditCaseStudy: TITLE_STORY_GR.EDIT_STORY,

  allowRenderEntranceTest: TITLE_QUIZ_GR.VIEW_LIST_QUIZ,
  allowRenderCreateEntranceTest: TITLE_QUIZ_GR.CREATE_QUIZ,
  allowRenderEditEntranceTest: TITLE_QUIZ_GR.EDIT_QUIZ,
  allowRenderRemoveEntranceTest: TITLE_QUIZ_GR.REMOVE_QUIZ,
  allowRenderParticipantList: TITLE_QUIZ_GR.VIEW_PARTICIPANT_LIST,
  allowRenderPublicScoreDetail: TITLE_QUIZ_GR.PUBLIC_SCORE_DETAIL,

  allowRenderGetSectionQuiz: TITLE_SECTION_QUIZ_GR.GET_SECTION_QUIZ,
  allowRenderCreateSectionQuiz: TITLE_SECTION_QUIZ_GR.CREATE_SECTION_QUIZ,
  allowRenderEditSectionQuiz: TITLE_SECTION_QUIZ_GR.EDIT_SECTION_QUIZ,
  allowRenderRemoveSectionQuiz: TITLE_SECTION_QUIZ_GR.REMOVE_SECTION_QUIZ,

  allowRenderResourcesList: TITLE_RESOURCES_GR.GET_LIST_RESOURCES,
  allowRenderDownloadResources: TITLE_RESOURCES_GR.DOWNLOAD_RESOURCES,
  allowRenderViewUpload: TITLE_RESOURCES_GR.VIEW_UPLOAD_FOLDER_RESOURCES,
  allowRenderCreateResources: TITLE_RESOURCES_GR.CREATE_RESOURCES,
  allowRenderUpdateResources: TITLE_RESOURCES_GR.UPDATE_RESOURCES,
  allowRenderRemoveResources: TITLE_RESOURCES_GR.REMOVE_RESOURCES,

  allowRenderCreateNotification: TITLE_NOTIFICATIONS_GR.CREATE_NOTIFICATION,
  allowRenderNotificationList: TITLE_NOTIFICATIONS_GR.VIEW_LIST,
  allowRenderEditNotification: TITLE_NOTIFICATIONS_GR.EDIT_NOTIFICATION,
  allowRenderMailLog: TITLE_NOTIFICATIONS_GR.VIEW_MAIL_LOG,

  allowRenderGroupList: TITLE_GROUPS_GR.VIEW_GROUP,
  allowRenderCreateGroup: TITLE_GROUPS_GR.CREATE_GROUP,
  allowRenderEditGroup: TITLE_GROUPS_GR.EDIT_GROUP,
  allowRenderRemoveGroup: TITLE_GROUPS_GR.REMOVE_GROUP,

  allowRenderStaffList: TITLE_STAFF_GR.GET_STAFF,
  allowRenderCreateStaffs: TITLE_STAFF_GR.CREATE_STAFF,
  allowRenderEditStaffs: TITLE_STAFF_GR.EDIT_STAFF,
  allowRenderChangeEmailStaffs: TITLE_STAFF_GR.CHANGE_EMAIL_STAFF,
  allowRenderResetPasswordStaffs: TITLE_STAFF_GR.RESET_PASSWORD_STAFF,
  allowRenderExportStaffs: TITLE_STAFF_GR.GET_EXPORT_STAFF,
  allowRenderImportStaffs: TITLE_STAFF_GR.CREATE_STAFF,
  allowRenderAssignPermissionStaff: TITLE_STAFF_GR.GET_ASSIGN_PERMISSION_STAFF,

  allowRenderCertificate: TITLE_CERTIFICATE_GR.VIEW_CERTIFICATE,
  allowRenderCreateCertificate: TITLE_CERTIFICATE_GR.CREATE_CERTIFICATE,
  allowRenderRemoveCertificate: TITLE_CERTIFICATE_GR.REMOVE_CERTIFICATE,
  allowRenderDuplicateCertificate: TITLE_CERTIFICATE_GR.DUPLICATE_CERTIFICATE,
  allowRenderEditCertificate: TITLE_CERTIFICATE_GR.EDIT_CERTIFICATE,

  allowRender: TITLE_GR.VIEW_USER_GR,
  allowRenderCreate: TITLE_GR.CREATE_USER_GR,
  allowRenderRemove: TITLE_GR.REMOVE_USER_GR,
  allowRenderImport: TITLE_GR.CREATE_USER_GR,
  allowRenderEdit: TITLE_GR.EDIT_USER_GR,
  allowRenderGetDevices: TITLE_GR.GET_SECURITY_USER_GR,
  allowRenderRemoveDevices: TITLE_GR.REMOVE_DEVICE_USER_GR,
  allowRenderSecurityDevices: TITLE_GR.REMOVE_SECURITY_USER_GR,
  allowRenderMakeContactDF: TITLE_GR.MAKE_CONTACT_DEFAULT_GR,
  allowRenderGetActivities: TITLE_GR.GET_ACTIVITIES_USER_GR,
  allowRenderChangeEmail: TITLE_GR.PUT_CHANGE_EMAIL_USER_GR,
  allowRenderResetPassword: TITLE_GR.PUT_RESET_PASSWORD_USER_GR,
  allowRenderGetExportData: TITLE_GR.GET_EXPORT_DATA_USER_GR,

  allowRenderImportReport: TITLE_LOGS_GR.VIEW_LIST_IMPORT_LOGS,
  allowRenderImportLogs: TITLE_LOGS_GR.VIEW_LIST_IMPORT_LOGS,

  allowRenderCheckDuplicateContact: TITLE_MARKETING_GR.CHECK_DUPLICATE_CONTACT,

  allowRenderQuestion: TITLE_QUESTIONS_GR.VIEW_QUESTION,
  allowRenderCreateQuestion: TITLE_QUESTIONS_GR.CREATE_QUESTION,
  allowRenderEditQuestion: TITLE_QUESTIONS_GR.EDIT_QUESTION,
  allowRenderRemoveQuestion: TITLE_QUESTIONS_GR.REMOVE_QUESTION,

  allowRenderGetExam: TITLE_EXAM_GR.GET_EXAMS,
  allowRenderCreateExam: TITLE_EXAM_GR.CREATE_EXAM,
  allowRenderEditExam: TITLE_EXAM_GR.EDIT_EXAM,
  allowRenderRemoveExam: TITLE_EXAM_GR.REMOVE_EXAM,

  allowRenderEventTestParticipants: TITLE_EVENT_TEST_GR.GET_EVENT_TEST_PARTICIPANTS,
  allowRenderAddStudentEventTest: TITLE_EVENT_TEST_GR.ADD_STUDENT_EVENT_TEST,
  allowRenderRemoveStudentEventTest: TITLE_EVENT_TEST_GR.REMOVE_STUDENT_EVENT_TEST,
  allowRenderGetEventTest: TITLE_EVENT_TEST_GR.GET_EVENT_TEST,
  allowRenderCreateEventTest: TITLE_EVENT_TEST_GR.CREATE_EVENT_TEST,
  allowReanderEditEventTest: TITLE_EVENT_TEST_GR.EDIT_EVENT_TEST,

  allowRenderGetSupporterGroup: TITLE_SUPPORTER_GROUP_GR.GET_SUPPORTER_GROUPS,
  allowRenderCreateSupporterGroup: TITLE_SUPPORTER_GROUP_GR.CREATE_SUPPORTER_GROUP,
  allowRenderEditSupporterGroup: TITLE_SUPPORTER_GROUP_GR.EDIT_SUPPORTER_GROUP,
  allowRenderRemoveSupporterGroup: TITLE_SUPPORTER_GROUP_GR.REMOVE_SUPPORTER_GROUP,
} // Title trên side bar dùng để phân quyền

export const staffLinks = [
  PageLink.STAFFS,
  PageLink.CREATE_STAFF,
  PageLink.IMPORT_LOG + '/STAFF',
  PageLink.IMPORT_LOG + '/detail/STAFF',
]

export const studentLinks = [
  PageLink.STUDENTS,
  PageLink.CREATE_STUDENTS,
  PageLink.IMPORT_LOG + '/STUDENT',
  PageLink.IMPORT_LOG + '/detail/STUDENT',
]

export const teacherLinks = [
  PageLink.TEACHERS,
  PageLink.CREATE_TEACHERS,
  PageLink.IMPORT_LOG + '/TEACHER',
  PageLink.IMPORT_LOG + '/detail/TEACHER',
]
