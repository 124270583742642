import React, { Dispatch, SetStateAction, useEffect } from 'react'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import SappTable from 'src/components/base/SappTable'
import { KTCardBody } from 'src/_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { useForm } from 'react-hook-form'
import { FAQ_STATUS, FORMAT_DATETIME, PageLink } from 'src/constants'
import { Select } from 'antd'
import SappTooltip from 'src/common/SappTooltip'
import { IFAQItem, IFAQList } from 'src/type/shop/faqs'
import { INewOrUpdatePopup } from 'src/type/shop/common'
import { format } from 'date-fns'
import toast from 'react-hot-toast'
import { FaqsAPI } from 'src/apis/short-course/faqs'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import ShopActionBox from '../shared/ShopActionBox'
import { useConfirm } from 'src/hooks/use-confirm'
const { Option } = Select

// define headers
const headers = [
  {
    label: 'name',
    className: 'min-w-175px',
  },
  {
    label: 'Category',
    className: 'min-w-175px text-center',
  },
  {
    label: 'Status',
    className: 'min-w-75px',
  },
  {
    label: 'Last Updated',
    className: 'min-w-150px text-left',
  },
  {
    label: '',
    className: 'min-w-30px',
  },
]

interface IQueryPrams {
  title: string | null
  category_id: string | null
  status: string | null
  sortType: string | null
  fromDate: Date | string
  toDate: Date | string
}

interface IProps {
  faqList: IFAQList | undefined
  setFaqList: Dispatch<SetStateAction<any>>
  cleanedParams: Object
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  checkedList: Array<string>
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, isTotal?: boolean) => void
  isCheckedAll: boolean
  loadData: (page_index: number, page_size: number, object?: Object) => void
  queryParam: IQueryPrams
  getParams: any
  reloadPage: () => void
  setOpenNewOrUpdate: Dispatch<SetStateAction<INewOrUpdatePopup>>
}

const TableFAQs = ({
  faqList,
  setFaqList,
  cleanedParams,
  loading,
  setLoading,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  loadData,
  queryParam,
  getParams,
  reloadPage,
  setOpenNewOrUpdate,
}: IProps) => {
  const { control, setValue } = useForm()
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()

  const showQueryParams =
    queryParam.title ||
    queryParam.category_id ||
    queryParam.status ||
    queryParam.sortType ||
    queryParam.fromDate ||
    queryParam.toDate

  /**
   * Một hàm dùng để change params trên router
   */
  const filterParamsPagination = () => {
    const dateQueryFromDate =
      queryParam?.fromDate instanceof Date ? getDateInfo(queryParam.fromDate) : null
    const dateQueryToDate =
      queryParam?.toDate instanceof Date ? getDateInfo(queryParam.toDate) : null
    return cleanParamsAPI(
      getParams(
        queryParam.title ?? '',
        queryParam.category_id ?? '',
        queryParam.status ?? '',
        queryParam.sortType ?? '',
        queryParam.fromDate && dateQueryFromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        queryParam.toDate && dateQueryToDate
          ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
          : '',
        showQueryParams ? 'updated_at' : 'created_at'
      )
    )
  }

  /**
   * Call API get Categories
   */
  useEffect(() => {
    setLoading(true)
    loadData(1, 10, cleanedParams)
  }, [])

  /**
   * Một hàm dùng để đổi params trên router
   * @param {number} currentPage  - trang page hiện tại
   * @param {number} size  - lượng bản ghi của page
   */
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParams = {
      page_index: currenPage,
      page_size: size,
      title: queryParam.title ?? '',
      category_id: queryParam.category_id ?? '',
      status: queryParam.status ?? '',
      sortType: queryParam.sortType ?? '',
      fromDate: queryParam.fromDate instanceof Date ? formatDate(queryParam.fromDate) : '',
      toDate: queryParam.toDate instanceof Date ? formatDate(queryParam.toDate) : '',
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  /**
   * Call API khi change pagination
   * @param {number} page_index  - trang page hiện tại
   * @param {number} page_size  - lượng bản ghi của page
   */
  const handlePaginationChange = (page_index: number, page_size: number) => {
    loadData(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }

  /**
   * Một hàm dùng xoá môt Faq
   * @param {string} id  - id của faq muốn xoá =
   */
  const deleteFaq = async (id: string) => {
    await FaqsAPI.deleteFaq(id)
    toast.success('Faq deleted successfully!')
    reloadPage()
  }

  /**
   * Một hàm để active edit action
   * @param {string} id  - id của faq muốn xoá =
   */
  const handleCreateOrUpdate = async (id?: string) => {
    if (id) {
      navigate(`${PageLink.FAQ_DETAIL}/${id}`)
    } else {
      navigate(`${PageLink.FAQ_DETAIL}/${undefined}`)
    }
    // todo
  }

  /**
   * handle update status of faq
   * @param {IFAQItem} data  - data của faq
   * @param {string} status  - status của faq muốn update
   */
  const handleChangeStatus = (data: IFAQItem, status?: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: async () => {
        setLoading(true)
        try {
          if (!status) return
          setLoading(true)
          await FaqsAPI.editFAQ(data.id, {
            title: data.title,
            shop_category_id: data.shop_faq_category?.id,
            describe: data.describe,
            status: status,
          })
          reloadPage()
        } catch (err) {
          // do nothing
        } finally {
          setLoading(false)
        }
      },
      onClose: () => {
        setValue(`status-${data?.id}`, data.status)
      },
    })
  }

  const handleEdit = (id: string) => {
    navigate(`${PageLink.FAQ_DETAIL}/${id}`)
  }

  return (
    <KTCardBody>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={faqList?.faqs}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {faqList?.faqs?.map((data, index) => {
          const isChecked = checkedList.includes(data.id)
          return (
            <tr key={index}>
              <td>
                <SAPPCheckbox
                  checkTarget='#kt_table_users .form-check-input'
                  checked={isChecked}
                  onChange={() => {
                    toggleCheck(data.id)
                  }}
                />
              </td>
              <td className='text-start'>
                <div onClick={() => handleEdit(data.id)}>
                  <SappTooltip title={data.title} />
                </div>
              </td>
              <td className='text-center'>
                <div className='sapp-text-truncate-2'>{data?.shop_faq_category?.title}</div>
              </td>
              <td className='text-left'>
                <HookFormSelectAntd
                  allowClear={false}
                  size='large'
                  name={`status-${data.id}`}
                  control={control}
                  dropdownStyle={{ maxWidth: 100 }}
                  placeholder='Status'
                  filterOption={true}
                  defaultValue={data.status}
                  onChange={(status: any) => handleChangeStatus(data, status)}
                  options={FAQ_STATUS}
                />
              </td>
              <td className='text-left'>{format(new Date(data?.updated_at), FORMAT_DATETIME)}</td>
              <td className='text-center'>
                <ShopActionBox
                  id={data.id}
                  setLoading={setLoading}
                  reloadPage={reloadPage}
                  onDelete={deleteFaq}
                  onEdit={handleCreateOrUpdate}
                />
              </td>
            </tr>
          )
        })}
      </SappTable>
      <PagiantionSAPP
        currentPage={faqList?.metadata?.page_index || 1}
        pageSize={faqList?.metadata?.page_size || 10}
        totalItems={faqList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export default TableFAQs
