import { useParams } from 'react-router-dom'
import HeaderTab from 'src/components/base/HeaderTab'
import ItemProfile from 'src/components/base/ItemProfile'
import ItemClassProfile from 'src/components/classes/profile/ItemClassProfile'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import { ROOM_TYPE_ENUM } from 'src/constants'
import { ROOM_PROFILE, TITLE_OPTIONS_AREA, TITLE_OPTIONS_ROOM } from 'src/constants/classroom'
import { ROOM_MODE } from 'src/type'
import { IRoom } from 'src/type/area'
import { mappingAddress } from 'src/utils'
interface IProps {
  roomDetail: IRoom | undefined
  loading: boolean
}

const RoomProfileDetail = ({ roomDetail, loading }: IProps) => {
  const { id } = useParams()
  const address = mappingAddress([
    roomDetail?.address,
    roomDetail?.facility?.address,
    roomDetail?.facility?.ward?.name_with_type,
    roomDetail?.facility?.ward?.district?.name_with_type,
    roomDetail?.facility?.ward?.district?.province?.name_with_type,
  ])
  return (
    <>
      {loading ? (
        <LoadingProfileDetail loadingAvatar={false} />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <HeaderTab
            title='Overview'
            titleButton='Edit Classroom'
            link={`${ROOM_PROFILE}/${id}/setting`}
          />
          <div className='card-body p-9'>
            <ItemProfile title={TITLE_OPTIONS_ROOM.name} body={roomDetail?.name} />
            <ItemProfile title={TITLE_OPTIONS_ROOM.code} body={roomDetail?.code} />
            {roomDetail?.mode === ROOM_MODE.PHYSICAL && (
              <>
                <ItemProfile
                  title={TITLE_OPTIONS_ROOM.classroomType}
                  body={roomDetail?.type === ROOM_TYPE_ENUM.STANDARD ? 'Tiêu Chuẩn' : 'Bàn Tròn'}
                />
                <ItemProfile
                  title={TITLE_OPTIONS_ROOM.facility}
                  body={roomDetail?.facility?.name}
                />
                <ItemClassProfile
                  title={TITLE_OPTIONS_AREA.status}
                  status={roomDetail?.status.toLocaleLowerCase()}
                  className='mb-7'
                />
                <ItemProfile title={TITLE_OPTIONS_ROOM.address} body={address} />
                <ItemProfile
                  title={TITLE_OPTIONS_ROOM.capacity}
                  body={roomDetail?.capacity?.toString()}
                />
                <ItemProfile
                  title={TITLE_OPTIONS_ROOM.deferredStudent}
                  body={roomDetail?.deferred_student?.toString()}
                />
                <ItemProfile
                  title={TITLE_OPTIONS_ROOM.newStudent}
                  body={roomDetail?.new_student?.toString()}
                />
              </>
            )}
            {roomDetail?.mode === ROOM_MODE.VIRTUAL && (
              <ItemProfile
                title={TITLE_OPTIONS_ROOM.additionalInformation}
                body={roomDetail?.virtual_metadata}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default RoomProfileDetail
