import { Pagination } from 'antd'
import { multiply } from 'lodash'
import { Dispatch, SetStateAction } from 'react'
import { useLocation } from 'react-router-dom'
import SAPPSelect from 'src/components/base/select/SAPPSelect'
import { pageSizeOptions } from 'src/constants'

interface IProps {
  currentPage: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  pageSize: any
  setPageSize?: Dispatch<SetStateAction<number>>
  totalItems: any
  isAllSelected?: boolean | undefined
  clearSelected?: () => void
  handlePaginationChange?: any
  handleChangeParams?: (currenPage: number, size: number) => void
  pageSizeOptionsDetail?: Array<{ label: string; value: number }>
  showPagination?: number
}

const PagiantionSAPP = ({
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  totalItems,
  clearSelected,
  isAllSelected,
  handlePaginationChange,
  handleChangeParams,
  pageSizeOptionsDetail,
  showPagination = 10,
}: IProps) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const page_index = parseInt(queryParams.get('page_index') as any)
  const page_size = parseInt(queryParams.get('page_size') as any)

  const handleCurrentChange = (page: number) => {
    setCurrentPage && setCurrentPage(page)
    if (isAllSelected) {
      clearSelected && clearSelected()
    }
    handleChangeParams && handleChangeParams(page, page_size || 10)
  }

  const handlePageChange = (size: number) => {
    if (multiply(page_index, size) > totalItems) {
      setCurrentPage && setCurrentPage(1)
    }
    setCurrentPage && setCurrentPage(1)
    setPageSize && setPageSize(size)
    handleChangeParams &&
      handleChangeParams(multiply(page_index, size) > totalItems ? 1 : page_index || 1, size)
  }

  return (
    <>
      {totalItems > showPagination && (
        <div className='d-flex justify-content-md-between justify-content-center mt-5 flex-wrap gap-4 '>
          <label className='d-flex align-items-center fw-bold'>
            <span className='sapp-text--pagination me-3'>Show</span>
            <SAPPSelect
              value={pageSize}
              onChange={
                handlePaginationChange
                  ? (page_size) => handlePaginationChange(1, page_size)
                  : handlePageChange
              }
              size='middle'
              options={pageSizeOptionsDetail || pageSizeOptions}
              className='sapp-select w-75px'
            />
            <span className='ms-3 sapp-text--pagination w-100'>of {totalItems} entries</span>
          </label>
          <Pagination
            total={totalItems}
            current={currentPage}
            pageSize={pageSize}
            onChange={handlePaginationChange ? handlePaginationChange : handleCurrentChange}
            className='sapp-pagination'
          />
        </div>
      )}
    </>
  )
}

export default PagiantionSAPP
