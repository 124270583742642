import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { IconArrowCircle } from 'src/common/icons'
import SAPPTextField from 'src/components/base/textfield/SAPPTextField'
import { DURATION_RESEND_EMAIL } from 'src/constants'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { secondsToMinutesAndSeconds } from 'src/utils'

const UserOTP = ({
  setIsActive,
  otp,
  setOtp,
  currentEmail,
  isActive,
  durationResend,
  setDurationResend,
  open,
}: {
  currentEmail?: string
  onSubmitChangeEmail: () => Promise<void>
  setOpen: Dispatch<SetStateAction<boolean>>
  isActive?: boolean
  setIsActive: Dispatch<SetStateAction<boolean>>
  otp?: string[] | undefined
  setOtp?: any
  open: boolean
  durationResend: number
  setDurationResend: Dispatch<SetStateAction<number>>
}) => {
  const { id } = useParams()
  const { contextHolder } = useConfirm()
  const location = useLocation()
  const { sendEmailOTP, sendEmailOTPUser } = useUserContext()

  const handleInput = (index: number, e: any) => {
    const value = e.target.value
    // Update the OTP array
    const newOtp = [...(otp as string[])]
    newOtp[index] = value
    setOtp(newOtp)

    if (
      e.nativeEvent.inputType === 'deleteContentBackward' ||
      e.nativeEvent.inputType === 'deleteContentForward'
    ) {
      const prevInput = document.getElementById(`otp-input-${index - 1}`)
      if (prevInput) {
        prevInput.focus()
      }
    }

    // Move to the next input if the current one is filled
    if (value) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`)

      if (nextInput) {
        nextInput.focus()
      }
    }
  }

  const handlePaste = (e: any, data?: string) => {
    e.preventDefault()
    const pasted = e.clipboardData
      .getData('text/plain')
      .replace(/[^0-9]/g, '')
      .split('')

    // Update the OTP array
    const newOtp = [...(otp as any)]
    newOtp.splice(0, pasted.length, ...pasted)
    setOtp(newOtp)
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select()
  }

  const handleKeyDown = (index: number, e: any) => {
    const isNumeric = /^[0-9]{1}$/.test(e.key)
    const isSpecialKey = ['Backspace', 'Delete', 'Tab'].includes(e.key)
    const isControlMetaKey = !e.metaKey
    const isControlCtrl = e.ctrlKey && e.key !== 'v'

    if (!isNumeric && !isSpecialKey && isControlMetaKey && isControlCtrl) {
      e.preventDefault()
    }

    if ((e.key === 'Delete' || e.key === 'Backspace') && !e.target.value) {
      const prevInput = document.getElementById(`otp-input-${index - 1}`)
      if (prevInput) {
        prevInput.focus()
      }
    }

    if (e.key === 'Tab' && !e.target.value) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`)
      if (nextInput) {
        nextInput.focus()
      }
    }
  }

  // Resend
  useEffect(() => {
    let interval: any

    if (durationResend > 0 && isActive && open) {
      interval = setInterval(() => {
        setDurationResend((prev) => {
          if (prev - 1 === 0) {
            clearInterval(interval)
            setIsActive(false)
          }
          return prev - 1
        })
      }, 1000)
    }

    // Clear the interval when the component unmounts
    return () => clearInterval(interval)
  }, [isActive, open])

  const onClickResendOTP = async () => {
    setOtp(['', '', '', '', '', ''])
    try {
      if (location.pathname.split('/profile')?.[0] === '/staff') {
        const res = await sendEmailOTP(id)
        if (res.success === true) {
          setIsActive(true)
          setDurationResend(DURATION_RESEND_EMAIL)
        }
      } else {
        const userRes = await sendEmailOTPUser(id)
        if (userRes.success) {
          setIsActive(true)
          setDurationResend(DURATION_RESEND_EMAIL)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      {contextHolder}
      <div>
        <label className='d-flex justify-content-between align-items-center mb-3'>
          <span className={'required fw-bold sapp-fs-14'}>OTP</span>
          {isActive && durationResend > 0 && (
            <div className='otp__resend-duration'>
              {' '}
              {secondsToMinutesAndSeconds(durationResend)}
            </div>
          )}
          {!isActive && (
            <div className='otp__resend-button' onClick={onClickResendOTP}>
              <IconArrowCircle />
              <span>Resend</span>
            </div>
          )}
        </label>
        <div className='d-flex justify-content-between fd-row fv-plugins-icon-container mb-4'>
          {otp?.map((digit, index) => (
            <div className='otp__outer-input' key={index}>
              <SAPPTextField
                id={`otp-input-${index}`}
                type='text'
                maxLength={1}
                value={digit}
                onInput={(e) => handleInput(index, e)}
                onPaste={(e: any) => handlePaste(e)}
                className={`text-center otp__inner-input`}
                onFocus={handleFocus}
                onKeyDown={(e) => handleKeyDown(index, e)}
                otp={{
                  active: true,
                  index: index,
                }}
              />
            </div>
          ))}
        </div>

        <p className='otp__email-guide'>
          Liên hệ với học viên để lấy mã OTP đã được gửi về địa chỉ email{' '}
          <strong>{currentEmail}</strong>
        </p>
      </div>
    </>
  )
}
export default UserOTP
