import { UseFormReturn } from 'react-hook-form'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { OPTIONS_OF_CONTRUCTION_MODE } from 'src/constants/classes'
import { Col, Row, Select } from 'antd'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import { ACTION_STATUS } from '../products/CreateAndUpdateSetting'
import { useEffect } from 'react'
const { Option } = Select

const InformationContent = ({ useFormProp }: { useFormProp: UseFormReturn<any> }) => {
  const { control, resetField, watch } = useFormProp
  const isCta = watch('cta')
  const isCharge = watch('is_charge')

  useEffect(() => {
    if (isCta === 'false') {
      resetField('cta_title')
      resetField('cta_url')
    }
    if (isCharge === 'false') {
      resetField('price_number')
      resetField('total_seat_number')
    }
  }, [isCharge, isCta])

  return (
    <div className='card card-flush'>
      <div className='card-body pt-10'>
        <SappLabel label='Information' isLabel />
        <div>
          <SappLabel label='Duration' required />
        </div>
        <div className='row row-cols-2 g-8 sapp-mb-24px'>
          <div className='col fv-row fv-plugins-icon-container '>
            <div className='d-flex'>
              <div className='w-100'>
                <HookFormDateTime
                  control={control}
                  required
                  name='start_time'
                  placeholder='Start'
                />
              </div>
            </div>
          </div>

          <div className='col fv-row fv-plugins-icon-container '>
            <div className='d-md-flex'>
              <div className='w-100'>
                <HookFormDateTime control={control} required name='end_time' placeholder='End' />
              </div>
            </div>
          </div>
        </div>
        <div className='sapp-mb-24px'>
          <HookFormSelectAntd
            size='large'
            name='construction_mode'
            control={control}
            required
            className='sapp-h-45px'
            label='Construction Mode'
            options={OPTIONS_OF_CONTRUCTION_MODE}
          />
        </div>
        <div>
          <HookFormTextField
            control={control}
            name='address'
            required
            className='sapp-h-45px'
            label='Address'
          />
        </div>
        <div className='mb-10 fv-row mt-5'>
          <SappLabel label='CTA' required />
          <Row>
            <Col className='align-self-center'>
              <HookFormRadioGroup
                control={control}
                name='cta'
                options={ACTION_STATUS}
                direction='horizontal'
                separator={false}
                justify='start'
                itemWidth={'150px'}
                className='sapp-checkbox--icon'
              />
            </Col>
          </Row>
        </div>

        <div className='mb-10 fv-row'>
          <HookFormTextField
            control={control}
            name='cta_title'
            label='CTA Title'
            disabled={isCta !== 'true'}
            required={isCta === 'true'}
            className='sapp-h-45px'
          />
        </div>

        <div className='mb-10 fv-row'>
          <HookFormTextField
            control={control}
            name='cta_url'
            label='CTA Link'
            disabled={isCta !== 'true'}
            required={isCta === 'true'}
            className='sapp-h-45px'
          />
        </div>

        <div className='mb-10 fv-row mt-5'>
          <SappLabel label='Tính Phí' required />
          <Row>
            <Col className='align-self-center'>
              {/* <div className='d-flex gap-20'> */}
              <HookFormRadioGroup
                control={control}
                name='is_charge'
                options={ACTION_STATUS}
                direction='horizontal'
                separator={false}
                justify='start'
                itemWidth={'150px'}
                className='sapp-checkbox--icon'
              />
            </Col>
          </Row>
        </div>

        <div className='mb-10 fv-row'>
          <HookFormTextField
            type='number'
            control={control}
            name='price_number'
            label='Price'
            disabled={isCharge !== 'true'}
            required={isCharge === 'true'}
            className='sapp-h-45px'
            minNumber={1}
          />
        </div>

        <div className='mb-10 fv-row'>
          <HookFormTextField
            type='number'
            control={control}
            name='total_seat_number'
            disabled={isCharge !== 'true'}
            required={isCharge === 'true'}
            label='Total Seat'
            className='sapp-h-45px'
            minNumber={1}
          />
        </div>
      </div>
    </div>
  )
}

export default InformationContent
