import { DebouncedFunc, debounce } from 'lodash'
import { useMemo, useState } from 'react'
import { CoursesAPI } from 'src/apis/courses'
import { ICourse } from 'src/type/courses'
export interface IMetadata {
  total_pages: number
  total_records: number
  page_index: number
  page_size: number
}

export interface ISearchCourse {
  courseCategory: {
    loading: boolean
    data: ICourse[]
    id?: string
    search?: string
    metadata?: IMetadata
  }
  course: {
    loading: boolean
    data: ICourse[]
    id?: string
    search?: string
    metadata?: IMetadata
  }
  part: {
    loading: boolean
    data: ICourse[]
    id?: string
    search?: string
    metadata?: IMetadata
  }
  chapter: {
    loading: boolean
    data: ICourse[]
    id?: string
    search?: string
    metadata?: IMetadata
  }
  unit: {
    loading: boolean
    data: ICourse[]
    id?: string
    search?: string
    metadata?: IMetadata
  }
  activity: {
    loading: boolean
    data: ICourse[]
    id?: string
    search?: string
    metadata?: IMetadata
  }
}

export interface IUseCourseFilterResult {
  listCourse: ISearchCourse
  setListCourse: React.Dispatch<React.SetStateAction<ISearchCourse>>
  getListSort: ({
    type,
    parentType,
    name,
    parentId,
    page_index,
    page_size,
    isSelect,
  }: {
    parentType: keyof ISearchCourse
    type: keyof ISearchCourse
    name?: string | undefined
    parentId?: string | undefined
    page_index?: number | undefined
    page_size?: number | undefined
    isSelect?: boolean | undefined
  }) => Promise<void>
  debouncedGetListSort: DebouncedFunc<
    ({
      type,
      parentType,
      name,
      parentId,
    }: {
      parentType: keyof ISearchCourse
      type: keyof ISearchCourse
      name?: string | undefined
      parentId?: string | undefined
    }) => Promise<void>
  >
  getCoursesCategories: (name?: string) => Promise<void>
  debounceGetCoursesCategories: DebouncedFunc<(name?: string) => Promise<void>>
  getCourses: (
    name?: string,
    course_category_ids?: string,
    page_index?: number,
    page_size?: number,
    course_id?: string,
    seedCourses?: any[]
  ) => Promise<void>

  debouncedGetCourses: DebouncedFunc<(name?: string, course_category_ids?: string) => Promise<void>>
  clearChildren: (type: keyof ISearchCourse, reset?: boolean, parentId?: any) => ISearchCourse
  defaultSearchCourse: ISearchCourse
  handleNextPage: DebouncedFunc<(course_id?: string) => void>
  handleNextListSort: DebouncedFunc<
    (type: keyof ISearchCourse, parentType: keyof ISearchCourse) => void
  >
}
const defaultSearchCourse = {
  courseCategory: {
    loading: false,
    data: [],
  },
  course: { loading: false, data: [] },
  part: { loading: false, data: [] },
  chapter: { loading: false, data: [] },
  unit: { loading: false, data: [] },
  activity: { loading: false, data: [] },
}
const children = {
  courseCategory: ['course', 'part', 'chapter', 'unit', 'activity'],
  course: ['part', 'chapter', 'unit', 'activity'],
  part: ['chapter', 'unit', 'activity'],
  chapter: ['unit', 'activity'],
  unit: ['activity'],
}

const parent: { [key: string]: keyof typeof defaultSearchCourse } = {
  activity: 'unit',
  unit: 'chapter',
  chapter: 'part',
  part: 'course',
  course: 'courseCategory',
}

const useCourseFilter = (setValue?: any, handleFilter?: any): IUseCourseFilterResult => {
  const [listCourse, setListCourse] = useState<ISearchCourse>(defaultSearchCourse)

  /**
   *  @description Lấy danh sách course cho select box Course
   *
   * @param {string} [name]
   */
  const getCoursesCategories = async (name?: string) => {
    try {
      setListCourse((e) => ({
        ...e,
        courseCategory: {
          ...e.courseCategory,
          loading: true,
        },
      }))
      const response = await CoursesAPI.getCategory({
        page_index: 1,
        page_size: 10,
        params: { name },
      })
      setListCourse((e) => ({
        ...e,
        courseCategory: {
          loading: false,
          data: response?.data?.course_categories || [],
        },
      }))
    } catch (error) {
      setListCourse((e) => ({
        ...e,
        courseCategory: {
          ...e.courseCategory,
          loading: false,
        },
      }))
    }
  }
  const debounceGetCoursesCategories = debounce(getCoursesCategories, 500)

  /**
   *  @description Lấy danh sách course cho select box Course
   *
   * @param {string} [name]
   * @param {string} [course_category_ids]
   * @param {number} [page_index]
   * @param {number} [page_size]
   */
  const getCourses = async (
    name?: string,
    course_category_ids?: string,
    page_index: number = 1,
    page_size: number = 20,
    course_id?: string,
    seedCourses?: any[]
  ) => {
    try {
      setListCourse((e) => ({
        ...e,
        course: {
          ...e.course,
          loading: true,
        },
      }))
      const response = await CoursesAPI.get({
        page_index,
        page_size,
        params: {
          name,
          ...(course_id && { course_id: course_id }),
          ...(course_category_ids && { course_category_ids: [course_category_ids] }),
        },
      })

      const insert_courses = seedCourses || []
      setListCourse((e) => {
        return {
          ...e,
          courseCategory: {
            ...e.courseCategory,
            id: course_category_ids,
          },
          course: {
            loading: false,
            data:
              (name !== e.course.search ? [] : e.course.data).concat(
                insert_courses,
                response?.data?.courses
              ) || [],
            metadata: response.data?.metadata,
            search: name,
          },
        }
      })
    } catch (error) {
      setListCourse((e) => ({
        ...e,
        course: {
          ...e.course,
          loading: false,
        },
      }))
    }
  }
  const debouncedGetCourses = debounce(getCourses, 500)

  /**
   * @description Lấy danh sách cho select box part, chapter, unit, activity khi auto complete
   * hoặc select vào select box cấp cao hơn
   *
   * @param {{
   *     parentType: keyof ISearchCourse
   *     type: keyof ISearchCourse
   *     name?: string
   *     parentId?: string
   *     page_index?: number
   *     page_size?: number
   *   }} {
   *     type,
   *     parentType,
   *     name,
   *     parentId,
   *     page_index,
   *     page_size,
   *   }
   */
  const getListSort = async ({
    type,
    parentType,
    name,
    parentId,
    page_index = 1,
    page_size = 20,
    isSelect = false,
  }: {
    parentType: keyof ISearchCourse
    type: keyof ISearchCourse
    name?: string
    parentId?: string
    page_index?: number
    page_size?: number
    isSelect?: boolean
  }) => {
    const newListCourse = clearChildren(isSelect ? parentType : type, false)

    let dataListName = ''
    if (type !== 'course') {
      dataListName = 'sections'
    }
    setListCourse(() => ({
      ...newListCourse,
      [parentType]: {
        ...newListCourse[parentType],
        id: parentId || newListCourse[parentType].id,
      },
      [type]: {
        ...newListCourse[type],
        loading: true,
      },
    }))
    try {
      if (parentId) {
        const response = await CoursesAPI.getListSort({
          name,
          parentId: type !== 'part' ? parentId : undefined,
          courseId: type === 'part' ? parentId : undefined,
          page_index: page_index || 1,
          page_size: page_size || 10,
          type: type.toUpperCase(),
        })
        setListCourse((e) => {
          return {
            ...e,
            [type]: {
              loading: false,
              data:
                ((name || '') !== (e?.[type]?.search || '') ? [] : e?.[type]?.data || [])?.concat(
                  response?.data?.[dataListName]
                ) || [],
              metadata: response?.data?.meta,
              search: name,
            },
          }
        })
      }
    } catch (error) {
      setListCourse(() => ({
        ...newListCourse,
        [type]: {
          ...newListCourse[type],
          loading: false,
        },
      }))
    }
  }

  // Sử dụng debouncedGetListSort trong useCourseFilterResult
  const debouncedGetListSort = debounce(getListSort, 500)

  /**
   * @description Reset lại part, chapter, unit, activity khi select ở cấp cao hơn
   *
   * @param {keyof ISearchCourse} type
   * @return {*}  {ISearchCourse}
   */
  const clearChildren = (
    type: keyof ISearchCourse,
    reset: boolean = true,
    parentId: string = ''
  ): ISearchCourse => {
    const key = type as keyof typeof children
    let newListCourse = { ...listCourse }

    if (type !== 'activity') {
      for (let child of children[key]) {
        const newSetValue = setValue as any
        newSetValue(child, '')
        newListCourse = {
          ...newListCourse,
          [child]: {
            loading: false,
            data: [],
            id: undefined,
            metadata: undefined,
            search: '',
          },
        }
      }
    }

    if (reset) {
      if (listCourse?.activity?.id && type !== 'activity') {
        !!handleFilter && handleFilter({ course_section_id: null })
      }

      if (type === 'course') {
        newListCourse.course = {
          loading: false,
          data: [],
        }
        getCourses()
      } else {
        newListCourse[type] = {
          loading: false,
          data: [],
          metadata: undefined,
          search: '',
        }
        getListSort({
          name: '',
          parentType: parent[type],
          type,
          parentId: parentId ? parentId : listCourse?.[parent[type]]?.id,
        })
      }
    }
    return newListCourse
  }

  const handleNextPage = (course_id?: string) => {
    const total_pages = listCourse.course?.metadata?.total_pages
    const page_index = listCourse.course?.metadata?.page_index
    const page_size = listCourse.course?.metadata?.page_size
    if (total_pages) {
      if (page_index && page_index < total_pages) {
        getCourses(listCourse.course.search, undefined, page_index + 1, page_size, course_id)
      }
    }
  }
  const debouncedHandleNextPage = debounce(handleNextPage, 500)

  /**
   * Xử lý việc lấy danh sách các mục theo trang cho khóa học.
   *
   * @param {keyof ISearchCourse} type - Loại mục cần lấy (course, part, chapter, etc.).
   * @param {keyof ISearchCourse} parentType - Loại mục cấp cao hơn (courseCategory, course, part, chapter, unit, activity).
   */
  const handleNextListSort = (type: keyof ISearchCourse, parentType: keyof ISearchCourse) => {
    const total_pages = listCourse[type]?.metadata?.total_pages
    const page_index = listCourse[type]?.metadata?.page_index
    const page_size = listCourse[type]?.metadata?.page_size
    if (total_pages) {
      if (page_index && page_index < total_pages) {
        getListSort({
          type,
          parentType,
          name: listCourse[type].search,
          parentId: listCourse[parent[type]]?.id,
          page_index: page_index + 1,
          page_size,
        })
      }
    }
  }
  const debouncedHandleNextListSort = debounce(handleNextListSort, 500)

  const useCourseFilterResult = useMemo(
    () => ({
      listCourse,
      setListCourse,
      getListSort,
      debouncedGetListSort,
      clearChildren,
      getCoursesCategories,
      debounceGetCoursesCategories,
      getCourses,
      debouncedGetCourses,
      defaultSearchCourse,
      handleNextPage: debouncedHandleNextPage,
      handleNextListSort: debouncedHandleNextListSort,
    }),
    [listCourse]
  )
  return useCourseFilterResult
}

export default useCourseFilter
