import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { debounce, isUndefined } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import { QuestionBankAPI } from 'src/apis/question-bank'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import SappModal from 'src/components/base/SappModal'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {
  IQuestion,
  IQuestionTag,
  IQuestionTagList,
  IResponseQuestionMeta,
} from 'src/components/question-bank/shared/interfaces'
import { FILTER_SELECTALL_SORTBY } from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { ISearchCourse } from 'src/hooks/use-course-filter'
import { ITopicList, ITopicObject } from 'src/type/question-bank'
import { cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'
import { LIST_TYPE } from '../shared/Interfaces'
import TableListQuestion from './TableListQuestion'

const { Option } = Select

type Props = {
  open: boolean | string
  setOpen: React.Dispatch<React.SetStateAction<boolean | LIST_TYPE>>
  type: LIST_TYPE | boolean
  questions: IQuestion[]
  setQuestions: React.Dispatch<React.SetStateAction<IQuestion[]>>
  maxQuestionsNumber: number | 'NO_MAX'
  editing?: string
  isTest?: boolean
  filterSelected?: boolean
}

const ChooseQuestionsModal = ({
  open,
  setOpen,
  type,
  questions,
  setQuestions,
  maxQuestionsNumber,
  editing,
  isTest,
  filterSelected = true,
}: Props) => {
  const defaultValueMeta = {
    page_index: 0,
    page_size: 0,
    total_pages: 0,
    total_records: 0,
  }
  const [questionState, setQuestionState] = useState<IResponseQuestionMeta<IQuestion[]>>()
  const { confirm, contextHolder } = useConfirm()
  const [loading, setLoading] = useState(false)
  const [courseList, setCourseList] = useState<any>({
    courses: [],
    metadata: defaultValueMeta,
  })

  const [listItemSet, setListItemSet] = useState<ITopicList>({
    topics: [],
    meta: defaultValueMeta,
  })

  const [questionTags, setQuestionTags] = useState<IQuestionTagList>({
    question_tags: [],
    metadata: defaultValueMeta,
  })

  const [listSection, setListSection] = useState<any>([])
  const {
    checkedList,
    listDataChecked,
    toggleCheck,
    toggleCheckAll,
    isCheckedAll,
    setDefaultChecked,
  } = useChecked<IQuestion>(questionState?.data.questions)
  const [searchCourse, setSearchCourse] = useState('')
  const [loadingCourse, setLoadingCourse] = useState<boolean>(false)
  // const [generatedParams, setGeneratedParams] = useState<any>()
  useEffect(() => {
    getListQuestions(type, 1, 10, { filter_type: 'ALL', dateField: 'created_at' })
    setDefaultChecked(questions)
  }, [open])
  const getParams = (
    question_content: string,
    course_id: string,
    part_id: string,
    chapter_id: string,
    unit_id: string,
    topic_id: string,
    activity_id: string,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    dateField?: string,
    filter_type?: any,
    quiz_id?: string,
    tag_id?: string
  ) => ({
    question_content,
    course_id,
    part_id,
    chapter_id,
    unit_id,
    topic_id,
    activity_id,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
    filter_type,
    quiz_id,
    tag_id,
  })
  const validationSchema = z.object({
    question_content: z.string().optional(),
    course_id: z.string().optional(),
    tag_id: z.string().optional(),
    part: z.string().optional(),
    chapter: z.string().optional(),
    unit: z.string().optional(),
    activity: z.string().optional(),
    topic: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    filter_type: z.any(),
    quiz_id: z.any(),
  })
  const { control, getValues, reset, setValue, watch } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })
  const onSubmit = () => {
    getListQuestionFilter({ page_index: 1, page_size: 10, created_at: true })
  }
  const getListQuestionFilter = ({
    page_index,
    page_size,
    created_at = false,
  }: {
    page_index: number
    page_size: number
    created_at?: boolean
  }) => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const itemSet = getValues('item-set')
    const activityValue = getValues('activity')
    const unitValue = getValues('unit')
    const chapterValue = getValues('chapter')
    const partValue = getValues('part')
    const tag_id = getValues('tag')

    const showSearchParams = {
      question_content: getValues('question_content').trim(),
      course_id: getValues('course_id'),
      part: partValue,
      chapter: chapterValue,
      unit: unitValue,
      topic_id: itemSet,
      tag_id,
      activity: activityValue,
      sortType: getValues('sortType'),
      fromDate: getValues('fromDate'),
      toDate: getValues('toDate'),
      filter_type: getValues('filter_type'),
      quiz_id: getValues('quiz_id'),
      created_at: created_at,
    }
    const cleanedParams = cleanParamsAPI(
      getParams(
        showSearchParams.question_content ?? '',
        replaceValueAll(showSearchParams.course_id),
        replaceValueAll(showSearchParams.part),
        replaceValueAll(showSearchParams.chapter),
        replaceValueAll(showSearchParams.unit),
        replaceValueAll(showSearchParams.topic_id),
        replaceValueAll(showSearchParams.activity),
        replaceValueAll(showSearchParams.sortType),
        showSearchParams.fromDate
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        showSearchParams.toDate
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        showSearchParams.created_at ? 'created_at' : 'created_at',
        replaceValueAll(showSearchParams.filter_type),
        getValues('quiz_id'),
        replaceValueAll(showSearchParams.tag_id)
      )
    )

    setLoading(true)

    getListQuestions(type, page_index || 1, page_size || 10, cleanedParams)
  }

  const getListItemSet = async (page_index?: number, page_size?: number, param?: Object) => {
    try {
      const response = await QuestionBankAPI.getTopic({
        page_index: page_index ?? 1,
        page_size: page_size ?? 20,
        params: param,
      })
      setListItemSet((prev: ITopicList) => {
        return {
          meta: response.data.meta,
          topics: [...prev.topics, ...response.data.topics].filter(
            (item, index, self) => index === self.findIndex((t) => t.id === item.id)
          ),
        }
      })
    } catch (error) {
      // do nothing
    }
  }

  const getListQuestionTags = async (page_index?: number, page_size?: number, param?: Object) => {
    try {
      const response = await QuestionBankAPI.getQuestionTags({
        page_index: page_index ?? 1,
        page_size: page_size ?? 10,
        params: param,
      })
      setQuestionTags((prev: IQuestionTagList) => {
        return {
          metadata: response.data.metadata,
          question_tags: [...prev.question_tags, ...response.data.question_tags].filter(
            (item, index, self) => index === self.findIndex((t) => t.id === item.id)
          ),
        }
      })
    } catch (error) {
      // do nothing
    }
  }

  const { id } = useParams()
  const getListQuestions = (
    type: LIST_TYPE | boolean = 'all',
    page_index?: number,
    page_size?: number,
    param?: any
  ) => {
    if (typeof type === 'string') {
      setLoading(true)
      ;(async () => {
        try {
          const params = {
            ...param,
            page_index,
            page_size,
            quiz_id: getValues('filter_type') ? id : undefined,
          }
          if (isTest) {
            params['is_independent_question'] = !isTest
          }
          if (editing) {
            // params['quiz_id'] = getValues('quiz_id')
            const response = await QuestionBankAPI.getQuestions(
              {
                params,
              },
              type
            )
            setQuestionState(response)
          } else {
            const response = await QuestionBankAPI.getQuestions(
              {
                params,
              },
              type
            )
            setQuestionState(response)
          }
        } catch (e) {
        } finally {
          setLoading(false)
        }
      })()
    }
  }
  const getCourseList = async ({
    name,
    page_index = 1,
    page_size = 20,
  }: {
    name?: string
    page_index?: number
    page_size?: number
  }) => {
    try {
      setLoadingCourse(true)
      const res = await CoursesAPI.get({
        page_index: page_index,
        page_size: page_size,
        params: { name: name },
      })
      if (page_index === 1) {
        setCourseList(res.data)
      } else {
        setCourseList((prev: any) => {
          const obj = { ...prev }
          const newArr = obj.courses.concat(res.data.courses)
          obj.courses = newArr
          obj.metadata = { ...res.data.metadata }
          return obj
        })
      }
      return res
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingCourse(false)
    }
  }

  const debounceSearchItemSet = debounce((e) => {
    getListItemSet(1, 10, { text: e })
  })

  const debounceSearchTag = debounce((e) => {
    getListQuestionTags(1, 10, { name: e })
  })

  const debounceSearchCourse = debounce((e) => {
    getCourseList({ name: e, page_index: 1, page_size: 20 })
    setSearchCourse(e)
  }, 500)
  const getListSection = async ({
    type,
    name,
    parentId,
    page_index = 1,
    page_size = 50,
  }: {
    type: keyof ISearchCourse
    name?: string
    parentId?: string
    page_index?: number
    page_size?: number
  }) => {
    try {
      const response = await CoursesAPI.getListSort({
        name,
        parentId: type !== 'part' ? parentId : undefined,
        courseId: type === 'part' ? parentId : undefined,
        page_index: page_index,
        page_size: page_size,
        type: type.toUpperCase(),
      })
      setListSection((e: any) => {
        const obj = { ...[type] } as any
        let newData = []
        if (obj.data) {
          newData = obj.data.concat(response?.data?.sections)
        } else {
          newData = response?.data?.sections
        }
        return {
          ...e,
          [type]: {
            loading: false,
            data: newData || [],
            metadata: response?.data?.meta,
          },
        }
      })
    } catch (error) {}
  }

  const handleNextPage = () => {
    const total_pages = courseList?.metadata?.total_pages
    const page_index = courseList?.metadata?.page_index
    const page_size = courseList?.metadata?.page_size
    if (total_pages) {
      if (page_index < total_pages) {
        if (!loadingCourse) {
          getCourseList({
            name: searchCourse,
            page_index: page_index + 1,
            page_size: page_size,
          })
        }
      }
    }
  }
  const handleResetFilter = () => {
    reset()
    // setLoading(true)
    clearChildren('course')

    getListQuestions(type, 1, queryParams.page_size || 10)
  }

  const handlNextPageSection = ({ type, name }: { type: keyof ISearchCourse; name?: string }) => {
    const total_pages = listSection?.[type]?.metadata?.total_pages
    const page_index = listSection?.[type]?.metadata?.page_index
    const page_size = listSection?.[type]?.metadata?.page_size
    const parentId =
      listSection?.[type]?.data?.[0]?.course_id || listSection?.[type]?.data?.[0]?.parent_id || ''
    if (total_pages) {
      if (page_index < total_pages) {
        getListSection({
          type: type,
          name: name,
          parentId: parentId,
          page_index: page_index + 1,
          page_size: page_size,
        })
      }
    }
  }

  const handleClose = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        setOpen(false)
        reset()
        // setLoading(true)
        clearChildren('course')
        // toggleCheckAll(false)
        // setGeneratedParams(undefined)
      },
    })
  }

  const handleSubmit = () => {
    if (!listDataChecked || listDataChecked.length <= 0) {
      toast.error('You have not selected any question!')
      return
    }
    if (maxQuestionsNumber !== 'NO_MAX' && (listDataChecked || []).length > maxQuestionsNumber) {
      toast.error('The number of selected questions exceeds the limit of ' + maxQuestionsNumber)
      return
    }
    // Set lại list question và Câu hỏi được add cuối cùng hiển thị ở cuối
    setQuestions((e) => {
      return [...listDataChecked]
    })
    setOpen(false)
  }

  const queryParams = {
    question_content: '',
    course_id: '',
    tag_id: '',
    part: '',
    chapter: '',
    unit: '',
    topic_id: '',
    course_section_id: '',
    sortType: '',
    fromDate: '',
    toDate: '',
    page_index: 1,
    page_size: 10,
    filter_type: 'ALL',
    quiz_id: '',
  }
  const newCourse = courseList?.courses?.map((e: any) => ({
    label: e.name,
    value: e.id,
  }))
  const clearChildren = (type: keyof ISearchCourse, reset: boolean = true): any => {
    const children = {
      course: ['part', 'chapter', 'unit', 'activity'],
      part: ['chapter', 'unit', 'activity'],
      chapter: ['unit', 'activity'],
      unit: ['activity'],
    }
    const key = type as keyof typeof children
    let newListCourse = { ...listSection }
    if (type !== 'activity') {
      for (let child of children[key]) {
        const newSetValue = setValue as any
        newSetValue(child, '')
        newListCourse = {
          ...newListCourse,
          [child]: {
            loading: false,
            data: [],
            id: undefined,
          },
        }
      }
      setListSection(newListCourse)
    }
  }

  const [belongToQuiz, setBelongToQuiz] = useState<any>()

  const fetchQuiz = async (page_index: number, page_size: number, params?: Object) => {
    try {
      const res = await QuestionBankAPI.getBelongToQuiz(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  // Xử lý chuyển sang trang kế tiếp của danh sách câu hỏi
  const handlNextPageQuiz = async (params: Object) => {
    const totalPages = belongToQuiz?.meta?.total_pages
    const pageIndex = belongToQuiz?.meta?.page_index as number
    const pageSize = belongToQuiz?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res = await fetchQuiz(pageIndex + 1, pageSize, params)
      const results = belongToQuiz.quizzes.concat(res?.data?.groups)
      setBelongToQuiz({
        meta: res?.data?.meta,
        quizzes: results,
      })
    }
  }

  // Lấy danh sách câu hỏi
  const getQuiz = async ({ params }: { params: Object }) => {
    const resMentor = await fetchQuiz(1, 30, params)
    setBelongToQuiz(resMentor?.data)
  }

  // Tạo hàm debounce để tìm kiếm câu hỏi
  const debounceSearchMentor = debounce((e) => {
    getQuiz({ params: { name: e, courseId: watch('course_id') } })
  }, 500)

  // Tạo mảng chứa danh sách câu hỏi và bài kiểm tra
  const belongToQuizAndTest =
    belongToQuiz?.quizzes &&
    belongToQuiz?.quizzes?.map((quizzes: any) => ({ label: quizzes?.name, value: quizzes?.id }))

  // useEffect để xử lý khi giá trị của 'course' thay đổi
  useEffect(() => {
    if (watch('course_id') === undefined || watch('course_id') === 'all') {
      setBelongToQuiz(undefined)
      setValue('quiz_id', null)
    }
  }, [watch('course_id')])

  // useEffect để lấy danh sách câu hỏi khi 'course' thay đổi
  useEffect(() => {
    if (watch('course_id') && watch('course_id') !== 'all') {
      getQuiz({ params: { courseId: watch('course_id') } })
    }
  }, [watch('course_id')])

  // // useEffect để đặt giá trị 'quiz' thành null khi 'id' không tồn tại
  useEffect(() => {
    if (watch('course_id')) {
      setValue('quiz_id', null)
    }
  }, [watch('course_id')])

  const handleNextPageItemSet = (params: Object) => {
    const total_pages = listItemSet?.meta?.total_pages
    const page_index = listItemSet?.meta?.page_index
    const page_size = listItemSet?.meta?.page_size
    if (total_pages) {
      if (page_index < total_pages) {
        if (!loadingCourse) {
          getListItemSet(page_index + 1, page_size, params)
        }
      }
    }
  }

  const handleNextPageITag = (params: Object) => {
    const total_pages = questionTags?.metadata?.total_pages
    const page_index = questionTags?.metadata?.page_index
    const page_size = questionTags?.metadata?.page_size
    if (total_pages) {
      if (page_index < total_pages) {
        if (!loadingCourse) {
          getListQuestionTags(page_index + 1, page_size, params)
        }
      }
    }
  }

  return (
    <div>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={!!open}
        handleClose={handleClose}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen'}
        classNameBody={'sapp-m-h-unset sapp-table-container'}
        classBody='modal-body pb-0'
        showFooter={false}
        handleSubmit={handleSubmit}
        customFooter={
          <div className='modal-body w-100 sapp-table-container px-0'>
            <PagiantionSAPP
              currentPage={questionState?.data?.meta?.page_index ?? 1}
              handlePaginationChange={(page: number, pageSize: number) => {
                getListQuestionFilter({ page_index: page, page_size: pageSize, created_at: false })
              }}
              pageSize={questionState?.data?.meta?.page_size ?? 10}
              totalItems={questionState?.data?.meta?.total_records}
            />
          </div>
        }
        classNameFooter='border-0 py-0'
        disableInnerScroll
      >
        <div className='d-flex justify-content-between mb-8'>
          <div className='sapp-title-modal-create-class'>List Questions</div>
          <div className='d-flex justify-content-between align-items-center'>
            {checkedList.length > 0 && (
              <div className='fw-bold fs-6 text-primary me-8'>{checkedList.length} Selected</div>
            )}
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleClose}
              cancelButtonCaption='Cancel'
              okButtonCaption='Add'
              okOnClick={handleSubmit}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-bold me-0'
              classNameSubmit='fw-bold me-5'
              isListScreen
              // loading={loading}
            />
          </div>
        </div>
        <div className='w-100'>
          <div className='row'>
            {/* begin:: Search */}
            <div className='col-xl-2 col-sm-4'>
              <div className='card-title justify-content-center mb-0 mx-0 mt-2'>
                <HookFormTextField
                  control={control}
                  name='question_content'
                  placeholder='Search'
                  defaultValue={queryParams?.question_content}
                  isListScreen={false}
                  preIcon={
                    <i
                      className={`ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y
                       ms-5
                      `}
                    ></i>
                  }
                  onSubmit={onSubmit}
                />
              </div>
            </div>
            {/* end:: Search */}

            <div className='col-xl-2 col-sm-4 mt-2'>
              <HookFormSelectAntd
                control={control}
                name='course_id'
                placeholder='Course'
                defaultValue={queryParams.course_id ?? ''}
                showSearch
                handleNextPage={handleNextPage}
                onFocus={async () => {
                  if (courseList?.courses?.length <= 0) {
                    await getCourseList({})
                    return
                  }
                }}
                onChange={(e: any) => {
                  if (e === undefined) {
                    return
                  }
                  getListSection({ parentId: e, type: 'part' })
                  clearChildren('course')
                }}
                allowClear
                onClear={() => {
                  clearChildren('course')
                }}
                onSearch={async (e) => {
                  debounceSearchCourse(e)
                  return
                }}
                loading={loadingCourse}
                onSelect={() => {
                  getCourseList({ name: '', page_index: 1, page_size: 20 })
                  setSearchCourse('')
                }}
                options={newCourse ?? []}
              />
            </div>
            <div className='col-xl-2 col-sm-4 mt-2'>
              <HookFormSelectAntd
                name='part'
                placeholder='Part'
                control={control}
                showSearch
                loading={false}
                handleNextPage={() => handlNextPageSection({ type: 'part' })}
                onChange={(e: any) => {
                  if (e === undefined) {
                    return
                  }
                  getListSection({ parentId: e, type: 'chapter' })
                }}
                allowClear
                onClear={() => {
                  clearChildren('part')
                }}
                onSelect={() => {
                  clearChildren('part')
                }}
                options={
                  listSection.part?.data?.map((e: any) => ({ label: e.name, value: e.id })) ?? []
                }
              />
            </div>
            <div className='col-xl-2 col-sm-4 mt-xl-2 mt-5'>
              <HookFormSelectAntd
                name='chapter'
                placeholder='Chapter'
                control={control}
                showSearch
                loading={false}
                handleNextPage={() => handlNextPageSection({ type: 'chapter' })}
                onChange={(e: any) => {
                  if (e === undefined) {
                    return
                  }
                  getListSection({ parentId: e, type: 'unit' })
                }}
                allowClear
                onClear={() => {
                  clearChildren('chapter')
                }}
                onSelect={() => {
                  clearChildren('chapter')
                }}
                options={
                  listSection.chapter?.data?.map((e: any) => ({ label: e.name, value: e.id })) ?? []
                }
              />
            </div>

            <div className='col-xl-2 col-sm-4 mt-5  mt-xl-2'>
              <HookFormSelectAntd
                name='unit'
                placeholder='Unit'
                control={control}
                showSearch
                loading={false}
                handleNextPage={() => handlNextPageSection({ type: 'unit' })}
                onChange={(e: any) => {
                  if (e === undefined) {
                    return
                  }
                  getListSection({ parentId: e, type: 'activity' })
                }}
                allowClear
                onClear={() => {
                  clearChildren('unit')
                }}
                onSelect={() => {
                  clearChildren('unit')
                }}
                options={
                  listSection.unit?.data?.map((e: any) => ({ label: e.name, value: e.id })) ?? []
                }
              />
            </div>
            <div className='col-xl-2 col-sm-4 mt-xl-2 mt-5'>
              <HookFormSelectAntd
                name='activity'
                placeholder='Activity'
                control={control}
                loading={false}
                handleNextPage={() => handlNextPageSection({ type: 'activity' })}
                allowClear
                options={
                  listSection.activity?.data?.map((e: any) => ({
                    label: e.name,
                    value: e.id,
                  })) ?? []
                }
              />
            </div>
            <div className='col-xl-2 col-sm-4 mt-5'>
              <HookFormSelectAntd
                name='quiz_id'
                placeholder='Quiz/Test'
                control={control}
                // className='w-100 sapp-h-45px fs-6 sapp-field_question-select'
                loading={loading}
                onSearch={(e: any) => {
                  if (e === undefined) {
                    return
                  }
                  if (!isUndefined(watch('course_id'))) {
                    debounceSearchMentor(e)
                  }
                }}
                onClear={() => setValue('quiz_id', null)}
                handleNextPage={(e: any) => handlNextPageQuiz({ params: { name: e } })}
                showSearch
                allowClear
                options={belongToQuizAndTest?.map((e: any) => ({
                  label: e.label,
                  value: e.value,
                }))}
              />
            </div>
            <div className='col-xl-2 col-sm-4 mt-5'>
              <HookFormSelectAntd
                control={control}
                name='item-set'
                placeholder='Item Set'
                defaultValue={queryParams.topic_id ?? ''}
                showSearch
                handleNextPage={debounce(
                  (e: string) => handleNextPageItemSet({ params: { text: e } }),
                  500
                )}
                allowClear
                onFocus={async () => {
                  if (listItemSet?.topics?.length <= 0) {
                    await getListItemSet()
                    return
                  }
                }}
                onClear={() => {
                  setValue('item-set', null)
                }}
                onSearch={async (e) => {
                  debounceSearchItemSet(e)
                  return
                }}
                loading={loadingCourse}
                options={
                  listItemSet?.topics?.map((item: ITopicObject) => ({
                    label: item.name,
                    value: item.id,
                  })) ?? []
                }
              />
            </div>
            <div className='col-xl-2 col-sm-4 mt-5'>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='tag'
                placeholder='Tag'
                defaultValue={queryParams.tag_id ?? ''}
                showSearch
                handleNextPage={debounce(
                  (e: string) => handleNextPageITag({ params: { name: e } }),
                  500
                )}
                allowClear
                onFocus={async () => {
                  if (questionTags?.question_tags?.length <= 0) {
                    await getListQuestionTags()
                    return
                  }
                }}
                onClear={() => {
                  setValue('tag', null)
                }}
                onSearch={async (e) => {
                  debounceSearchTag(e)
                  return
                }}
                loading={loadingCourse}
                options={
                  questionTags.question_tags?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })) ?? []
                }
              ></HookFormSelectAntd>
            </div>
            <div className='col-xl-2 col-sm-4 mt-5 '>
              <HookFormSelectAntd
                name='sortType'
                placeholder='Sort by'
                control={control}
                defaultValue={queryParams?.sortType ?? ''}
                options={FILTER_SELECTALL_SORTBY}
              />
            </div>
            <div className='col-xl-2 col-sm-4 mt-5'>
              <HookFormDateTime
                control={control}
                name='fromDate'
                placeholder='From date'
                defaultValue={queryParams?.fromDate}
              />
            </div>
            <div className='col-xl-2 col-sm-4 mt-5'>
              <HookFormDateTime
                control={control}
                name='toDate'
                placeholder='To date'
                defaultValue={queryParams?.toDate}
              />
            </div>
            {editing && filterSelected && (
              <div className='col-xl-2 col-sm-4 mt-5'>
                <HookFormSelectAntd
                  control={control}
                  name='filter_type'
                  placeholder='All'
                  allowClear
                  defaultValue={queryParams.filter_type ?? ''}
                  options={[
                    { value: 'SELECTED', label: 'Selected' },
                    { value: 'NOT_SELECTED', label: 'Not selected' },
                    { value: 'ALL', label: 'All' },
                  ]}
                />
              </div>
            )}
          </div>
        </div>
        <div className='card-header border-0 pt-6 mb-8 px-0'>
          <SAPPFIlterButton
            titleReset='Reset'
            resetClick={handleResetFilter}
            titleSubmit='Search'
            okClick={onSubmit}
            disabled={false}
          />
        </div>
        <TableListQuestion
          checkedList={checkedList}
          isCheckedAll={isCheckedAll}
          loading={loading}
          questionList={questionState?.data?.questions}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
        />
      </SappModal>
    </div>
  )
}

export default ChooseQuestionsModal
