import { TablePaginationConfig } from 'antd'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { KTCardBody } from 'src/_metronic/helpers'
import { UsersAPI } from 'src/apis/user'
import AssignedTo from 'src/components/base/assigned'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import SappTable from 'src/components/ui/table/SappTable'
import UserCell from 'src/components/user-management/UserCell'
import UserInfoCell from 'src/components/user-management/UserInfoCell'
import { STAFF_PROFILE, STATUS_FORM } from 'src/constants'
import { CODE_ADMIN, TITLE_STAFF_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { EStatusUser, Role } from 'src/type'
import { IStaffData, IStaffList } from 'src/type/staffs.'
import { cleanParamsAPI } from 'src/utils'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { StaffActionsCell } from './columns/StaffActionsCell'

interface IProps {
  staffList: IStaffList
  handleChangeParams: any
  queryParams: {
    page_index: number
    page_size: number
    textSearch: string | null
    sortRole?: string
    sortStatus?: string
    sortType?: string
    fromDate: Date
    toDate: Date
    sex: string
  }
  pagination: TablePaginationConfig
  setPagination: Dispatch<SetStateAction<TablePaginationConfig>>
  setSelected: Dispatch<SetStateAction<Map<number, React.Key[]>>>
  selected: Map<number, React.Key[]>
}

const StaffTable = ({
  staffList,
  queryParams,
  pagination,
  setPagination,
  handleChangeParams,
  setSelected,
  selected,
}: IProps) => {
  const { isLoading, refetch } = useQueryResponse()
  const { confirm, contextHolder } = useConfirm()
  // TODO: reset lại table về mặc định nếu không call API
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }
  const { control, watch, setValue } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    staffList?.staffs?.map((item: IStaffData, index: number) => {
      setValue(`status${index}`, item.status)
    })
  }, [staffList?.staffs])

  const changeStatusUser = (id: string, status: any) => {
    UsersAPI.updateStaffStatus({ id, status })
      .then(() => toast.success('Update Successfully!'))
      .catch((error) => {})
  }

  const hanleChangeStatus = (id: string, value: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: () =>
        changeStatusUser(
          id,
          EStatusUser.ACTIVE === value
            ? EStatusUser.ACTIVE
            : EStatusUser.INACTIVE === value
            ? EStatusUser.INACTIVE
            : EStatusUser.BLOCKED
        ),
      onClose: () => cancel(true),
    })
  }

  const handleChange = (name: string, value: string, id: string) => {
    if (watch(name)) {
      hanleChangeStatus(id, value)
    }
  }

  const filterParams = cleanParamsAPI({ ...queryParams })
  const { profileMe } = useUserContext()
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderRole = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.GET_ASSIGN_PERMISSION_STAFF
  )

  const allowRenderResetPasswordStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.RESET_PASSWORD_STAFF
  )
  const allowRenderBlockStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderEditEmailStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.CHANGE_EMAIL_STAFF
  )
  const allowRenderViewProfileStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.GET_STAFF)
  const allowRenderStaffActions =
    allowRenderEdit ||
    allowRenderResetPasswordStaff ||
    allowRenderBlockStaff ||
    allowRenderEditEmailStaff ||
    allowRenderViewProfileStaff

  useEffect(() => {
    setPagination((prev) => ({ ...prev, total: staffList?.metadata?.total_records }))
  }, [staffList])

  const columnsValue = [
    {
      title: 'Code',
      render: (record: IStaffData) => <UserCell data={record?.key ?? ''} />,
    },
    {
      title: 'User',
      render: (_: any, record: IStaffData) => (
        <UserInfoCell
          user={record ?? ''}
          linkViewProfile={`${STAFF_PROFILE}/${record?.id}/overview`}
        />
      ),
    },
    {
      title: 'Username',
      render: (record: IStaffData) => <UserCell data={record?.username ?? ''} />,
    },
    {
      title: 'Phone',
      render: (record: IStaffData) => <UserCell data={record?.detail?.phone ?? ''} />,
    },
    ...(allowRenderRole
      ? [
          {
            title: 'Role',
            render: (record: IStaffData) => (
              <div className='d-flex flex-column'>
                <AssignedTo roles={record?.roles ?? []} />
              </div>
            ),
          },
        ]
      : []),

    {
      title: 'Status',
      render: (_: IStaffData, record: IStaffData, index: number) => {
        return (
          <HookFormSelectAntd
            allowClear={false}
            size='large'
            name={`status${index}`}
            control={control}
            dropdownStyle={{ maxWidth: 100 }}
            placeholder='Status'
            filterOption={true}
            disabled={!allowRenderEdit || profileMe?.id === record?.id}
            onChange={(selectedValue: unknown) =>
              handleChange(`status${index}`, selectedValue as string, record?.id)
            }
            classNameHeight='sapp-h-35px'
            options={STATUS_FORM}
          />
        )
      },
    },
    {
      title: 'Date',
      render: (record: IStaffData) => (
        <DatetimeColumn updated_at={record?.updated_at} created_at={record?.created_at} />
      ),
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (record: any) => (
        <>
          {!allowRenderStaffActions ? (
            <></>
          ) : (
            <StaffActionsCell
              id={record?.id}
              status={record?.status}
              email={record?.detail?.email}
            />
          )}
        </>
      ),
    },
  ]

  return (
    <KTCardBody>
      {contextHolder}
      <SappTable
        handleChangeParams={handleChangeParams}
        filterParams={filterParams}
        columns={columnsValue}
        fetchData={refetch}
        data={staffList?.staffs ?? []}
        pagination={pagination}
        setPagination={setPagination}
        fetchTableData={refetch}
        loading={isLoading}
        showCheckbox
        setSelection={setSelected}
        selections={selected}
      />
    </KTCardBody>
  )
}

export { StaffTable }
