import { useQuery } from 'react-query'
import { UniversityApi } from 'src/apis/university'
import { universityKeys } from 'src/constants/queryKeyFactory'

const useUniversities = (enabled: boolean) => {
  const {
    data: universities,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: universityKeys.all,
    queryFn: async () => await UniversityApi.getUniversities(),
    enabled,
  })

  if (isLoading) {
    return {
      universities: [],
      isFetchingUniversities: true,
      isUniversitiesSuccess: false,
    }
  }

  if (isError) {
    return {
      universities: [],
      isFetchingUniversities: false,
      isUniversitiesSuccess: false,
    }
  }

  return {
    universities: universities?.data || [],
    isFetchingUniversities: isLoading || isFetching,
    isUniversitiesSuccess: isSuccess,
  }
}

export default useUniversities
