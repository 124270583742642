import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { Dispatch, SetStateAction, useState } from 'react'
import { useForm } from 'react-hook-form'
import LoadingTable from 'src/common/LoadingTable'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import SappTable from 'src/components/base/SappTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { FILTER_SELECTALL_SORTBY } from 'src/constants'
import { cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { z } from 'zod'
import HookFormDateTime from '../base/datetime/HookFormDateTime'
import { ISupporterInGroup, ISupporterList } from 'src/type/comment'
import ListFilterLayout from '../layout/listFilter'

const { Option } = Select

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  userState: ISupporterList | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchListSupporter: (currentPage?: number, pageSize?: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: (
    text: string,
    sort_type: string,
    fromDate: Date | string,
    toDate: Date | string,
    dateField: string
  ) => {
    text: string
    sort_type: string
    fromDate?: Date | string
    toDate?: Date | string
    dateField?: string
  }
  queryParams: {
    text: string
    sort_type: string
    fromDate: string | Date
    toDate: string | Date
    page_index: number
    page_size: number
  }
  checkedList: string[]
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, memorize?: boolean) => void
  isCheckedAll: boolean
  setUserState: Dispatch<SetStateAction<ISupporterList | undefined>>
}

// define headers
const headers = [
  {
    label: 'SUPPORTER ID',
    className: 'min-w-150px',
  },
  {
    label: 'SUPPORTER NAME',
    className: 'min-w-200px',
  },
  {
    label: 'PHONE',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'DATE',
    className: 'min-w-100px pe-5',
  },
]

const SupporterTable = ({
  userState,
  loading,
  setLoading,
  fetchListSupporter,
  getParams,
  queryParams,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
}: IProps) => {
  const initialValues = {
    text: '',
    sort_type: '',
    fromDate: '',
    toDate: '',
    selectType: '',
  }
  const [searchParams, setSearchParams] = useState<any>(initialValues)

  const validationSchema = z.object({
    text: z.string().optional(),
    sort_type: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    selectType: z.any(),
  })
  const fieldNames = ['text', 'sort_type', 'fromDate', 'toDate']

  // Using validate for input
  const { control, reset, getValues, setValue, handleSubmit } = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const showDatefield =
    getValues('text') || getValues('sort_type') || getValues('fromDate') || getValues('toDate')

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    setLoading(true)
    const dateInfoFromDate = searchParams.fromDate
    const dateInfoToDate = searchParams.toDate
    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.text ?? '',
        searchParams?.sort_type ?? '',
        searchParams.fromDate
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        searchParams.toDate
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        searchParams.selectType
      )
    )
    fetchListSupporter(page_index, page_size, cleanedParams)
  }

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName as keyof typeof initialValues])
    })
    setSearchParams(initialValues)
    setLoading(true)
    fetchListSupporter(1, 10)
  }

  const onSubmit = () => {
    setLoading(true)
    try {
      const dateInfoFromDate = getDateInfo(getValues('fromDate'))
      const dateInfoToDate = getDateInfo(getValues('toDate'))
      const cleanedParams = cleanParamsAPI(
        getParams(
          getValues('text').trim() ?? '',
          getValues('sort_type') ?? '',
          getValues('fromDate')
            ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
            : '',
          getValues('toDate')
            ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
            : '',
          showDatefield ? 'updated_at' : 'created_at'
        )
      )
      fetchListSupporter(1, 10, cleanedParams)
    } catch (error) {}
  }

  return (
    <div className='card-body py-5'>
      <div className='card-header border-0'>
        <div className='w-100'>
          <ListFilterLayout>
            <HookFormTextField
              control={control}
              name='text'
              placeholder='Search Supporter'
              defaultValue={queryParams?.text}
              onSubmit={onSubmit}
              isListScreen
            />

            <HookFormSelectAntd
              name='sort_type'
              placeholder='Sort by'
              control={control}
              defaultValue={queryParams?.sort_type ?? ''}
              options={FILTER_SELECTALL_SORTBY}
              classNameHeight='sapp-h-40'
            />

            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams?.fromDate}
              isListScreen
            />

            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams?.toDate}
              isListScreen
            />
          </ListFilterLayout>
        </div>
      </div>
      <div className='card-header border-0 pt-6 mb-6 px-0'>
        <div className='container m-0'>
          <div className='row'>
            <div className='col-sm-4 col-xl-4 col-lg-8 px-md-0'>
              <SAPPFIlterButton
                titleReset='Reset'
                titleSubmit='Search'
                okClick={handleSubmit(onSubmit)}
                resetClick={handleResetFilter}
                disabled={loading}
                loading={loading}
                classNameCancel='sapp-fs--default'
                classNameSubmit='sapp-fs--default'
              />
            </div>
          </div>
        </div>
      </div>
      <SappTable
        headers={headers}
        loading={loading}
        data={userState?.data}
        isCheckedAll={false}
        hasCheckAll={true}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {[1, 2, 3, 4, 5].map((header, i) => (
              <LoadingTable key={header} headers={[1, 2, 3, 4, 5]} />
            ))}
          </>
        ) : (
          <>
            {userState?.data?.map((user: ISupporterInGroup) => {
              const isChecked = checkedList.includes(user.id)

              return (
                <tr key={user.id}>
                  <td>
                    <SAPPCheckbox
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => toggleCheck(user.id)}
                    />
                  </td>
                  <td className='text-start'>{user?.hubspot_contact_id}</td>
                  <td className='text-start'>
                    <div className='sapp-text-truncate-2 sapp-table-title-des text-wrap text-break'>
                      {user?.detail?.full_name}
                    </div>
                  </td>
                  <td className='text-start'>{user?.user_contacts?.[0]?.phone}</td>
                  <td className='text-start'>
                    <DatetimeColumn created_at={user?.created_at} updated_at={user?.updated_at} />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <div></div>
      <PagiantionSAPP
        currentPage={userState?.metadata?.page_index || 1}
        pageSize={userState?.metadata?.page_size || 10}
        totalItems={userState?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default SupporterTable
