import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { DepartmentAPI } from 'src/apis/department'
import SappDrawer from 'src/components/base/SappDrawer'
import { departmentKeys } from 'src/constants'
import { FormType, IDepartmentForm } from 'src/type'
import DepartmentForm from '../DepartmentForm'

interface IDepartment {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DepartmentCreate = ({ open, setOpen }: IDepartment) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { mutate: addDepartment } = useMutation({
    mutationFn: (data: IDepartmentForm) => DepartmentAPI.create(data),
    onSuccess: (res) => {
      if (res.success) {
        toast.success('Create Department Successfully')
        queryClient.invalidateQueries(departmentKeys.all())
        setOpen(false)
      }
    },
  })

  return (
    <SappDrawer
      open={open}
      title='New Department'
      handleSubmit={() => {}}
      width='60%'
      handleClose={() => {
        setOpen(false)
        navigate('/departments')
      }}
      showFooter={false}
      rootClassName='px-0'
    >
      <DepartmentForm
        drawerOpen={open}
        setDrawerOpen={setOpen}
        submit={addDepartment}
        formType={FormType.CREATE}
      />
    </SappDrawer>
  )
}

export default DepartmentCreate
