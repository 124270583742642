import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { debounce, isUndefined } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import { ClassesApi } from 'src/apis/classes'
import { uploadFile, UsersAPI } from 'src/apis/user'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import HeaderTab from 'src/components/base/HeaderTab'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import UploadMulti from 'src/components/base/upload-file/UploadMulti'
import { IUpdateTeacher } from 'src/components/teacher/TeacherPostForm/TeacherPostForm'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import ModalEditEmail from 'src/components/user-list/components/user-edit-modal/ModalEditEmail'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  ErrorCode,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  STATUS_FORM,
  TEACHER_PROFILE,
} from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM, LANG_PLACEHOLDER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { teachingStatusArray } from 'src/constants/users/teacher'
import { useUserContext } from 'src/context/UserProvider'
import { getSelectOptions } from 'src/helper/getSelectOptions'
import { useConfirm } from 'src/hooks/use-confirm'
import useStaffs from 'src/hooks/useStaffs'
import { EStatusUser, IError, Role } from 'src/type'
import { IFacilities, IFacility } from 'src/type/area'
import { IUploadFile } from 'src/type/common'
import { IStudentDetail } from 'src/type/students'
import { ITeacher } from 'src/type/teacher'
import { sizeInBytes } from 'src/utils'
import { z } from 'zod'
import { DEFAULT_SCHEMA } from '../../schema'
import Subjects from '../../TeacherPostForm/Subjects'
const { Option } = Select

interface IProps {
  teacherDetail: IStudentDetail | undefined
  setTeacherDetail: Dispatch<SetStateAction<any>>
  loading: boolean
}

const EditProfileTeacher = ({ teacherDetail, setTeacherDetail, loading }: IProps) => {
  const navigate = useNavigate()

  const [defaultValues, setDefaultValues] = useState({
    id: teacherDetail?.id || '',
    username: teacherDetail?.username,
    staff_ids: teacherDetail?.user_staff_instances?.map((item) => item.staff_id) || [],
    phone: teacherDetail?.user_contacts?.find((user) => user.is_default)?.phone,
    full_name: teacherDetail?.detail?.full_name,
    email: teacherDetail?.user_contacts?.find((user) => user.is_default)?.email,
    status: teacherDetail?.status,
    sex: teacherDetail?.detail.sex,
    job_title: teacherDetail?.detail.job_title,
    facebook: teacherDetail?.detail.facebook,
    linkedin: teacherDetail?.detail.linkedin,
    youtube: teacherDetail?.detail.youtube,
    describe: teacherDetail?.detail.describe,
    teacher_status: teacherDetail?.teacher_status,
    category_primary_id: teacherDetail?.category_primary_id,
    attachment_files:
      teacherDetail?.attachment_files && teacherDetail?.attachment_files.length
        ? teacherDetail?.attachment_files.map((item) => ({ ...item.file, id: item.id }))
        : [],
    facility_ids: teacherDetail?.teacher_facilities
      ? teacherDetail?.teacher_facilities?.map((item) => item.facility_id)
      : [],
    current_company: teacherDetail?.detail.current_company ?? '',
    dob: teacherDetail?.detail.dob,
    address: teacherDetail?.detail.address,
  })
  const [text, setText] = useState<string | undefined>(undefined)
  const [isActive, setIsActive] = useState(false)
  const [facilityList, setFacilityList] = useState<IFacilities>()
  const { id } = useParams()
  const [openEditEmail, setOpenEditEmail] = useState(false)
  const { confirm, contextHolder } = useConfirm()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [showAvatarDefault, setShowAvatarDefault] = useState(false)
  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)
  const allowRenderEditEmail = profileMe?.roles?.some((role: Role) => {
    return (
      hasPermission(role, TITLE_GR.PUT_CHANGE_EMAIL_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
    )
  })
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_GR.EDIT_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`${TEACHER_PROFILE}/${id}/overview`),
    })
  }

  const schema = z.object(DEFAULT_SCHEMA)

  const handleOnchangeFile = (files: IUploadFile[]) => {
    setValue(
      'attachment_files',
      files?.map((e: IUploadFile) => ({
        id: e.id,
        name: e.name,
        file_key: e.file_key,
        suffix_type: e.suffix_type,
        type: 'FILE',
      }))
    )
  }

  const fetchFacility = async (pageIndex: number = 1, pageSize: number = 50, params?: Object) => {
    try {
      const res = await ClassesApi.getFacilities({
        page_index: pageIndex,
        page_size: pageSize,
        params,
      })
      if (res.data.metadata.page_index === 1) {
        setFacilityList(() => ({
          metadata: res?.data?.metadata,
          facilities: res?.data?.facilities,
        }))
      } else {
        setFacilityList((prev: IFacilities | undefined) => ({
          metadata: res?.data?.metadata,
          facilities: [...(prev?.facilities ?? []), ...(res?.data?.facilities ?? [])].filter(
            (item: IFacility, index: number, self) =>
              index === self.findIndex((t) => t.id === item.id)
          ),
        }))
      }
    } catch (error) {}
  }

  const handlNextPageFacility = async (params: Object) => {
    const totalPages = facilityList?.metadata?.total_pages
    const pageIndex = facilityList?.metadata?.page_index as number
    const pageSize = facilityList?.metadata?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      await fetchFacility(pageIndex + 1, pageSize, params)
    }
  }

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm<ITeacher>({
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    defaultValues: defaultValues as any,
  })

  const isValidHttpUrl = (url: string) => {
    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
    return pattern.test(url)
  }

  const onSubmit = async (data: ITeacher) => {
    setLoadingSubmit(true)
    const {
      email,
      full_name,
      phone,
      username,
      status,
      avatar,
      job_title,
      sex,
      facebook,
      youtube,
      linkedin,
      describe,
      teacher_teachable_instances,
      current_company,
      facility_ids,
      attachment_files,
    } = data

    if (facebook && !isValidHttpUrl(facebook)) {
      setError('facebook', { message: 'Invalid Value' })
      return
    }

    if (linkedin && !isValidHttpUrl(linkedin)) {
      setError('linkedin', { message: 'Invalid Value' })
      return
    }

    if (youtube && !isValidHttpUrl(youtube)) {
      setError('youtube', { message: 'Invalid Value' })
      return
    }
    // Prevent form submission
    if (!isUndefined(avatar) && (avatar?.size as number) > sizeInBytes(DEFAULT_MAX_FILE_SIZE))
      return

    if (!isUndefined(avatar)) {
      await uploadFile({ userId: teacherDetail?.id, avatar: avatar })
    }

    try {
      const paramsUpdate: IUpdateTeacher = {
        ...data,
        email: email.toLowerCase(),
        full_name: full_name,
        phone: phone,
        username: username,
        status: status,
        sex: sex || null,
        avatar: null,
        job_title,
        facebook,
        youtube,
        linkedin,
        describe,
        current_company,
        facility_ids,
        attachment_files: attachment_files?.map((item) => {
          const founded = teacherDetail?.attachment_files?.find((file) => file.id === item.id)
          if (founded) {
            return founded
          } else {
            return {
              file: {
                name: item?.name,
                suffix_type: item?.suffix_type,
                file_key: item?.file_key,
              },
              type: 'FILE',
            }
          }
        }),
        teacher_teachable_instances: teacher_teachable_instances?.filter(
          (item) => item.category_id && item.category_id !== ''
        ),
      }

      if (!showAvatarDefault || !isUndefined(avatar)) {
        delete paramsUpdate.avatar
      }

      await UsersAPI.update({
        id: teacherDetail?.id,
        data: paramsUpdate,
      })
        .then(() => {
          toast.success('Update SuccessFully!')
          navigate(`${TEACHER_PROFILE}/${teacherDetail?.id}/overview`)
        })
        .catch((err: any) => {
          const { code, message } = err?.response?.data?.error
          if (code && code === ErrorCode.HUBSPOT_CONTACT_EMAIL_INVALID) {
            toast.error(message)
          }
          err?.response?.data?.error?.others?.forEach((e: IError) => {
            const errorMessage = e?.errors?.[0]?.message

            setError(e.property, { message: errorMessage })
          }, {})
        })
    } catch (error) {
      setLoadingSubmit(false)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const { staffs, hasNextPage, fetchNextPage, refetch, isLoading, isFetchingNextPage } = useStaffs({
    status: EStatusUser.ACTIVE,
  })

  useEffect(() => {
    if (!teacherDetail?.id) return
    setDefaultValues({
      id: teacherDetail?.id || '',
      username: teacherDetail?.username,
      staff_ids: teacherDetail?.user_staff_instances?.map((item) => item.staff_id) || [],
      phone: teacherDetail?.user_contacts?.find((user) => user.is_default)?.phone,
      full_name: teacherDetail?.detail?.full_name,
      email: teacherDetail?.user_contacts?.find((user) => user.is_default)?.email,
      status: teacherDetail?.status,
      sex: teacherDetail?.detail.sex,
      address: teacherDetail?.detail?.address,
      job_title: teacherDetail?.detail.job_title,
      facebook: teacherDetail?.detail.facebook,
      linkedin: teacherDetail?.detail.linkedin,
      youtube: teacherDetail?.detail.youtube,
      describe: teacherDetail?.detail.describe,
      teacher_status: teacherDetail?.teacher_status,
      category_primary_id: teacherDetail?.category_primary_id,
      attachment_files:
        teacherDetail?.attachment_files && teacherDetail?.attachment_files.length
          ? teacherDetail?.attachment_files.map((item) => ({ ...item.file, id: item.id }))
          : [],
      facility_ids: teacherDetail?.teacher_facilities
        ? teacherDetail?.teacher_facilities?.map((item) => item?.facility_id)
        : [],
      current_company: teacherDetail?.detail.current_company ?? '',
      dob: teacherDetail?.detail.dob,
    })
    setFacilityList({
      metadata: { page_index: 0, page_size: 0, total_pages: 0, total_records: 0 },
      facilities: teacherDetail?.teacher_facilities?.map((item) => ({ ...item?.facility })) ?? [],
    })
  }, [teacherDetail])

  const debounceSearchStaff = debounce((e) => {
    setText(e)
  }, 300)

  useEffect(() => {
    refetch()
  }, [text])
  return (
    <>
      {contextHolder}
      {teacherDetail && (
        <div className='card mb-5 mb-xl-10'>
          <HeaderTab title='Edit Teacher' />

          <div className='collapse show'>
            <div className='form'>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-semibold fs-6'>Avatar</label>
                  <div className='col-lg-9'>
                    <SAPPHookUploadFile
                      name='avatar'
                      control={control}
                      setValue={setValue}
                      setError={setError}
                      imagePreview={
                        teacherDetail?.detail?.avatar?.['150x150'] ??
                        teacherDetail?.detail?.avatar?.ORIGIN ??
                        avatar
                      }
                      accept={ACCEPT_UPLOAD_MIME_TYPE}
                      maxFileSize={DEFAULT_MAX_FILE_SIZE}
                      setShowAvatarDefault={setShowAvatarDefault}
                      removeAvatar={avatar}
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                    {LANG_PLACEHOLDER.FULLNAME}
                  </label>
                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='full_name'
                      control={control}
                      placeholder={LANG_PLACEHOLDER.FULLNAME}
                      required
                      guideline={GUIDELINE_FULLNAME}
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                    Username
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='username'
                      control={control}
                      placeholder='Username'
                      disabled
                      required
                      guideline={GUIDELINE_USERNAME}
                    />
                  </div>
                </div>

                <div className='row mb-md-6 mb-0'>
                  <label className='col-xl-3 col-lg-3 col-form-label required fw-semibold fs-6'>
                    Email
                  </label>

                  <div className='col-xl-8 col-lg-7 col-md-10 fv-row'>
                    <HookFormTextField
                      name='email'
                      control={control}
                      placeholder='Email'
                      required
                      disabled
                      guideline={GUIDELINE_EMAIL}
                    />
                  </div>
                  <div className='col-xl-1 col-lg-2 col-md-2 d-flex justify-content-end mt-md-0 mt-3'>
                    <ButtonPrimary
                      title='Edit'
                      disabled={!allowRenderEditEmail}
                      className='sapp-p-button--custom'
                      onClick={() => setOpenEditEmail(true)}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-semibold fs-6'>Address</label>

                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField name='address' control={control} placeholder='Address' />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-semibold fs-6'>Phone</label>

                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='phone'
                      control={control}
                      placeholder='Phone Number'
                      required
                      disabled
                      guideline={GUIDELINE_PHONE}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                    Status
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <HookFormSelectAntd
                      name='status'
                      control={control}
                      classNameHeight='sapp-h-45px'
                      disabled={!allowRenderEdit}
                      options={STATUS_FORM}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel
                    label='Gender'
                    className='col-lg-3 mb-0 d-flex align-items-center'
                    required
                  />
                  <div className='col-lg-9 fv-row'>
                    <HookFormSelectAntd
                      name='sex'
                      control={control}
                      classNameHeight='sapp-h-45px'
                      placeholder='Please select'
                      disabled={!allowRenderEdit}
                      options={GENDER_FORM}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel label='D.O.B' className='col-lg-3 mb-0 d-flex align-items-center' />
                  <div className='col-lg-9 fv-row'>
                    <HookFormDateTime control={control} name='dob' placeholder='Please select' />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel
                    required
                    label='Facility'
                    className='col-lg-3 mb-0 d-flex align-items-center'
                  />
                  <div className='col-lg-9 fv-row'>
                    <HookFormSelectMultiple
                      name='facility_ids'
                      control={control}
                      options={
                        facilityList?.facilities?.map((item: any) => {
                          return { label: item.name, value: item.id }
                        }) ?? []
                      }
                      onFocus={(e: React.FocusEvent) => {
                        fetchFacility(1, 50)
                      }}
                      onSearch={(text: string | undefined) => {
                        if (!isUndefined(text)) {
                          fetchFacility(1, 50, { search: `name=${text}` })
                        }
                      }}
                      handleNextPage={handlNextPageFacility}
                      loading={loading}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel
                    label='Current Company'
                    className='col-lg-3 mb-0 d-flex align-items-center'
                  />
                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      control={control}
                      name='current_company'
                      placeholder='Please select'
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel
                    label='File chứng chỉ đi kèm'
                    className='col-lg-3 mb-0 d-flex align-items-center'
                  />
                  <div className='col-lg-9 fv-row'>
                    <UploadMulti
                      fileList={watch('attachment_files')}
                      setFileList={handleOnchangeFile}
                      isScreenList={false}
                      error={errors.attachment_files}
                      resourceLocation={RESOURCE_LOCATION.COURSE_SECTION}
                      label='Resources'
                      disabled={false}
                      guideline={[
                        'Định dạng cho phép pdf, docx, doc, xls, xlsx, csv, txt, ppt, pptx, zip. Kích thước tối đa 500MB.',
                      ]}
                      object_id={teacherDetail?.id}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel
                    label='Job Title'
                    className='col-lg-3 mb-0 d-flex align-items-center'
                  />
                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='job_title'
                      control={control}
                      placeholder='Job Title'
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                    Teaching Status
                  </label>
                  <div className='col-lg-9'>
                    <HookFormSelectAntd
                      size='large'
                      control={control}
                      name='teacher_status'
                      required
                      showSearch
                      classNameHeight='sapp-h-40'
                      options={teachingStatusArray}
                    />
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-semibold fs-6'>
                    Person in Charge
                  </label>
                  <div className='col-lg-9'>
                    <HookFormSelectMultiple
                      name={'staff_ids'}
                      control={control}
                      placeholder='Person in Charge'
                      options={getSelectOptions(
                        staffs,
                        teacherDetail?.user_staff_instances
                          ? teacherDetail?.user_staff_instances?.map((staff) => {
                              return {
                                username: staff.staff.username,
                                id: staff.staff_id,
                              }
                            })
                          : [],
                        'id'
                      )?.map((staff) => ({
                        label: staff.username,
                        value: staff.id,
                      }))}
                      defaultValue={[]}
                      loading={isLoading || isFetchingNextPage}
                      onSearch={(e: any) => {
                        debounceSearchStaff(e)
                      }}
                      handleNextPage={() => hasNextPage && fetchNextPage()}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel label='Facebook Link' className='col-lg-3' />
                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='facebook'
                      control={control}
                      placeholder='Facebook Link'
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel label='Youtube Link' className='col-lg-3' />
                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='youtube'
                      control={control}
                      placeholder='Youtube Link'
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel label='LinkedIn' className='col-lg-3' />
                  <div className='col-lg-9 fv-row'>
                    <HookFormTextField
                      name='linkedin'
                      control={control}
                      placeholder='LinkedIn'
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <SappLabel label='Describe' className='col-lg-3' />
                  <div className='col-lg-9 fv-row'>
                    <HookFormEditor
                      defaultValue={teacherDetail?.detail.describe}
                      control={control}
                      name='describe'
                      placeholder=''
                      resourceLocation={RESOURCE_LOCATION.COURSE_SECTION}
                      object_id={''}
                      setDataFile={() => {}}
                      disabled={!allowRenderEdit}
                    />
                  </div>
                </div>
                {errors?.category_primary_id?.message && (
                  <p className='text-danger my-7 text-right'>
                    {errors?.category_primary_id?.message}
                  </p>
                )}
                <div className='row mb-6'>
                  <SappLabel label='Teaching Programs' className='col-lg-3' required />
                  <div className='col-lg-9'>
                    <Subjects
                      control={control}
                      setValue={setValue}
                      setError={setError}
                      watch={watch}
                      teacherDetail={teacherDetail}
                    />
                  </div>
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <ButtonSecondary
                  title='Discard'
                  className='me-2'
                  onClick={hanleCancel}
                  disabled={loadingSubmit || loading}
                />
                <ButtonPrimary
                  title='Save Changes'
                  onClick={handleSubmit(onSubmit)}
                  loading={loadingSubmit || loading}
                  // disabled={!allowRenderEdit}
                />
              </div>
            </div>
          </div>
          <ModalEditEmail
            open={openEditEmail}
            setOpen={setOpenEditEmail}
            isActive={isActive}
            setIsActive={setIsActive}
            currentEmail={defaultValues.email}
            setTeacherDetail={setTeacherDetail}
          />
        </div>
      )}
    </>
  )
}

export default EditProfileTeacher
