import { Select } from 'antd'
import { ArrowDownIcon, DownIcon } from 'src/common/icons'
import './sappselect.scss'
import { ISappSelect } from './type'
import { isNull } from 'lodash'

const SAPPSelect = ({
  className = '',
  onChange,
  size,
  dropdownStyle,
  placeholder,
  showSearch = false,
  filterOption,
  filterSort,
  onSearch,
  disabled,
  loading,
  allowClear = true,
  onClear,
  suffixIcon = <ArrowDownIcon />,
  onBlur,
  onSelect,
  onFocus,
  classNameHeight,
  onDropdownVisibleChange,
  notFoundContent,
  options,
  status,
  value,
  onPopupScroll,
}: ISappSelect) => {
  return (
    <Select
      className={`${className ?? ''} w-100 sapp-hook-form-select ${
        classNameHeight ?? 'sapp-h-45px'
      }`}
      onChange={onChange}
      size={size}
      dropdownStyle={{
        zIndex: '2000',
        minWidth: '150px',
        ...(dropdownStyle && dropdownStyle),
      }}
      placeholder={placeholder || 'Please Select'}
      showSearch={showSearch}
      filterOption={
        filterOption ??
        (showSearch &&
          ((input, option) => {
            const optionText = (option?.userName ?? option?.children ?? option?.label ?? '')
              .toString()
              .toLowerCase()
            const optionEmail = (option?.email ?? '').toString().toLowerCase()
            const trimmedInput = input?.toLowerCase()?.trimStart()?.trimEnd()
            // Check if the trimmed option text includes the trimmed input
            return optionText.includes(trimmedInput) || optionEmail.includes(trimmedInput)
          }))
      }
      filterSort={filterSort}
      status={status}
      onSearch={onSearch}
      disabled={disabled}
      allowClear={allowClear}
      onClear={onClear}
      loading={loading}
      suffixIcon={isNull(suffixIcon) ? null : <DownIcon width={9} height={6} />}
      onPopupScroll={onPopupScroll}
      onBlur={onBlur}
      onSelect={onSelect}
      onFocus={onFocus}
      onDropdownVisibleChange={onDropdownVisibleChange}
      notFoundContent={notFoundContent}
      value={value}
      options={options}
    />
  )
}

export default SAPPSelect
