import { Select } from 'antd'
import { format } from 'date-fns'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { KTCardBody } from 'src/_metronic/helpers'
import { CertificatesApi } from 'src/apis/certificates'
import LoadingTable from 'src/common/LoadingTable'
import SappModal from 'src/components/base/SappModal'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import CertificateAction from 'src/components/certificate/component/CertificateAction'
import { HEADER_CLASS_LIST } from 'src/constants'
import { CERTIFICATE_CREATE, OPTIONS_OF_STATUS } from 'src/constants/certificate'
import { useConfirm } from 'src/hooks/use-confirm'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'

const { Option } = Select

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  certificateList: any
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchCertificateList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
}

// define headers
const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Template Name',
    className: 'min-w-400px pe-5',
  },
  {
    label: 'Category',
    className: 'min-w-200px pe-5',
  },
  {
    label: 'Status',
    className: 'min-w-175px',
  },
  {
    label: 'Date',
    className: 'min-w-250px pe-5',
  },
  {
    label: '',
    className: 'min-w-30px pe-5',
  },
]

const TableCertificate = ({
  certificateList,
  loading,
  setLoading,
  filterParams,
  fetchCertificateList,
  getParams,
  queryParams,
  isCheckedAll,
  toggleCheckAll,
}: IProps) => {
  const { search } = useLocation()
  const { confirm, contextHolder } = useConfirm()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)
  const navigate = useNavigate()
  const [openPreview, setOpenPreview] = useState<boolean>(false)
  const [dataPreview, setDataPreview] = useState<{ name: string; data?: Array<any> }>({
    name: '',
    data: [],
  })

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)

  // TODO: check xem có tồn tại params ở trên router không?
  const showQueryParams =
    queryParams.name || queryParams.sortType || queryParams.fromDate || queryParams.toDate

  // TODO: dùng để filter params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.name.trimStart().trimEnd() ?? '',
      queryParams.courseCategoryId ?? '',
      queryParams.sortType ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      showQueryParams ? 'updated_at' : 'created_at'
    )
  )

  const handleChangeStatus = (value: unknown, data: any) => {
    const request: any = {
      name: data?.name,
      status: value,
      courseCategoryId: data?.courseCategoryId,
    }
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: async () => {
        setLoading(true)
        try {
          await CertificatesApi.editCertificates({ ...request }, data?.id)
          toast.success('Update Status Successfully!')
          fetchCertificateList(page_index || 1, page_size || 10, filterParamsPagination)
        } catch (error: any) {
          // if (error?.response?.data?.error?.code === '403|000000') {
          //   toast.error(DENIED_PERMISSIONS)
          // }
          setValue(`status${data?.id}`, data?.status)
        } finally {
          setLoading(false)
        }
      },
      onClose: () => {
        setValue(`status${data?.id}`, data?.status)
      },
    })
  }

  // Using validate for input
  const { control, setValue } = useForm<any>({
    mode: 'onChange',
  })

  useEffect(() => {
    setLoading(true)
    fetchCertificateList(page_index || 1, page_size || 10, filterParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    certificateList?.classes
      ?.map((e: any) => ({ label: e.status, value: e.status.toLowerCase() }))
      ?.forEach((option: any) => {
        setValue(`status${option?.id}`, option.value)
      })
  }, [setValue, certificateList?.topics])

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      name: queryParams.name ?? '',
      sortType: queryParams.sortType ?? '',
      fromDate: formatDate(queryParams.fromDate as Date) ?? '',
      toDate: formatDate(queryParams.toDate as Date) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchCertificateList(page_index, page_size, filterParamsPagination)
    handleChangeParams(page_index || 1, page_size || 10)
  }

  const Action = (type: string, data: any) => {
    if (type === 'edit') {
      navigate(`${CERTIFICATE_CREATE}/${data?.id}`)
    }

    if (type === 'preview') {
      setOpenPreview(true)
      setDataPreview({
        name: data?.name || '',
        data: data?.setting || [],
      })
    }
  }
  return (
    <KTCardBody>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={certificateList?.topics}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
        hasCheck={false}
      >
        {loading ? (
          <>
            {HEADER_CLASS_LIST.map((header, i) => (
              <LoadingTable key={header} headers={HEADER_CLASS_LIST} />
            ))}
          </>
        ) : (
          <>
            {certificateList?.topics?.map((e: any, index: number) => {
              return (
                <tr key={e?.id}>
                  <td>
                    {index +
                      1 +
                      (certificateList?.meta?.page_index - 1) * certificateList?.meta?.page_size}
                  </td>
                  <td className='text-start'>
                    <Link
                      className='sapp-text-truncate-1 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                      to={`${CERTIFICATE_CREATE}/${e?.id}`}
                    >
                      {e?.name}
                    </Link>
                  </td>
                  <td className='text-start'>{e?.course_category?.name}</td>
                  <td className='text-start'>
                    <HookFormSelectAntd
                      allowClear={false}
                      name={`status${e?.id}`}
                      control={control}
                      dropdownStyle={{ maxWidth: 100 }}
                      placeholder='Status'
                      filterOption={true}
                      onChange={(selectedValue: unknown) => handleChangeStatus(selectedValue, e)}
                      defaultValue={e?.status ?? ''}
                      classNameHeight='sapp-h-40'
                      className='sapp-w-80'
                      options={OPTIONS_OF_STATUS}
                    />
                  </td>
                  <td className='text-start'>
                    <div className='text-nowrap'>
                      Created: {format(new Date(e?.created_at), 'dd/MM/yyyy HH:mm')}
                    </div>
                    <div className='text-nowrap'>
                      Updated: {format(new Date(e.updated_at), 'dd/MM/yyyy HH:mm')}
                    </div>
                  </td>
                  <td className='text-end sapp-absolute-column'>
                    <CertificateAction
                      Action={Action}
                      data={e}
                      id={e?.id}
                      fetchCertificateList={fetchCertificateList}
                      page_index={page_index}
                      page_size={page_size}
                      filterParams={filterParams}
                    />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={certificateList?.meta?.page_index || 1}
        pageSize={certificateList?.meta?.page_size || 10}
        totalItems={certificateList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
      <SappModal
        cancelButtonCaption='Cancel'
        okButtonCaption='OK'
        open={openPreview}
        title={dataPreview?.name ?? 'Preview Certification'}
        handleClose={() => setOpenPreview(false)}
        centered
        classBody='py-10 modal-body px-lg-17 px-10'
        classNameBody='d-flex justify-content-center sapp-p-body-content'
        showFooter={false}
        contentClassName='sapp-modal-content'
        dialogClassName='sapp-modal-dialog-preview'
        showClassHeightBody={false}
        classNameContent='sapp-p-body-content'
      >
        <div className='w-100 sapp-h-774px relative' style={{ overflowY: 'hidden' }}>
          <div className='sapp-alight-center sapp-image-scale-08'>
            {dataPreview?.data?.map((e, i) => {
              if (e?.type === 'TEXT') {
                return (
                  <div
                    className={`sapp-opacity-bg-border position-absolute ${e?.tag}`}
                    style={{
                      fontFamily: e?.fontFamily,
                      fontSize: e?.fontSize,
                      color: e?.color,
                      fontWeight: e?.isBold ? '700' : '',
                      fontStyle: e?.isItalic ? 'italic' : 'normal',
                      textDecoration: e?.isThrough
                        ? 'line-through'
                        : e?.isUndefined
                        ? 'underline'
                        : '',
                      textTransform: e?.isUpperCase ? 'uppercase' : 'none',
                      textAlign: e?.textAlign,
                      lineHeight: e?.lineHeight,
                      width: e?.position?.width,
                      height: e?.position?.height,
                      top: e?.position?.top,
                      left: e?.position?.left,
                    }}
                  >
                    {e?.value}
                  </div>
                )
              }

              if (e?.type === 'IMAGE-DEFAULT') {
                return (
                  <img
                    src={e?.imageUrl}
                    alt={`Image-${i}`}
                    className='object-fit-contain sapp-max-image-default'
                    loading='lazy'
                  />
                )
              }
            })}
          </div>
        </div>
      </SappModal>
    </KTCardBody>
  )
}

export default TableCertificate
