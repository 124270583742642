import { Skeleton } from 'antd'
import clsx from 'clsx'
import utc from 'dayjs/plugin/utc'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { ExamAPI } from 'src/apis/exams'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import ListFilterLayout from 'src/components/layout/listFilter'
import NotData from 'src/components/NotData'
import { FILTER_SORTBY_DATE, monthsOfYear, PageLink } from 'src/constants'
import useSubjects from 'src/hooks/useSubjects'
import { ISubject } from 'src/type/subject'
dayjs.extend(utc)

// Styles
import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import { CalendarIcon } from 'src/common/icons'
import ActionCell from 'src/components/base/action/ActionCell'
import ButtonDanger from 'src/components/base/button/ButtonDanger'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { EXAM_DETAIL_URL } from 'src/constants/exam'
import { TITLE_EXAM_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { EXAM_PROGRAM, ExamFilters, IExamFilters } from 'src/type'
import { hasPermission } from 'src/utils/permission'
import { removeEmptyKey } from 'src/utils/removeEmptyKey'
import { formatDate } from 'src/utils/time'
import styles from './ExamList.module.scss'
import ExamUploadModal from './ExamUploadModal'

interface IHeader {
  label: string
  minWidth?: string
  width?: string
  className?: string
}

const cleanFilters = (filters: IExamFilters): IExamFilters => {
  const dateRangeFields = [
    ExamFilters.REGISTRATION_OPENING,
    ExamFilters.EARLY_REGISTRATION_DEADLINE,
    ExamFilters.STANDARD_REGISTRATION_DEADLINE,
    ExamFilters.REGISTRATION_CLOSING_DATE,
    ExamFilters.START_DATE,
    ExamFilters.END_DATE,
  ]
  const formattedFilters = {
    ...filters,
    exam_year: filters.exam_year ? dayjs(filters.exam_year).format('YYYY') : '',
  } // Make a shallow copy of the filters

  dateRangeFields.forEach((key) => {
    const value = formattedFilters[key as keyof IExamFilters]

    // Check if the value exists and contains both 'fromDate' and 'toDate'
    if (value && typeof value === 'object' && 'fromDate' in value && 'toDate' in value) {
      const { fromDate, toDate } = value as { fromDate: string; toDate: string }
      // Convert 'fromDate' and 'toDate' to the desired format: "fromDate; toDate"
      formattedFilters[key as keyof IExamFilters] =
        fromDate && toDate
          ? `${dayjs(fromDate).startOf('day').utc().format()};${dayjs(toDate)
              .endOf('day')
              .utc()
              .format()}` // Updated line
          : ''
    }
  })

  return removeEmptyKey({
    ...formattedFilters,
  }) as IExamFilters
}

interface ExamRouteParams extends Record<string, string | undefined> {
  program: EXAM_PROGRAM
}

const ExamList = () => {
  const [openModal, setOpenModal] = useState(false)
  const { program: programRoute } = useParams<ExamRouteParams>()
  const program = programRoute as EXAM_PROGRAM

  const cfaHeaders: IHeader[] = [
    {
      label: 'Mở Đăng kí',
    },
    {
      label: 'Hạn ĐK sớm',
    },
    {
      label: 'Hạn ĐK chuẩn',
    },
    {
      label: 'Ngày thi',
    },
  ]

  const cmaHeaders: IHeader[] = [
    {
      label: 'Ngày thi đầu',
      minWidth: '120px',
    },
    {
      label: 'Ngày thi cuối',
    },
    {
      label: 'Ngày đăng kí cuối',
    },
  ]

  const accaHeaders: IHeader[] = [
    {
      label: 'Ngày thi',
    },
  ]

  const headers: IHeader[] = [
    {
      label: 'Kỳ thi',
    },
    {
      label: 'Subject',
      className: 'pr-6',
    },
    ...(program === EXAM_PROGRAM.CFA ? cfaHeaders : []),
    ...(program === EXAM_PROGRAM.CMA ? cmaHeaders : []),
    ...(program === EXAM_PROGRAM.ACCA ? accaHeaders : []),
    {
      label: 'Số học viên Đk',
      className: 'text-center',
    },
  ]

  const searchParams = new URLSearchParams(location.search)
  const filtersFromUrl = Object.fromEntries(searchParams.entries())

  const [pageIndex, setPageIndex] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(3)
  const [dropdownSubjectsOpen, setDropdownSubjectsOpen] = useState(false)

  const { handleSubmit, control, watch, reset } = useForm<IExamFilters>({
    defaultValues: filtersFromUrl,
  })

  const [isReset, setIsReset] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const { confirm, contextHolder } = useConfirm()

  const {
    subjects,
    handleNextPageSubject,
    debounceSearchSubject,
    isLoading: isSubjectsLoading,
    setSubjects,
  } = useSubjects() // Use the hook

  const subjectCourse = subjects?.subjects?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
  }))

  const filters: IExamFilters = watch()

  const fetchExams = async (filters: IExamFilters) => {
    let params = {}
    if (filters) {
      params = {
        [ExamFilters.CATEGORY_NAME]: program,
        ...filters,
        page_index: pageIndex,
        page_size: pageSize,
      }
    } else if (filtersFromUrl) {
      params = {
        [ExamFilters.CATEGORY_NAME]: program,
        ...filtersFromUrl,
        page_index: pageIndex,
        page_size: pageSize,
      }
    }
    const cleanedParams = cleanFilters(params as IExamFilters)

    const pagination = new URLSearchParams({
      page_index: pageIndex.toString(),
      page_size: pageSize.toString(),
      ...cleanedParams,
    })
    pagination.delete('category_name')

    navigate(`?${pagination.toString()}`)

    const response = await ExamAPI.getList({
      params: cleanedParams,
    })
    return response.data
  }

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ['ListExams', pageIndex, pageSize, isReset, program],
    queryFn: () => {
      return fetchExams(filters)
    },
    enabled: !!program,
    retry: 1,
    keepPreviousData: true,
    onSuccess(data) {
      if (
        data.exams.length === 0 &&
        data.metadata.page_index > data.metadata.total_pages &&
        data.metadata.total_records !== 0
      ) {
        setPageIndex(data.metadata.page_index - 1)
      }
    },
    refetchOnWindowFocus: false,
  })

  const { mutate: deleteExam, isLoading: isDeleteLoading } = useMutation({
    mutationFn: (examIds: string[]) => ExamAPI.delete(examIds),
    onSuccess: (res) => {
      const errorData = res?.data?.data

      if (errorData?.length !== 0) {
        toast.error(
          <div className={styles.deleteErrorContainer}>
            {errorData.map(
              (error: { data: string[]; error_message: string }, index: number) =>
                error.data.length > 0 && (
                  <div key={index} className={styles.deleteError}>
                    <p>Error: {error.error_message}</p>
                    <p>Exam: {error.data.join(', ')}</p>
                  </div>
                )
            )}
          </div>,
          {
            duration: 5000,
          }
        )
      } else {
        toast.success(res.data.message)
      }

      queryClient.invalidateQueries('ListExams')
      toggleCheckAll(false)
    },
    onError: () => {
      toast.error("There's something wrong")
    },
  })
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(data?.exams)

  const navigate = useNavigate()

  const onSubmit: SubmitHandler<IExamFilters> = (data) => {
    refetch()

    setIsReset(false)
  }

  const handleReset = () => {
    const emptyFilters: Partial<IExamFilters> = Object.keys(watch()).reduce((acc, key) => {
      acc[key as keyof IExamFilters] = '' // Explicit type assertion
      return acc
    }, {} as Partial<IExamFilters>)
    reset(emptyFilters)
    navigate({
      pathname: location.pathname,
    })
    setPageIndex(1)
    setPageSize(3)
    setIsReset(true)
  }

  const handleDelete = (id: string | string[]) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xoá không?'],
      onClick: () => (Array.isArray(id) ? deleteExam(id) : deleteExam([id])),
    })
  }

  const handlePaginationChange = (page_index: number, page_size: number) => {
    setPageIndex(page_index)
    setPageSize(page_size)
    setIsReset(false)
  }

  const getColSpanExam = (program: EXAM_PROGRAM | undefined) => {
    switch (program) {
      case EXAM_PROGRAM.CFA:
        return 3
      case EXAM_PROGRAM.ACCA:
        return 5
      case EXAM_PROGRAM.CMA:
        return 5
    }
  }

  const getColSpanSubject = (program: EXAM_PROGRAM | undefined) => {
    switch (program) {
      case EXAM_PROGRAM.CFA || EXAM_PROGRAM.ACCA:
        return 4
      case EXAM_PROGRAM.CMA:
        return 1
    }
  }

  const [previousPath, setPreviousPath] = useState(location.pathname)

  useEffect(() => {
    // Check if the path has changed
    if (location.pathname !== previousPath) {
      // Reset filters if navigating to a different route
      handleReset()
    }

    // Update the previous path
    setPreviousPath(location.pathname)
  }, [location.pathname, previousPath])

  useEffect(() => {
    setSubjects(undefined)
    if (dropdownSubjectsOpen && program) {
      // Fetch subjects only when dropdown is open and program changes
      debounceSearchSubject({ category_name: program })
    }
  }, [dropdownSubjectsOpen, program])

  const handleDropdownVisibleChange = (open: boolean) => {
    setDropdownSubjectsOpen(open)
  }

  const { profileMe } = useUserContext()

  const allowRenderCreate = hasPermission(profileMe?.roles, TITLE_EXAM_GR.CREATE_EXAM)
  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_EXAM_GR.EDIT_EXAM)
  const allowRenderRemove = hasPermission(profileMe?.roles, TITLE_EXAM_GR.REMOVE_EXAM)

  return (
    <div className={clsx([styles.card], ['card py-9'])}>
      {/* Filters */}
      {contextHolder}
      <form>
        <ListFilterLayout className='px-9'>
          {/* Tháng thi */}
          <HookFormSelectAntd
            placeholder='Tháng thi'
            name={ExamFilters.EXAM_MONTH}
            control={control}
            allowClear
            suffixIcon={<CalendarIcon />}
            defaultValue={filters?.exam_month}
            options={monthsOfYear}
            classNameHeight='sapp-h-40'
          />
          {/* Năm Thi */}
          <HookFormDateTime
            control={control}
            name={ExamFilters.EXAM_YEAR}
            placeholder='Năm thi'
            format={'YYYY'}
            picker='year'
            defaultValue={filters?.exam_year}
            isListScreen
          />
          {/* Subject */}
          <HookFormSelectAntd
            control={control}
            name='subject_id'
            placeholder='Subject'
            allowClear
            handleNextPage={(e: any) =>
              handleNextPageSubject({
                name: e,
                category_name: program,
              })
            }
            onDropdownVisibleChange={handleDropdownVisibleChange}
            showSearch
            loading={isSubjectsLoading}
            defaultValue={filters?.subject_id}
            options={
              subjectCourse?.map((subject) => ({
                label: subject.label,
                value: subject.value,
              })) ?? []
            }
            classNameHeight='sapp-h-40'
          />

          {program === EXAM_PROGRAM.CFA && [
            <RangeDateTimePicker
              control={control}
              name={ExamFilters.REGISTRATION_OPENING}
              allowClear={true}
              placeholder={['Mở đăng ký', 'To']}
              defaultValue={filters?.registration_opening_date}
              isListScreen
            />,
            <RangeDateTimePicker
              control={control}
              name={ExamFilters.EARLY_REGISTRATION_DEADLINE}
              allowClear={true}
              placeholder={['Đăng ký sớm', 'To']}
              defaultValue={filters?.early_registration_deadline}
              isListScreen
            />,
            <RangeDateTimePicker
              control={control}
              name={ExamFilters.STANDARD_REGISTRATION_DEADLINE}
              allowClear={true}
              placeholder={['Đăng ký chuẩn', 'To']}
              defaultValue={filters?.standard_registration_deadline}
              isListScreen
            />,
          ]}
          {program === EXAM_PROGRAM.CMA && [
            <RangeDateTimePicker
              control={control}
              name={ExamFilters.START_DATE}
              allowClear={true}
              placeholder={['Ngày thi đầu', 'To']}
              defaultValue={filters?.start_date}
              isListScreen
            />,
            <RangeDateTimePicker
              control={control}
              name={ExamFilters.END_DATE}
              allowClear={true}
              placeholder={['Ngày thi cuối', 'To']}
              defaultValue={filters?.end_date}
              isListScreen
            />,
            <RangeDateTimePicker
              control={control}
              name={ExamFilters.REGISTRATION_CLOSING_DATE}
              allowClear={true}
              placeholder={['Ngày ĐK cuối', 'To']}
              defaultValue={filters?.registration_closing_date}
              isListScreen
            />,
          ]}
          {/* Sort by */}
          <HookFormSelectAntd
            control={control}
            name={ExamFilters.SORT_TYPE}
            placeholder='Sort by'
            allowClear
            defaultValue={filters?.sort_type}
            options={FILTER_SORTBY_DATE}
            classNameHeight='sapp-h-40'
          />
        </ListFilterLayout>
        <div className={clsx([styles.cardHeader], ['card-header border-0 mt-5'])}>
          <div className='d-flex'>
            <ButtonSecondary
              title={'Reset'}
              className={`me-4`}
              onClick={handleReset}
              isListScreen
              type='button'
            />
            <ButtonPrimary
              title={'Search'}
              onClick={handleSubmit(onSubmit)}
              loading={isLoading || isFetching}
              isListScreen
            />
          </div>
          <div className={clsx('d-flex justify-content-between', styles.createDeleteButtons)}>
            {/* Don't show upload button when there're selected items */}
            {checkedList?.length > 0 ? (
              <>
                <div className='fw-bold sapp-checkbox-text-custom me-5 pt-5'>
                  <span>{checkedList?.length}</span> Selected
                </div>
                <ButtonDanger
                  className={'fs-base sapp-h-40px'}
                  title={'Delete Selected'}
                  onClick={() => handleDelete(checkedList)}
                  loading={isDeleteLoading}
                />
              </>
            ) : (
              <>
                {allowRenderCreate && (
                  <ButtonIcon
                    title='Import Exam'
                    customButton='btn btn-light'
                    className={'fs-base'}
                    onClick={() => setOpenModal(true)}
                    type='button'
                    isListScreen
                  >
                    <KTIcon iconName='file-up' className='fs-4' iconType='outline' />
                  </ButtonIcon>
                )}
              </>
            )}
            {allowRenderCreate && (
              <ButtonIcon
                title={'Create Exam'}
                link={`${PageLink.EXAMS}/${program}/create`}
                isListScreen
              >
                <KTIcon iconName='plus' className='fs-4' />
              </ButtonIcon>
            )}
          </div>
        </div>
      </form>

      {/* Table */}
      <div className='position-relative'>
        <div className={clsx('px-9 mt-9 table-container')}>
          <div className='table-responsive position-relative'>
            <table className={clsx(styles.table)}>
              <thead>
                <tr className={`text-start text-muted fw-bold text-uppercase`}>
                  <th className={clsx([styles.tableHeader], 'px-5 pb-9')}>
                    <SAPPCheckbox
                      checked={isCheckedAll}
                      onChange={() => toggleCheckAll(!isCheckedAll, true)}
                      disabled={false}
                    />
                  </th>
                  {headers?.map((column) => (
                    <th
                      key={column.label}
                      className={clsx(['sapp-text-header--column fw-bold pb-9', column?.className])}
                      style={{
                        minWidth: column?.minWidth,
                        width: column?.width,
                      }}
                    >
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              {isLoading || isFetching ? (
                <tbody>
                  {headers?.map(() => (
                    <tr>
                      {[...headers, { label: 'skeleton' }].map((header) => (
                        <>
                          <td key={header?.label} className='pb-5'>
                            <Skeleton.Input size='large' active />
                          </td>
                        </>
                      ))}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className='text-gray-600 fw-semibold sapp-fs-14'>
                  {data?.exams?.map((exam) => {
                    const isChecked = checkedList && checkedList.includes(exam.id)
                    return (
                      <React.Fragment key={exam.id}>
                        <tr className='bg-gray-100'>
                          {/* Kỳ thi */}
                          <td colSpan={getColSpanExam(program as EXAM_PROGRAM)} className='p-5'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div className='d-flex'>
                                <SAPPCheckbox
                                  checked={isChecked}
                                  onChange={() => {
                                    toggleCheck(exam.id)
                                  }}
                                  disabled={false}
                                />
                                <Link
                                  className={clsx(
                                    'ms-6 text-gray-800 text-hover-primary',
                                    styles.examLink
                                  )}
                                  to={`${PageLink.EXAMS}/${program}/${exam.id}/${EXAM_DETAIL_URL.OVERVIEW}`}
                                >
                                  {exam.name}
                                </Link>
                              </div>
                            </div>
                          </td>
                          {/* Kỳ thi */}

                          {program === EXAM_PROGRAM.CFA && (
                            <>
                              {/* Mở ĐK */}
                              <td>{formatDate(exam?.registration_opening_date)}</td>
                              {/* Hạn ĐK Sớm */}
                              <td>{formatDate(exam?.early_registration_deadline)}</td>{' '}
                              {/* Hạn ĐK Chuẩn */}
                              <td colSpan={3}>
                                <span>{formatDate(exam?.standard_registration_deadline)}</span>{' '}
                              </td>
                            </>
                          )}
                          {program === EXAM_PROGRAM.CMA && (
                            <>
                              {/* 	Ngày đăng kí cuối */}
                              <td colSpan={2}>{formatDate(exam?.registration_closing_date)}</td>
                            </>
                          )}

                          {(allowRenderEdit || allowRenderRemove) && (
                            <td
                              colSpan={1}
                              className={clsx(
                                'text-end sapp-absolute-column',
                                styles.actionCellCol
                              )}
                            >
                              <ActionCell>
                                {allowRenderEdit && (
                                  <div
                                    className='menu-item px-3'
                                    onClick={() =>
                                      navigate(`${PageLink.EXAMS}/${program}/${exam.id}/setting`)
                                    }
                                  >
                                    <div className='menu-link px-3'>Edit</div>
                                  </div>
                                )}
                                {allowRenderRemove && (
                                  <div
                                    className='menu-item px-3'
                                    onClick={() => handleDelete(exam.id)}
                                  >
                                    <div className='menu-link px-3'>Delete</div>
                                  </div>
                                )}
                              </ActionCell>
                            </td>
                          )}
                        </tr>

                        {exam?.examination_subjects?.map((subject) => (
                          <tr style={{ lineHeight: '45px' }} key={subject.id}>
                            {/* Checkbox */}
                            <td></td>
                            <td>{subject?.code_exam}</td>
                            <td colSpan={getColSpanSubject(program)}>{subject?.subject.name}</td>
                            {program === EXAM_PROGRAM.CFA && (
                              <td>
                                {formatDate(subject?.start_date)} - {formatDate(subject?.end_date)}
                              </td>
                            )}
                            {program === EXAM_PROGRAM.CMA && (
                              <>
                                <td>{formatDate(subject?.start_date)}</td>
                                <td colSpan={2}>{formatDate(subject?.end_date)}</td>
                              </>
                            )}
                            {program === EXAM_PROGRAM.ACCA && (
                              <>
                                <td>{formatDate(subject?.end_date)}</td>
                              </>
                            )}
                            <td align='center'>{subject?.student_count}</td>
                          </tr>
                        ))}
                      </React.Fragment>
                    )
                  })}
                </tbody>
              )}
            </table>
          </div>
          <PagiantionSAPP
            currentPage={data?.metadata?.page_index || 1}
            pageSize={data?.metadata?.page_size || 3}
            totalItems={data?.metadata?.total_records}
            handlePaginationChange={handlePaginationChange}
            showPagination={3}
          />
          <ExamUploadModal open={openModal} setOpen={setOpenModal} program={program} />
          <div className={styles.abc}>
            <div className='styleHere'></div>
          </div>
          {isEmpty(data) && !isLoading && <NotData />}
        </div>
      </div>
    </div>
  )
}

export default ExamList
