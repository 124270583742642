import { UseFormReturn } from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { Select } from 'antd'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import Advanced from '../blogs/Advanced'
import MenuTabsSection from '../blogs/MenuTabs'
import { IUseShopFilterResult } from 'src/hooks/use-shop-filter'
import { IEventForm } from 'src/type/shop/event'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import { Dropdown } from 'react-bootstrap'
import { IStepProgress } from 'src/type'

const { Option } = Select

export const ACTION_STATUS = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
]

interface IProps extends IStepProgress {
  loading: boolean
  useFormProp: UseFormReturn<IEventForm>
  onSubmit: () => void
  onCancel: () => void
  useFilter: IUseShopFilterResult
}

const CreateAndUpdateSetting = ({
  loading,
  setStep,
  step,
  useFormProp,
  onSubmit,
  onCancel,
  useFilter,
}: IProps) => {
  const { control, getValues } = useFormProp

  const { dataList, getData, debounceGetData, handleNextPage } = useFilter

  return (
    <div className='form d-flex flex-column flex-lg-row'>
      <Processbar
        step={step}
        setNewStep={setStep}
        showCancel
        onCancel={onCancel}
        onClick={onSubmit}
        showButtonPrimary={false}
      >
        <Dropdown.Item>Preview</Dropdown.Item>
        <Dropdown.Item onClick={onSubmit}>Save & Next</Dropdown.Item>
      </Processbar>
      <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
        <div className='tab-content'>
          <div className='tab-pane fade show active'>
            <div className='d-flex flex-column gap-7 gap-lg-10'>
              <div className='card card-flush pb-4 pt-8'>
                <div className='card-body pt-0'>
                  <div className='text-gray-800 fw-bold sapp-mb-32px sapp-fs-20px'>Settings</div>
                  <div className='mb-10 fv-row'>
                    <HookFormSelectMultiple
                      name='categories'
                      placeholder='None'
                      control={control}
                      label='Category'
                      options={dataList.data.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      handleNextPage={handleNextPage}
                      onSearch={(e: any) => {
                        debounceGetData(e)
                        if (e === undefined) {
                          return
                        }
                      }}
                      loading={dataList.loading}
                    />
                  </div>

                  <div className='mb-10 fv-row'>
                    <HookFormSelectAntd
                      name='primary_event_category_id'
                      placeholder='None'
                      control={control}
                      size='large'
                      label='Primary Category'
                      showSearch
                      handleNextPage={handleNextPage}
                      onSearch={async (e) => {
                        debounceGetData(e)
                        return
                      }}
                      onFocus={async () => {
                        if (dataList?.data?.length <= 0) {
                          await getData()
                          return
                        }
                      }}
                      onChange={async (e: any) => {
                        if (e === undefined) {
                          debounceGetData()
                        }
                      }}
                      loading={dataList.loading}
                      allowClear
                      classNameHeight='sapp-h-40'
                      options={dataList.data?.map((item) => ({ label: item.name, value: item.id }))}
                    />
                  </div>

                  <div className='mb-10 fv-row'>
                    <HookFormTextField
                      control={control}
                      label='URL'
                      name='suffix_url'
                      groupText='sapp.shop.edu.vn/sapp-academy/'
                      className='sapp-h-45px'
                    />
                  </div>

                  <div className='mb-10 fv-row'>
                    <HookFormTextField
                      control={control}
                      label='Meta Title'
                      name='meta_title'
                      className='sapp-h-45px'
                      required
                    />
                  </div>

                  <div className='mb-10 fv-row'>
                    <HookFormEditor
                      height={380}
                      name='meta_description'
                      control={control}
                      math={true}
                      label='Meta Description'
                      defaultValue={getValues('meta_description')}
                      resourceLocation={RESOURCE_LOCATION.MEDIA}
                      object_id={undefined}
                      required
                    />
                  </div>

                  <div className='mb-10 fv-row'>
                    <HookFormTextField
                      control={control}
                      label='Focus Keyword'
                      name='focus_keyword'
                      className='sapp-h-45px'
                    />
                  </div>

                  <div className='mb-10 fv-row'>
                    <MenuTabsSection
                      typeofSection={'Advanced'}
                      SectionName={'Advanced'}
                      tabs={[
                        {
                          id: 'tab1',
                          title: 'Advanced',
                          content: <Advanced useForm={useFormProp} />,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateAndUpdateSetting
